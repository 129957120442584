import * as React from 'react'
import * as PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { loadItemOptions } from '../../api/items'

function ItemPicker({ item, setItem, id, name, customLoadOptions = null, isDisabled = false }) {
  const loadOptions = customLoadOptions ? customLoadOptions : loadItemOptions
  let selected = ''
  if (item && item.value !== undefined) {
    selected = item
  }

  return (
    <AsyncSelect
      id={id}
      name={name}
      cacheOptions
      placeholder="Vælg eller skriv..."
      loadOptions={loadOptions}
      defaultOptions
      isClearable
      value={selected}
      onChange={setItem}
      required
      styles={{ menu: base => ({ ...base, zIndex: 1100 }) }} // more than sticky headers have
      isDisabled={isDisabled}
    />
  )
}

ItemPicker.propTypes = {
  item: PropTypes.object,
  setItem: PropTypes.func.isRequired,
  defaultItem: PropTypes.object,
}

export default ItemPicker
