import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import MinutesPicker from './MinutesPicker'
import HoursPicker from './HoursPicker'
import RemovedField from "./RemovedField";

function MachineFields({
  className,
  idKey,
  index,
  machineFields,
  setMachineFields,
  availableMachines,
  fieldNameFn,
  handleRemove,
}) {
  const machineId = machineFields.machine_id || ''
  const minutes = machineFields.time % 60 || 0
  const hours = Math.floor(machineFields.time / 60) || 0
  const fieldId = field => `${idKey}-field-${field}`
  const id = machineFields.id || null

  if (machineFields._destroy === true) {
    return <RemovedField field={machineFields} fieldNameFn={fieldNameFn} />
  }
  return (
    <fieldset className={ `position-relative form-fieldset ${className}` }>
      <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />
      <div className="row">
        <div className="col-sm-6 col-lg-7 form-group">
          <label htmlFor={fieldId('machine_id')}>Maskine/udstyr</label>
          <select
            id={fieldId('machine_id')}
            name={fieldNameFn('machine_id')}
            className="form-control resources-machine-machine"
            value={machineId}
            onChange={e => setMachineFields({ ...machineFields, machine_id: e.target.value })}
            required
          >
            <option />
            {availableMachines.map(machine => {
              const { id, name } = machine
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-sm-6 col-lg-5">
          <div className="resources-time-selects">
            <div className="form-group pr-1 time-picker-field">
              <label>Tid</label>
              <HoursPicker
                hours={hours}
                setHours={h => setMachineFields({ ...machineFields, time: h * 60 + minutes })}
                name={fieldNameFn('hours')}
              />
            </div>

            <div className="form-group pr-1 time-picker-field">
              <label>&nbsp;</label>
              <MinutesPicker
                minutes={minutes}
                setMinutes={m => setMachineFields({ ...machineFields, time: hours * 60 + m })}
                name={fieldNameFn('minutes')}
              />
            </div>
            <input type="hidden" name={fieldNameFn('time')} value={hours * 60 + minutes} />
            {id && <input type="hidden" name={fieldNameFn('id')} value={id} />}
          </div>
        </div>
      </div>
    </fieldset>
  )
}

MachineFields.propTypes = {
  idKey: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  machineFields: PropTypes.shape({
    time: PropTypes.number,
  }).isRequired,
  setMachineFields: PropTypes.func.isRequired,
  availableMachines: PropTypes.array.isRequired,
}

export default MachineFields
