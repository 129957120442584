import React from 'react'
import Select, { components } from 'react-select'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

export function arrayMove(array, from, to) {
  array = array.slice()
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
  return array
}

export const SortableMultiValue = SortableElement(props => {
  const onMouseDown = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableSelect = SortableContainer(Select)

export default SortableSelect
