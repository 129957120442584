import * as React from 'react'
import FeedbackTypeSelect, { FEEDBACK_TYPES, FEEDBACK_ICONS } from './FeedbackTypeSelect'
import FeedbackForm from './FeedbackForm'

type FeedbackModalContentProps = {
  closeModal: () => void
}

export default function FeedbackModalContent(props: FeedbackModalContentProps) {
  const { closeModal } = props
  const [feedbackType, setFeedbackType] = React.useState('')
  const title = feedbackType === '' ? 'Feedback' : FEEDBACK_ICONS[feedbackType] + ' ' + FEEDBACK_TYPES[feedbackType]

  return (
    <>
      <div className="feedback-modal-header popup-header">{title}</div>
      {feedbackType === '' && <FeedbackTypeSelect setFeedbackType={setFeedbackType}/>}
      {feedbackType !== '' && <FeedbackForm closeModal={closeModal} feedbackType={feedbackType} setFeedbackType={setFeedbackType}/>}
    </>
  )
}
