import { useMutation, useQuery } from 'react-query'
import { getScanInfo, createScanRecord } from '../../api/scan_records'

export function useScanInfoQuery(orderNumber: string, area: string) {
  return useQuery(['relate_order_numbers', orderNumber, area], () => getScanInfo(orderNumber, area), { enabled: !!orderNumber })
}

export function useCreateScanRecordMutation() {
  return useMutation(createScanRecord)
}
