import { useContext } from 'react'
import * as PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import FormFields from './FormFields'
import DictionariesContext from '../../DictionariesContext'

function EditJobPlanningButton({ jobPlanning, submitUrl, buttonTitle, buttonClass }) {
  const dictionaries = useContext(DictionariesContext)
  const { currentUser } = dictionaries
  if (!currentUser.is_department_manager) {
    if (currentUser.username !== jobPlanning.no && !currentUser.allow_to_add_job_plannings) {
      // Do not allow to edit job planning for regular users (unless it user's job planning)
      return null
    }
  }

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-secondary'}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: 'auto', maxWidth: '720px' }}
      modal
    >
      {close => (
        <div className="job-planning-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="job-planning-modal-header popup-header">{buttonTitle}</div>
          <form action={submitUrl} method="POST">
            <div className="job-planning-modal-content popup-content">
              <FormFields jobPlanning={jobPlanning} />
            </div>
            <div className="job-planning-modal-actions popup-actions">
              <button type="button" className="btn btn-outline-secondary" onClick={close}>
                Luk
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      )}
    </Popup>
  )
}

EditJobPlanningButton.propTypes = {
  jobPlanning: PropTypes.object.isRequired,
  submitUrl: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
}

export default EditJobPlanningButton
