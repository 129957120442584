import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getApprovalFormsAnswers } from '../../api/approval_forms_answers'
import ApprovalFormsRow from './ApprovalFormsRow'

function ApprovalFormsTable({ jobnumber }) {
  const jobnumberId = jobnumber.id
  const { isLoading, data, error } = useQuery(['approval_forms_table', jobnumberId], () =>
    getApprovalFormsAnswers({ jobnumber_id: jobnumberId })
  )

  if (error) {
    return <div className="text-center text-danger">An error occured, please try to reload the page.</div>
  }

  if (isLoading) {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin" />
      </div>
    )
  }

  if (!data.length) {
    return <div className="text-center">Der er ingen godkendelsesformular på denne ordre</div>
  }

  return (
    <table className="table">
      <tbody>
        {data &&
          data.map(approvalFormAnswer => {
            return <ApprovalFormsRow approvalFormAnswer={approvalFormAnswer} key={approvalFormAnswer.id} />
          })}
      </tbody>
    </table>
  )
}

ApprovalFormsTable.propTypes = {
  jobnumber: PropTypes.shape({
    id: PropTypes.number,
    jobnumber: PropTypes.string,
  }).isRequired,
}

export default ApprovalFormsTable
