import * as React from 'react'
import TableHead from './TableHead'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import TableRow from './TableRow'
import { format, differenceInCalendarDays } from 'date-fns'

type TableProps = {
  isLoading: boolean
  isError: boolean
  error?: any
  hideItems: boolean
  productionPlanningLines?: any
  area?: string
  showCompletedToday: boolean
}

const DEFAULT_COLSPAN = 12

export default function Table(props: TableProps) {
  const { isLoading, isError, hideItems, productionPlanningLines, error, area, showCompletedToday } = props
  return (
    <div>
      <table className="table table-print-sm">
        <TableHead hideItems={hideItems} showCompletedToday={showCompletedToday} />
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={DEFAULT_COLSPAN}>
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin fa-2x" />
                </div>
              </td>
            </tr>
          )}
          {!isLoading &&
            Object.keys(productionPlanningLines).map(date => {
              const lines = productionPlanningLines[date]
              if (!lines.length) return null
              return (
                <ProductionPlanningGroupRows
                  key={date}
                  date={date}
                  hideItems={hideItems}
                  productionPlanningLines={productionPlanningLines[date]}
                  area={area}
                  showCompletedToday={showCompletedToday}
                />
              )
            })}
          {isError && (
            <tr>
              <td colSpan={DEFAULT_COLSPAN}>
                <ErrorMessage className="d-inline error-message" error={error} isError={isError} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

function ProductionPlanningGroupRows({ date, productionPlanningLines, hideItems, area, showCompletedToday }) {
  let lastJobId = productionPlanningLines[0].job_id
  let currentColor = 'bg-white'
  const toggleColor = () => {
    currentColor = currentColor === 'bg-light' ? 'bg-white' : 'bg-light'
  }
  const formattedDate = format(new Date(date), 'dd-MM-yyyy')
  const daysLeft = differenceInCalendarDays(new Date(date), new Date())
  return (
    <>
      <tr className="job-planning-date-row">
        <td className="job-planning-date-col" colSpan={DEFAULT_COLSPAN}>
          {`${formattedDate} | ${daysLeft} ${Math.abs(daysLeft) > 1 ? 'dage' : 'dag'}`}
        </td>
      </tr>
      {productionPlanningLines.map(productionPlanningLine => {
        const key = `${productionPlanningLine.job_id}-${productionPlanningLine.solution_id}`
        if (lastJobId !== productionPlanningLine.job_id) {
          lastJobId = productionPlanningLine.job_id
          toggleColor()
        }
        return (
          <TableRow
            key={key}
            productionPlanningLine={productionPlanningLine}
            area={area}
            hideItems={hideItems}
            showCompletedToday={showCompletedToday}
            className={currentColor}
          />
        )
      })}
    </>
  )
}
