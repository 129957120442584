import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import FormFields from './FormFields'

export default function RangeEntriesApp(props: any) {
  const queryClient = buildQueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <FormFields {...props} />
    </QueryClientProvider>
  )
}
