import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import FormFieldUsers from './FormFieldUsers'
import OrderNumberPicker from '../../resources_records/OrderNumberPicker'
import MinutesPicker from '../../resources_records/MinutesPicker'
import HoursPicker from '../../resources_records/HoursPicker'
import DictionariesContext from '../../DictionariesContext'
import DependentTaskPicker from '../../resources_records/DependentTaskPicker'

function FormFields({ jobPlanning }) {
  const dictionaries = useContext(DictionariesContext)
  const { availableWorktypes, availableUsers, availableDepartments, currentUser } = dictionaries
  const [jobnumber, setJobnumber] = useState(jobPlanning.jobnumber)
  const totalMinutes = parseFloat(jobPlanning.quantity) * 60
  const [minutes, setMinutes] = useState(totalMinutes % 60 || 0)
  const [hours, setHours] = useState(Math.floor(totalMinutes / 60) || 0)
  const [worktypeId, setWorktypeId] = useState(jobPlanning.worktype_id)
  const [taskId, setTaskId] = useState(jobPlanning.task_id)
  const jobnumberId = (jobnumber && jobnumber.id) || 0
  const fieldId = field => `field-${field}`
  const fieldName = field => `job_planning[${field}]`
  const { comment, placement, key } = jobPlanning
  const placeOfProduction = jobPlanning.place_of_production
  const finishedDate = jobPlanning.finished_date
  const isNewRecord = !key

  return (
    <>
      <FormFieldUsers
        isNewRecord={isNewRecord}
        currentUser={currentUser}
        availableUsers={availableUsers}
        availableDepartments={availableDepartments}
        defaultUserId={jobPlanning.user_id}
        fieldName={fieldName}
        fieldId={fieldId}
      />
      <OrderNumberPicker id={jobnumberId} orderNumber={jobnumber} setOrderNumber={setJobnumber} />
      <input type="hidden" name={fieldName('jobnumber_id')} value={jobnumberId} />
      <input type="hidden" name={fieldName('key')} value={key} />

      <div className="row">
        <div className="col-sm-6">
          <DependentTaskPicker jobnumber={jobnumber} name={fieldName('task_id')} taskId={taskId} setTaskId={setTaskId} disabled={!!key} />
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor={fieldId('finished_date')}>Slutdato</label>
          <input required type="date" className="form-control col-xs-12" defaultValue={finishedDate} name={fieldName('finished_date')} />
        </div>

        <div className="col-sm-6 form-group">
          <label htmlFor={fieldId('worktype_id')}>Arbejdstype</label>
          <select
            id={fieldId('worktype_id')}
            name={fieldName('worktype_id')}
            className="form-control resources-time-worktype"
            value={worktypeId}
            onChange={e => setWorktypeId(e.target.value)}
          >
            {availableWorktypes.map(worktype => {
              const [title, id] = worktype
              return (
                <option key={id} value={id}>
                  {title}
                </option>
              )
            })}
          </select>
        </div>

        <div className="col-sm-6 form-group">
          <div className="resources-time-selects row">
            <div className="form-group col-sm-6">
              <label>Tid</label>
              <HoursPicker hours={hours} setHours={setHours} name={fieldName('hours')} />
            </div>

            <div className="form-group col-sm-6">
              <label>&nbsp;</label>
              <MinutesPicker minutes={minutes} setMinutes={setMinutes} name={fieldName('minutes')} />
            </div>
            <input type="hidden" name={fieldName('quantity')} value={(hours * 60 + minutes) / 60.0} />
          </div>
        </div>

        <div className="col-sm-6 form-group">
          <label htmlFor={fieldId('placement')}>Placering</label>
          <input type="text" maxLength="30" className="form-control col-xs-12" defaultValue={placement} name={fieldName('placement')} />
        </div>

        <div className="col-sm-6 form-group">
          <label htmlFor={fieldId('place_of_production')}>ØST/VEST</label>
          <select
            id={fieldId('place_of_production')}
            className="form-control"
            name={fieldName('place_of_production')}
            defaultValue={placeOfProduction}
            required
          >
            <option value="ØST">ØST</option>
            <option value="VEST">VEST</option>
          </select>
        </div>

        <div className="col-sm-12 form-group">
          <label htmlFor={fieldId('comment')}>Bemærkning</label>
          <input type="text" className="form-control col-xs-12" defaultValue={comment} name={fieldName('comment')} />
        </div>
      </div>
    </>
  )
}

FormFields.propTypes = {
  jobPlanning: PropTypes.shape({
    jobnumber_id: PropTypes.string,
    jobnumber: PropTypes.object,
    quantity: PropTypes.string,
    comment: PropTypes.string,
    actually_completed: PropTypes.string,
    description: PropTypes.string,
    finished_date: PropTypes.string,
    placement: PropTypes.string,
  }).isRequired,
}

export default FormFields
