export type ViewType = 'table' | 'cards'
interface ViewSwitcherProps {
  currentView: ViewType
  setCurrentView: (view: ViewType) => void
}
function ViewSwitcher({ currentView, setCurrentView }: ViewSwitcherProps) {
  const buttonClasses = buttonView => {
    const defaultClasses = 'btn btn-outline-secondary'
    return buttonView === currentView ? `${defaultClasses} active` : defaultClasses
  }
  return (
    <div className="btn-group job-plannings-view-switcher" role="group" aria-label="View switcher">
      <button type="button" className={buttonClasses('table')} onClick={() => setCurrentView('table')}>
        <i className="fas fa-th-list" />
      </button>
      <button type="button" className={buttonClasses('cards')} onClick={() => setCurrentView('cards')}>
        <i className="fas fa-th-large" />
      </button>
    </div>
  )
}

export default ViewSwitcher
