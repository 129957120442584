import { useStorageState } from 'react-storage-hooks'
import { useJobPlanningLinesQuery } from './queries/job_planning_lines'
import JobPlanningLinesCollection from '../models/JobPlanningLinesCollection'

function defaultFilterForUser(currentUser) {
  let defaultFilter = {
    no: [currentUser.username],
    job_task_no: [],
  }

  const savedFilters = currentUser.saved_filters || []
  if (currentUser.saved_filters && currentUser.saved_filters.length > 0) {
    const savedDefaultFilter = savedFilters.find(f => f.default)
    if (savedDefaultFilter) {
      defaultFilter = savedDefaultFilter.filter
    }
  }
  return defaultFilter
}

export default function useJobPlanningsController(currentUser) {
  const initialFilter = defaultFilterForUser(currentUser)
  const [filter, setFilter] = useStorageState(sessionStorage, 'filter', initialFilter)
  const [showCompletedToday, setShowCompletedToday] = useStorageState(sessionStorage, 'showCompletedToday', false)
  const [currentView, setCurrentView] = useStorageState(localStorage, 'currentView', 'table') // [table, cards]
  const [sorting, setSorting] = useStorageState(sessionStorage, 'job-planning-lines-sorting', {
    column: 'job_id',
    direction: 'asc',
  })
  const { isLoading, isError, data, error } = useJobPlanningLinesQuery(filter, showCompletedToday)

  const jobPlannings = new JobPlanningLinesCollection(data, isLoading, isError, error, sorting)

  return {
    filter,
    setFilter,
    showCompletedToday,
    setShowCompletedToday,
    currentView,
    setCurrentView,
    jobPlannings,
    sorting,
    setSorting,
  }
}
