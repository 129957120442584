import axios from 'axios'
import { download_files_jobnumber_path } from '../routes'

export function downloadAttachments(payload = {}) {
  const options = {
    url: download_files_jobnumber_path(payload.jobnumber_id, {format: 'json'}),
    method: 'post',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}
