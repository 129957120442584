import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { week_list_path } from "../../routes"

function DepartmentsPicker({ startDate, endDate, availableDepartments, selectedDepartmentIds }) {
  const options = availableDepartments.map(t => {
    return { value: t[1], label: t[0] }
  })
  const selectedOptions = selectedDepartmentIds.map(n => options.find(o => o.value === parseInt(n, 10)))
  return (
    <Select
      id="week-list-departments-filter"
      placeholder="Vælg afdeling"
      isMulti
      value={selectedOptions}
      onChange={opts => {
        const ids = opts === null ? [] : opts.map(o => o.value)
        let url = week_list_path({start_date: startDate, end_date: endDate})
        ids.forEach(id => {
          url += `&department_ids[]=${id}`
        })
        window.location.href = url
      }}
      options={options}
    />
  )
}

DepartmentsPicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  availableDepartments: PropTypes.array.isRequired,
  selectedDepartmentIds: PropTypes.array.isRequired,
}

export default DepartmentsPicker
