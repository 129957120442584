import _ from 'lodash'

export function minutesToTime(minutes) {
  const intMinutes = parseInt(minutes, 10)
  if (_.isUndefined(minutes) || _.isNaN(intMinutes)) {
    return '0:00'
  }
  const sign = intMinutes < 0 ? '-' : ''
  const absMinutes = Math.abs(intMinutes)
  return `${sign}${Math.floor(absMinutes / 60)}:${(absMinutes % 60).toString().padStart(2, '0')}`
}
