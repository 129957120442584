import React from 'react'
import PropTypes from 'prop-types'

function HoursPicker({ hours, setHours, name }) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">T</span>
      </div>
      <select
        name={name}
        className="form-control resources-time-hours"
        value={hours}
        onChange={e => setHours(parseInt(e.target.value, 10))}
      >
        {[...Array(24).keys()].map(val => {
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </select>
    </div>
  )
}

HoursPicker.propTypes = {
  hours: PropTypes.number.isRequired,
  setHours: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default HoursPicker
