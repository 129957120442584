import * as React from 'react'
import { useContext } from 'react'
import * as PropTypes from 'prop-types'

import NewTimeEntryButton from '../NewTimeEntryButton'
import CompletePopupButton from '../complete_popup_button/CompletePopupButton'
import ApprovalFormsButton from '../ApprovalFormsButton'
import SendMessageButton from '../send_message_popup_button/SendMessageButton'
import FilesPopupButton from '../FilesPopupButton'
import EditJobPlanningButton from './EditJobPlanningButton'
import NewJobPlanningButton from './NewJobPlanningButton'
import DuplicateJobPlanningButton from './DuplicateJobPlanningButton'
import DictionariesContext from '../../DictionariesContext'
import ConsumptionLinesPopupButton from '../ConsumptionLinesPopupButton'
import ProjectButton from '../project_button/ProjectButton'
import checkFeatureAvailability from '../../helpers/features'
import ScanPopupButton from '../scan_popup_button/ScanPopupButton'

function Actions({ jobnumberId, jobnumber, jobPlanning, currentView }) {
  const dictionaries = useContext(DictionariesContext)
  const {
    availableAreas,
    availableWorktypes,
    availableWorkcards,
    availableWarehouses,
    availablePartners,
    availableMachines,
    availableApprovalFormTemplates,
    allPartners,
    currentUser,
  } = dictionaries
  const buttonId = jobPlanning.key

  const isScanFeatureAvailable = checkFeatureAvailability('scan', { currentUser })

  return (
    <div className="job-plannings-actions text-right">
      <div className="dropleft">
        <FilesPopupButton jobnumber={jobnumber} />
        {jobPlanning.brandeasy_project_id && (
          <ProjectButton buttonClass="ml-1 btn btn-outline-secondary" jobPlanning={jobPlanning} buttonTitle="BrandEasy" />
        )}
        <button
          id={buttonId}
          type="button"
          className="btn btn-outline-secondary job-plannings-actions-trigger ml-1"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          ...
        </button>
        <div className="dropdown-menu" aria-labelledby={jobPlanning.key}>
          <NewTimeEntryButton
            buttonClass="dropdown-item"
            record={{ jobnumber, is_master: true }}
            availableWorktypes={availableWorktypes}
            availableWorkcards={availableWorkcards}
            availablePartners={availablePartners}
            availableMachines={availableMachines}
            availableWarehouses={availableWarehouses}
            allPartners={allPartners}
            hideMachinesAndItems={currentUser.hide_machines_and_items}
            hidePackagings={currentUser.hide_packagings}
          />
          {isScanFeatureAvailable && (
            <ScanPopupButton
              buttonClass="dropdown-item"
              buttonTitle="Scan"
              availableAreas={availableAreas}
              currentUser={currentUser}
              initialArea={jobPlanning.task_area}
              orderNumber={jobPlanning.order_no}
            />
          )}
          <ConsumptionLinesPopupButton buttonClass="dropdown-item" jobnumber={jobnumber} currentUser={currentUser} />
          {jobPlanning.brandeasy_project_id === null && (
            <>
              <SendMessageButton buttonClass="dropdown-item" jobPlanning={jobPlanning} />
              <ProjectButton buttonClass="dropdown-item" buttonTitle="BrandEasy" jobPlanning={jobPlanning} />
            </>
          )}
          <CompletePopupButton
            buttonClass="dropdown-item"
            jobnumberId={jobnumberId}
            jobnumber={jobnumber}
            jobPlanning={jobPlanning}
            currentView={currentView}
          />
          <ApprovalFormsButton
            buttonClass="dropdown-item"
            jobnumber={jobnumber}
            availableApprovalFormTemplates={availableApprovalFormTemplates}
          />
          <EditJobPlanningButton
            buttonClass="dropdown-item"
            submitUrl="/job_plannings/update"
            buttonTitle="Rediger"
            jobPlanning={jobPlanning}
          />
          <NewJobPlanningButton
            buttonClass="dropdown-item"
            label="Kopier"
            jobPlanning={{ ...jobPlanning, key: null, id: null, user_id: jobPlanning.user_id || currentUser.id }}
          />
          <DuplicateJobPlanningButton
            buttonClass="dropdown-item"
            label="Kopier til flere stadier"
            jobPlanning={{ ...jobPlanning, key: null, id: null, user_id: jobPlanning.user_id || currentUser.id }}
          />
        </div>
      </div>
    </div>
  )
}

Actions.propTypes = {
  jobnumber: PropTypes.object.isRequired,
  jobnumberId: PropTypes.number,
  jobPlanning: PropTypes.object.isRequired,
}

export default Actions
