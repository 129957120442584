import * as React from 'react'

type ViewAllToggleProps = {
  viewAll: boolean
}

export default function ViewAllToggle(props: ViewAllToggleProps) {
  const { viewAll } = props

  const onChange = () => {
    // redirect to the same page with view_all=true
    const url = new URL(window.location.href)
    if (viewAll) {
      url.searchParams.delete('view_all')
    } else {
      url.searchParams.set('view_all', 'true')
    }

    window.location.href = url.toString()
  }

  return (
    <div className="view-all-toggle">
      <label className="cursor-pointer">
        <input className="mr-2" type="checkbox" checked={viewAll} onChange={onChange} />
        Vis alle
      </label>
    </div>
  )
}
