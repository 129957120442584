import { createContext } from 'react'
import { CurrentUserType } from '../DictionariesContext'

export type ProductionPlanningDictionariesContextType = {
  availableAreas: string[]
  currentUser: CurrentUserType
  updateCurrentUser: (currentUser: CurrentUserType) => void
}

const ProductionPlanningDictionariesContext = createContext<ProductionPlanningDictionariesContextType>(null)
export default ProductionPlanningDictionariesContext
