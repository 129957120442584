import axios, { AxiosRequestConfig } from 'axios'
import {
  messages_path,
  api_messages_path,
  download_attachments_messages_path,
  message_destroy_attachment_path,
} from '../routes'

export function getMessages(payload = {}) {
  const options: AxiosRequestConfig = {
    url: messages_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function downloadAttachments(payload = {}) {
  const options: AxiosRequestConfig = {
    url: download_attachments_messages_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function destroyAttachment(payload = { message_id: null, attachment_id: null }) {
  const options: AxiosRequestConfig = {
    url: message_destroy_attachment_path(payload.message_id, payload.attachment_id, { format: 'json' }),
    method: 'post',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}

export function createMessage(payload) {
  const options: AxiosRequestConfig = {
    url: api_messages_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}
