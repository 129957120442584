import React from 'react'
import { items_update_from_nav_path } from '../../routes'

export default function RefreshFromNavButton() {
  return (
    <form action={items_update_from_nav_path()} method="post">
      <button type="submit" className="btn btn-outline-primary">
        Opdater fra NAV
      </button>
    </form>
  )
}
