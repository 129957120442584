import * as React from 'react'
import NewJobPlanningButton from './NewJobPlanningButton'
import TableView from './TableView'
import CardsView from './CardsView'

export default function JobPlanningsTableContent(props) {
  const { currentUser, currentView, jobPlannings, sorting, setSorting } = props
  if (jobPlannings.isError) {
    return null
  }

  return (
    <>
      {currentView === 'table' && (
        <TableView
          jobPlanningLines={jobPlannings.groupedCollection}
          isLoading={jobPlannings.isLoading}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
      {currentView === 'cards' && <CardsView jobPlanningLines={jobPlannings.groupedCollection} isLoading={jobPlannings.isLoading} />}

      <div className="row text-right d-print-none">
        <div className="col-12">
          <NewJobPlanningButton label="Tilføj stadie" jobPlanning={{ user_id: currentUser.id }} />
        </div>
      </div>
    </>
  )
}
