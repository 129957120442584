import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import DuplicateFormFields from './DuplicateFormFields'
import { create_multiple_job_plannings_path } from "../../../routes"

function DuplicateJobPlanningButton({ jobPlanning, label, buttonClass }) {
  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-primary'}>
          {label}
        </button>
      }
      position="center center"
      contentStyle={{ width: 'auto', margin: '20px auto auto' }}
      modal
    >
      {close => (
        <div className="job-planning-duplicate-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="job-planning-duplicate-modal-header popup-header">{label}</div>
          <form action={create_multiple_job_plannings_path()} method="POST">
            <div className="job-planning-modal-content popup-content">
              <DuplicateFormFields jobPlanning={jobPlanning} />
            </div>
            <div className="job-planning-modal-actions popup-actions">
              <button type="button" className="btn btn-outline-secondary" onClick={close}>
                Luk
              </button>
              <button type="submit" className="btn btn-primary">
                Save all
              </button>
            </div>
          </form>
        </div>
      )}
    </Popup>
  )
}

DuplicateJobPlanningButton.propTypes = {
  jobPlanning: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
}

export default DuplicateJobPlanningButton
