import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { useMutation } from 'react-query'
import { LightgalleryItem } from 'react-lightgallery'
import { destroyAttachment } from '../../api/messages'
import { formatBytes } from '../helpers/file'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'

function MessageAttachment({ attachment, allowDelete, messageId, reloadMessages }) {
  const group = `group_${messageId}`

  const mutation = useMutation(
    attachmentId => {
      return destroyAttachment({ message_id: messageId, attachment_id: attachmentId })
    },
    {
      onSuccess: async () => {
        reloadMessages()
      },
    }
  )

  return (
    <li className="list-group-item" key={attachment.id}>
      {attachment.preview_url && (
        <LightgalleryItem group={group} src={attachment.url} itemClassName="d-inline-block">
          <img src={attachment.preview_url} alt={attachment.filename} />
        </LightgalleryItem>
      )}
      {!attachment.preview_url && (
        <a href={attachment.url}>
          <i className="fa fa-icon fa-file" />
        </a>
      )}
      <a href={attachment.url} className="ml-1" target="_blank" rel="noopener noreferrer">
        {attachment.filename}
      </a>
      <small className="text-muted ml-1">
        ({formatBytes(attachment.size)},{' '}
        {moment(attachment.created_at)
          .tz('Europe/Copenhagen')
          .format('DD-MM-YYYY, HH:mm:ss')}
        )
      </small>
      {allowDelete && !mutation.isLoading && (
        <RemoveFieldsButton
          className="resources-remove-fields-button"
          handleRemove={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(`Are you sure to remove the file ${attachment.filename}?`)) {
              mutation.mutate(attachment.id)
            }
          }}
        />
      )}
      {allowDelete && mutation.isLoading && <i className="resources-remove-fields-button fa fa-spinner fa-spin" />}
    </li>
  )
}

MessageAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  messageId: PropTypes.number.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  reloadMessages: PropTypes.func.isRequired,
}
export default MessageAttachment
