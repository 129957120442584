import React from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import UserRow from './UserRow'
import DatesTableHeaderRows from './DatesTableHeaderRows'

const moment = extendMoment(Moment)

function Table({ startDate, endDate, data, showWeekends, showNormTime }) {
  const start = moment(startDate)
  const end = moment(endDate)
  const usersData = data.data
  const { departments, holidays } = data
  const isHolidayOrWeekend = date => {
    return holidays[date.format('YYYY-MM-DD')] || date.weekday() === 0 || date.weekday() === 6
  }

  let range = Array.from(
    moment()
      .range(start, end)
      .by('day')
  )
  if (showWeekends === false) {
    range = range.filter(date => !isHolidayOrWeekend(date))
  }

  return (
    <table className="table table-bordered">
      <thead>
        <DatesTableHeaderRows range={range} isHolidayOrWeekend={isHolidayOrWeekend} colSpanMultiplier={3} />
      </thead>
      <tbody>
        {_.map(departments, (department, departmentId) => {
          return (
            <React.Fragment key={departmentId}>
              <tr className="resources-overview-department-title">
                <td colSpan={range.length * 3 + 1}>{department.title}</td>
              </tr>
              {_.map(department.users, (user, username) => {
                return (
                  <UserRow
                    key={username}
                    range={range}
                    userData={usersData[username]}
                    isHolidayOrWeekend={isHolidayOrWeekend}
                    user={user}
                    showNormTime={showNormTime}
                  />
                )
              })}
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  showWeekends: PropTypes.bool.isRequired,
}

export default Table
