import * as React from 'react'
import { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import MinutesPicker from './MinutesPicker'
import HoursPicker from './HoursPicker'
import { separateTime } from '../shared/utils'
import RemovedField from './RemovedField'

function TimeFields({ className, idKey, timeFields, setTimeFields, showSignControl, availableWorktypes, fieldNameFn, handleRemove }) {
  const timeId = timeFields.id || null
  const worktypeId = timeFields.worktype_id || ''
  const comment = timeFields.comment || ''
  let signMultiplier = timeFields.time && timeFields.time < 0 ? -1 : 1
  const [hours, minutes] = separateTime(Math.abs(timeFields.time))
  useEffect(() => {
    signMultiplier = timeFields.time && timeFields.time < 0 ? -1 : 1
  }, [timeFields.time])
  const fieldId = field => `${idKey}-field-${field}`

  if (timeFields._destroy === true) {
    return <RemovedField field={timeFields} fieldNameFn={fieldNameFn} />
  }

  return (
    <fieldset className={`position-relative form-fieldset ${className}`}>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={fieldId('worktype_id')}>Arbejdstype</label>
          <select
            id={fieldId('worktype_id')}
            name={fieldNameFn('worktype_id')}
            className="form-control resources-time-worktype"
            value={worktypeId}
            onChange={e => setTimeFields({ ...timeFields, worktype_id: e.target.value })}
            required
          >
            <option />
            {availableWorktypes.map(worktype => {
              const [title, id] = worktype
              return (
                <option key={id} value={id}>
                  {title}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div className="row">
        <div className={`${showSignControl ? 'col-md-5' : 'col-md-7'} form-group`}>
          <label htmlFor={fieldId('comment')}>Beskrivelse</label>
          <input
            id={fieldId('comment')}
            name={fieldNameFn('comment')}
            className="form-control resources-time-comment"
            value={comment}
            onChange={e => setTimeFields({ ...timeFields, comment: e.target.value })}
          />
        </div>
        <div className={showSignControl ? 'col-md-7' : 'col-md-5'}>
          <div className="resources-time-selects">
            {showSignControl && (
              <div className="form-group mr-1">
                <label htmlFor={fieldId('sign')}>&nbsp;</label>
                <div>
                  <div className="btn-group btn-group-toggle">
                    <label className={`btn ${signMultiplier >= 0 ? 'active resources-adjustment-up' : 'btn-outline-secondary '}`}>
                      <input
                        type="radio"
                        checked={signMultiplier >= 0}
                        onChange={e => setTimeFields({ ...timeFields, time: Math.abs(timeFields.time) })}
                      />
                      <span>+</span>
                    </label>
                    <label className={`btn ${signMultiplier < 0 ? 'active resources-adjustment-down' : 'btn-outline-secondary '}`}>
                      <input
                        type="radio"
                        checked={signMultiplier < 0}
                        onChange={e => {
                          const newTime = timeFields.time === undefined || timeFields.time === 0 ? -60 : -1 * Math.abs(timeFields.time)
                          setTimeFields({ ...timeFields, time: newTime })
                        }}
                      />
                      <span>-</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group pr-1 time-picker-field">
              <label>Tid</label>
              <HoursPicker
                hours={hours}
                setHours={h => setTimeFields({ ...timeFields, time: (h * 60 + minutes) * signMultiplier })}
                name={fieldNameFn('hours')}
              />
            </div>

            <div className="form-group pr-1 time-picker-field">
              <label>&nbsp;</label>
              <MinutesPicker
                minutes={minutes}
                setMinutes={m => setTimeFields({ ...timeFields, time: (hours * 60 + m) * signMultiplier })}
                name={fieldNameFn('minutes')}
              />
              <input className="invisible-field" type="number" onChange={() => {}} value={hours * 60 + minutes} min={5} />
            </div>
            <input type="hidden" name={fieldNameFn('time')} value={(hours * 60 + minutes) * signMultiplier} min={5} />
            {timeId && <input type="hidden" name={fieldNameFn('id')} value={timeId} />}
          </div>
        </div>
      </div>

      <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />
    </fieldset>
  )
}

TimeFields.propTypes = {
  idKey: PropTypes.string.isRequired,
  timeFields: PropTypes.shape({
    time: PropTypes.number,
  }).isRequired,
  setTimeFields: PropTypes.func.isRequired,
  availableWorktypes: PropTypes.array.isRequired,
}

export default TimeFields
