import axios from 'axios'
import { api_tasks_path } from '../routes'

export function getTasks(jobnumber_id) {
  const options = {
    url: api_tasks_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: { jobnumber_id: jobnumber_id },
  }
  return axios(options).then(response => response.data)
}
