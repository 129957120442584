import moment from 'moment'

export function dateSummary(date, jobPlannings) {
  const totalTimeReducer = (accumulator, jobPlanning) => accumulator + parseFloat(jobPlanning.quantity)
  const totalTime = jobPlannings.reduce(totalTimeReducer, 0)
  const time = `${Math.floor(totalTime)}:${Math.round((totalTime * 60) % 60)}`

  const noDate = moment(date).isSame('0001-01-01')
  const weekdays = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag']
  const formattedDate = noDate ? 'Ingen dato' : `${weekdays[moment(date).weekday()]} - ${moment(date).format('DD-MM-YYYY')}`

  return `${formattedDate} | ${time} ${totalTime === 1 ? 'time' : 'timer'}`
}

export default {
  dateSummary,
}
