import React from 'react'
import { ReactFormBuilder } from 'react-form-builder2'
import { customItems, registerCustomItems } from './ApprovalFormInputs.js'

function ApprovalFormTemplateApp() {
  const formDefinitionInput = document.getElementsByClassName('js-approval-form-template-form-definition-field')[0]
  const formBuilderDataKey = 'task_data'

  const saveToFormInput = data => {
    formDefinitionInput.value = JSON.stringify(data[formBuilderDataKey])
  }

  const loadFromFormInput = () => {
    const data = formDefinitionInput.value ? JSON.parse(formDefinitionInput.value) : {}
    return Promise.resolve(data)
  }

  registerCustomItems()

  return (
    <div className="react-form-builder-2">
      <ReactFormBuilder onPost={saveToFormInput} onLoad={loadFromFormInput} toolbarItems={customItems} />
    </div>
  )
}

ApprovalFormTemplateApp.propTypes = {}

export default ApprovalFormTemplateApp
