import * as React from 'react'
import * as PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from 'react-query'
import SearchFiles from './SearchFiles'
import DictionariesContext from '../DictionariesContext'

function SearchFilesApp({ currentUser, allPartners, availableUsers, defaultTab, defaultJobnumber, smProjectUrl, createSMProjectUrl, availableWarehouses}) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  const dictionaries = {
    smProjectUrl,
    currentUser,
    allPartners,
    availableUsers,
    createSMProjectUrl,
    availableWarehouses,
  }

  return (
    <DictionariesContext.Provider value={dictionaries}>
      <QueryClientProvider client={queryClient}>
        <SearchFiles defaultTab={defaultTab} defaultJobnumber={defaultJobnumber} />
      </QueryClientProvider>
    </DictionariesContext.Provider>
  )
}

SearchFilesApp.propTypes = {
  currentUser: PropTypes.object.isRequired,
  availableUsers: PropTypes.array.isRequired,
  defaultTab: PropTypes.string,
  defaultJobnumber: PropTypes.object,
}

export default SearchFilesApp
