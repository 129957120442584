import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import FeedbackButton from './FeedbackButton'

export default function FeedbackApp() {
  const queryClient = buildQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <div className="pt-3">
        <FeedbackButton />
      </div>
    </QueryClientProvider>
  )
}
