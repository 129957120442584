import { useContext } from 'react'
import * as PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import FormFields from './FormFields'
import { create_job_plannings_path } from '../../../routes'
import DictionariesContext from '../../DictionariesContext'

function NewJobPlanningButton({ jobPlanning, label, buttonClass }) {
  const dictionaries = useContext(DictionariesContext)
  const { currentUser } = dictionaries
  if (!currentUser.is_department_manager) {
    if (!currentUser.allow_to_add_job_plannings) {
      // Do not allow to add job planning for regular users
      return null
    }
  }
  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-primary'}>
          {label}
        </button>
      }
      position="center center"
      contentStyle={{ width: 'auto', maxWidth: '720px' }}
      modal
    >
      {close => (
        <div className="job-planning-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="job-planning-modal-header popup-header">{label}</div>
          <form action={create_job_plannings_path()} method="POST">
            <div className="job-planning-modal-content popup-content">
              <FormFields jobPlanning={jobPlanning} />
            </div>
            <div className="job-planning-modal-actions popup-actions">
              <button type="button" className="btn btn-outline-secondary" onClick={close}>
                Luk
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      )}
    </Popup>
  )
}

NewJobPlanningButton.propTypes = {
  jobPlanning: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
}

export default NewJobPlanningButton
