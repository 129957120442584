import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useCreateFeedbackMutation } from '../../hooks/queries/feedbacks'

type FeedbackFormProps = {
  closeModal: () => void
  setFeedbackType: (feedbackType: string) => void
  feedbackType: string
}

export default function FeedbackForm(props: FeedbackFormProps) {
  const { closeModal, feedbackType, setFeedbackType } = props
  const defaultValues = {
    message: '',
    feedback_type: feedbackType,
    url: window.location.href,
    file: null,
  }
  const form = useForm({ defaultValues })
  const { handleSubmit, register, formState, setFocus } = form
  const { errors } = formState
  useEffect(() => {
    setFocus('message')
  }, [])

  const mutation = useCreateFeedbackMutation()

  const onSubmit = data => {
    const formData = new FormData()
    formData.append('feedback[message]', data.message)
    formData.append('feedback[feedback_type]', data.feedbackType)
    formData.append('feedback[url]', data.url)
    if (data.file) {
      formData.append('feedback[file]', data.file[0])
    }

    mutation.mutate(formData, { onSuccess: () => form.reset(defaultValues) })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        {mutation.isError && <div className="invalid-feedback">Dette felt er påkrævet</div>}
        {mutation.isSuccess && <p className="p-4 text-center">Tak for din feedback!</p>}
        {!mutation.isSuccess && !mutation.isError && (
          <div className="feedback-content">
            <textarea
              id="feedback-message"
              className={`form-control${errors.message ? ' is-invalid' : ''}`}
              placeholder="Din feedback her!"
              {...register('message', { required: true })}
              maxLength={500}
              rows={5}
            />
            <input className="mt-3 form-control-file" type="file" {...register('file')} />
          </div>
        )}

        <div className="production-planning-filter-modal-actions popup-actions">
          {(mutation.isError || mutation.isSuccess) && (
            <button type="button" className="btn btn-primary" onClick={closeModal}>
              Luk
            </button>
          )}
          {!mutation.isSuccess && !mutation.isError && (
            <>
              <button type="button" className="btn btn-outline-secondary" onClick={() => setFeedbackType('')}>
                Tilbage
              </button>
              <button type="submit" className="btn btn-primary">
                {mutation.isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Send'}
              </button>
            </>
          )}
        </div>
      </form>
    </>
  )
}
