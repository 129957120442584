import React, { useState } from 'react'
import HoursPicker from '../resources_records/HoursPicker'
import MinutesPicker from '../resources_records/MinutesPicker'

function TimeInput({ className, value, name }) {
  const [time, setTime] = useState(value)

  const minutes = time % 60 || 0
  const hours = Math.floor(time / 60) || 0

  return (
    <div className={`${className} d-flex`}>
      <div className="form-group pr-1 time-picker-field">
        <label>Tid</label>
        <HoursPicker hours={hours} setHours={h => setTime(h * 60 + minutes)} name="hours" />
      </div>

      <div className="form-group time-picker-field">
        <label>&nbsp;</label>
        <MinutesPicker minutes={minutes} setMinutes={m => setTime(hours * 60 + m)} name="minutes" />
      </div>
      <input type="hidden" name={name} value={hours * 60 + minutes} />
    </div>
  )
}

export default TimeInput
