import axios, { AxiosRequestConfig } from 'axios'
import { api_feedbacks_path } from '../routes'

export function createFeedback(payload) {
  const options: AxiosRequestConfig = {
    url: api_feedbacks_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}
