import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import { useQuery } from 'react-query'
import { getTasks } from '../../../api/tasks'
import DuplicateFormFieldsRow from './DuplicateFormFieldsRow'
import DuplicateFormExistingJobPlannings from './DuplicateFormExistingJobPlannings'

function DuplicateFormFields({ jobPlanning }) {
  const { jobnumber } = jobPlanning
  const [rows, setRows] = useState([])

  const { isLoading, data: tasks, error } = useQuery(['tasks', jobnumber.id], () => getTasks(jobnumber.id))

  const insertRow = () => setRows([...rows, { ...jobPlanning, id: uuid() }])
  const removeRow = id => setRows(rows.filter(row => row.id !== id))

  useEffect(() => {
    insertRow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin fa-2x" />
      </div>
    )
  }
  if (error) {
    return <div className="job-plannings-table-error">{error.message}</div>
  }

  return (
    <table className="table job-planning-clone-table">
      <thead>
        <tr>
          <th className="align-middle">Opgnr.</th>
          <th className="align-middle">Slutdato</th>
          <th className="align-middle">Nr</th>
          <th className="align-middle">Timer</th>
          <th className="align-middle">Bemærkning</th>
          <th className="align-middle">Placering</th>
          <th className="align-middle">
            <button className="btn btn-primary btn-sm job-planning-clone-table-add" type="button" onClick={insertRow}>
              +
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <DuplicateFormExistingJobPlannings jobPlanning={jobPlanning} />
        {rows.map((row, idx) => {
          return (
            <DuplicateFormFieldsRow
              key={row.id}
              jobPlanning={row}
              fieldName={field => `job_plannings[${idx}][${field}]`}
              removeRow={() => removeRow(row.id)}
              tasks={tasks}
              showRemoveFieldsButton={rows.length > 1}
            />
          )
        })}
      </tbody>
    </table>
  )
}

DuplicateFormFields.propTypes = {
  jobPlanning: PropTypes.shape({
    jobnumber_id: PropTypes.string,
    jobnumber: PropTypes.object,
    quantity: PropTypes.string,
    comment: PropTypes.string,
    actually_completed: PropTypes.string,
    description: PropTypes.string,
    finished_date: PropTypes.string,
    placement: PropTypes.string,
  }).isRequired,
}

export default DuplicateFormFields
