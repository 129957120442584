import Popup from 'reactjs-popup'
import { Travel } from './travel_list'
import TravelForm from './TravelForm'
import TravelDictionariesContext from './TravelDictionariesContext'
import { useContext } from 'react'

interface EditTravelButtonProps {
  travel?: Travel
  buttonTitle?: string
  windowTitle?: string
  buttonClass?: string
}

export default function EditTravelButton({
  travel,
  buttonTitle = '+ Tilføj',
  windowTitle = 'Tilføj rejse',
  buttonClass = 'btn btn-outline-primary',
}: EditTravelButtonProps) {
  const { availableUsers, currentUser } = useContext(TravelDictionariesContext)

  if (travel !== undefined && (travel.driver.id !== currentUser.id && travel.user.id !== currentUser.id)) return null

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '420px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      modal
      nested
    >
      {close => (
        <div className="modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="popup-header">{windowTitle}</div>
          <TravelForm travel={travel} availableUsers={availableUsers} currentUser={currentUser} close={close} />
        </div>
      )}
    </Popup>
  )
}
