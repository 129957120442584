export default function checkFeatureAvailability(feature: string, options: any) {
  const { currentUser } = options
  switch (feature) {
    case 'consumption':
      return !currentUser.hide_consumption_list
    case 'scan':
      return !currentUser.hide_scan
    default:
      return false
  }
}
