import DatesTableHeaderRows from '../resources_overview_app/DatesTableHeaderRows'
import { isEmpty } from 'lodash'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

interface WeekListTableProps {
  startDate: string
  endDate: string
  showWeekends: boolean
  data: any[]
}

export default function WeekListTable({ startDate, endDate, showWeekends, data }: WeekListTableProps) {
  const start = moment(startDate)
  const end = moment(endDate)
  const isHolidayOrWeekend = date => {
    return date.weekday() === 0 || date.weekday() === 6
  }
  let range = Array.from(
    moment()
      .range(start, end)
      .by('day')
  )
  if (showWeekends === false) {
    range = range.filter(date => !isHolidayOrWeekend(date))
  }
  return (
    <table className="table table-bordered table-striped week_list">
      <thead>
        <DatesTableHeaderRows range={range} firstColumnColspan={2} showWeekends={showWeekends} isHolidayOrWeekend={isHolidayOrWeekend} />
      </thead>
      <tbody>
        {data.map(dataRow => {
          return (
            <tr key={dataRow.name}>
              <td className="text-nowrap text-left">{dataRow.group}</td>
              <td className="text-nowrap text-left">
                {dataRow.can_switch_to_user ? (
                  <a
                    className="change-user-link"
                    href={`/resources/records?date=${start.format('YYYY-MM-DD')}&pretend_to_be=${dataRow.user_id}`}
                    target="_blank"
                  >
                    {dataRow.name}
                    <i className="external-link-icon fa fa-icon fa-small fa-external-link-alt ml-1" />
                  </a>
                ) : (
                  dataRow.name
                )}
              </td>
              {range.map(date => {
                const formattedDate = date.format('YYYY-MM-DD')
                const holidayClassName = isHolidayOrWeekend(date) ? 'resources-overview-holiday' : ''
                if (!isEmpty(dataRow.cells)) {
                  const cell = dataRow.cells[formattedDate]
                  return (
                    <td className={holidayClassName} key={formattedDate}>
                      {cell && (cell.absence ? 'F' : '')}
                    </td>
                  )
                }
                return <td className={holidayClassName} key={formattedDate} />
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
