import {useMutation, useQuery} from 'react-query'
import { pullShipments, getShipments } from '../../api/shipments'

export function usePullShipmentsMutation() {
  return useMutation((jobnumberId: number) => pullShipments(jobnumberId))
}

export function useShipmentsQuery(jobnumberId: number) {
  const enabled = !!jobnumberId
  return useQuery(['shipments', jobnumberId], () => getShipments(jobnumberId), { enabled: enabled })
}
