import React from 'react'
import Popup from 'reactjs-popup'
import ConsumptionLineFormFields from './ConsumptionLineFormFields'
import useConsumptionLineFormController from '../../../hooks/useConsumptionLineFormController'
import ErrorMessage from '../list_app/ErrorMessage'

export default function EditConsumptionLineButton({
  jobCostingLine,
  buttonTitle = 'Rediger',
  windowTitlePrefix = 'Rediger',
  buttonClass = 'btn btn-outline-secondary',
}) {
  const windowTitle = jobCostingLine ? `${windowTitlePrefix} ${jobCostingLine.job_id}` : windowTitlePrefix
  const { values, setValues, submit, isItemPickerDisabled, isLoading, isError, error, isSubmitDisabled } = useConsumptionLineFormController(
    jobCostingLine
  )
  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '420px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      modal
      nested
    >
      {close => (
        <div className={`resources-record-modal modal-popup`}>
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="resources-record-modal-header popup-header">{windowTitle}</div>
          <form>
            <div className="resources-record-modal-content popup-content">
              <ErrorMessage isError={isError} error={error} />
              <ConsumptionLineFormFields
                isItemPickerDisabled={isItemPickerDisabled}
                jobCostingLine={jobCostingLine}
                values={values}
                setValues={setValues}
              />
            </div>
            <div className="resources-record-modal-actions popup-actions">
              <button type="button" className="btn btn-outline-secondary" onClick={close}>
                Luk
              </button>
              {!isError && (
                <button type="button" className="btn btn-primary" onClick={() => submit(close)} disabled={isSubmitDisabled}>
                  {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Save'}
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </Popup>
  )
}
