import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import Overview from './Overview'
import ProductionPlanningDictionariesContext, { ProductionPlanningDictionariesContextType } from './ProductionPlanningDictionariesContext'
import { useState } from 'react'

type ProductPlanningAppProps = {
  area: string
} & ProductionPlanningDictionariesContextType

export default function ProductPlanningApp(props: ProductPlanningAppProps) {
  const queryClient = buildQueryClient()

  const [dictionaries, setDictionaries] = useState({
    availableAreas: props.availableAreas,
    currentUser: props.currentUser,
    updateCurrentUser: user => {
      setDictionaries({ ...dictionaries, currentUser: user })
    },
  })

  return (
    <ProductionPlanningDictionariesContext.Provider value={dictionaries}>
      <QueryClientProvider client={queryClient}>
        <div className="pt-3">
          <Overview {...props} />
        </div>
      </QueryClientProvider>
    </ProductionPlanningDictionariesContext.Provider>
  )
}
