import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import { loadOrderNumberOptions } from '../../../api/jobnumbers'

function SelectJobnumbers({ defaultJobnumbers, inputName, label, selectId }) {
  const [selectedJobnumbers, setSelectedJobnumbers] = useState(defaultJobnumbers || [])

  return (
    <div>
      <label htmlFor={selectId}>{label}</label>
      <AsyncSelect
        id={selectId}
        cacheOptions
        loadOptions={loadOrderNumberOptions}
        defaultOptions
        isClearable
        isMulti
        value={selectedJobnumbers}
        onChange={e => setSelectedJobnumbers(e || [])}
        required
      />
      {selectedJobnumbers.map(jn => (
        <input type="hidden" name={inputName} value={jn.value} key={jn.value} />
      ))}
    </div>
  )
}

SelectJobnumbers.propTypes = {
  defaultJobnumbers: PropTypes.array.isRequired,
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectId: PropTypes.string.isRequired,
}

export default SelectJobnumbers
