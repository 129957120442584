import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import OrderDetails from './OrderDetails'
import { useDebounce } from '../../hooks/useDebounce'
import { useCreateScanRecordMutation, useScanInfoQuery } from '../../hooks/queries/scan_records'
import ScanFormFields, { areaToOption } from './ScanFormFields'
import RelatedIds from './RelatedIds'
import { JobNumberShape } from '../../api/api_data_types'
import { getApiErrorMessage } from '../helpers/error'
import RelatedInfo from './RelatedInfo'
import RelatedAreas from './RelatedAreas'

type ScanFields = {
  area: { value: string; label: string }
  order_and_id: string
  operator: string
  machine: string
  order_note: string
  related_ids?: string[]
}
type ScanFormProps = {
  availableAreas: string[]
  initialValues?: ScanFields
  scanCompletedSuccessfully?: () => void
}

function dataToPayload(data) {
  const { area, ...rest } = data
  return {
    scan_record: {
      area: area.value,
      ...rest,
    },
  }
}

function parseOrderAndId(orderAndId: string) {
  if (!orderAndId) {
    return [null, null]
  }

  const onlyOrderNumberIsValid = orderAndId.match(/^\d{6}$/)
  if (onlyOrderNumberIsValid) {
    return [orderAndId, null]
  }

  const orderAndIdIsValid = orderAndId.match(/^\d{6}[+-]\d+$/)
  if (!orderAndIdIsValid) {
    return [null, null]
  }

  const separator = orderAndId.includes('-') ? '-' : '+'
  return orderAndId.split(separator)
}

export default function ScanForm(props: ScanFormProps) {
  const { availableAreas, initialValues, scanCompletedSuccessfully } = props
  const defaultArea = availableAreas[0]
  const defaultValues = {
    area: areaToOption(defaultArea),
    order_and_id: '',
    operator: '',
    machine: '',
    order_note: '',
    related_ids: [],
  }
  const defaultValuesWithInitial = { ...defaultValues, ...initialValues }
  const form = useForm({ defaultValues: defaultValuesWithInitial })
  const { formState, watch, setFocus } = form
  useEffect(() => {
    setFocus('order_and_id')
  }, [])

  const orderAndId = watch('order_and_id') // 123432-1 or 123432+1
  const relatedIds = watch('related_ids')
  const area = watch('area')
  const [orderNumber, scanId] = parseOrderAndId(orderAndId)

  const { handleSubmit } = form

  const debouncedOrderNumber = useDebounce(orderNumber, 500)
  const { isLoading, data, error, isError } = useScanInfoQuery(debouncedOrderNumber, area.value)
  let jobNumber: JobNumberShape = data?.data?.data?.job_number
  let relatedIdsData = data?.data?.data?.related
  let relatedAreasData = data?.data?.data?.related_areas

  const mutation = useCreateScanRecordMutation()
  const onSubmit = formData => {
    const payload = dataToPayload(formData)
    mutation.mutate(payload, {
      onSuccess: () => {
        form.reset({ ...defaultValues, area: formData.area })
        if (scanCompletedSuccessfully) {
          scanCompletedSuccessfully()
        }
      },
    })
  }
  const currentIdIsInRelatedIds = relatedIdsData && relatedIdsData.some(item => item['LøsningsID']?.toString() === scanId?.toString())
  const submitDisabled =
    !orderNumber || !jobNumber || mutation.isLoading || (scanId && !currentIdIsInRelatedIds) || (!scanId && relatedIds.length === 0)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {mutation.isError && (
        <div className="alert alert-danger alert-permanent alert-dismissable" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          {getApiErrorMessage(mutation.error)}
        </div>
      )}
      {mutation.isSuccess && (
        <div className="alert alert-success" role="alert">
          {mutation.data?.message}
        </div>
      )}
      <div className="row">
        <ScanFormFields form={form} availableAreas={availableAreas} />
        {orderNumber && <OrderDetails jobNumber={jobNumber} error={error} isError={isError} isLoading={isLoading} />}
      </div>
      <div className="row">
        <div className="mt-4 col-12"></div>
        <div className="col-7">
          {orderNumber && relatedIdsData && <RelatedIds data={relatedIdsData} scanId={scanId} form={form} />}
          <div className="mt-4">
            {orderNumber && (
              <button type="submit" className="btn btn-primary" disabled={submitDisabled}>
                {mutation.isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Afslut'}
              </button>
            )}
          </div>
        </div>
        <div className="col-5">
          {orderNumber && relatedAreasData && (
            <div className="mb-4">
              <RelatedAreas areas={relatedAreasData} availableAreas={availableAreas} form={form} />
            </div>
          )}
          {orderNumber && relatedIdsData && <RelatedInfo data={relatedIdsData} />}
        </div>
      </div>
    </form>
  )
}
