import { Registry } from 'react-form-builder2'
import FileUploader from './FileUploader'

const registerCustomItems = () => {
  Registry.register('FileUploader', FileUploader)
}

const customItems = [
  // Additional standard components, you don't need full definition if no modification is required.
  { key: 'Header' },
  { key: 'Label' },
  { key: 'Paragraph' },
  { key: 'LineBreak' },
  { key: 'Dropdown' },
  { key: 'Tags' },
  { key: 'Checkboxes' },
  { key: 'RadioButtons' },
  { key: 'TextInput' },
  { key: 'NumberInput' },
  { key: 'TextArea' },
  { key: 'TwoColumnRow' },
  { key: 'ThreeColumnRow' },
  { key: 'FourColumnRow' },
  { key: 'Image' },
  { key: 'Rating' },
  { key: 'DatePicker' },
  { key: 'Signature' },
  { key: 'HyperLink' },
  { key: 'Range' },
  {
    key: 'FileUploader',
    element: 'CustomElement',
    component: FileUploader,
    type: 'custom',
    forwardRef: true,
    field_name: 'file_uploader_',
    name: 'File uploader',
    icon: 'fa fa-camera',
    props: { test: 'test_input' },
    label: 'File uploader',
  },
  // { key: 'Download ' },
  // { key: 'Camera' },
]

export { customItems, registerCustomItems }
