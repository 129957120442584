import * as React from 'react'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import { JobNumberShape } from '../../api/api_data_types'

type OrderDetailsProps = {
  jobNumber: JobNumberShape
  isLoading: boolean
  isError: boolean
  error: any
}

export default function OrderDetails(props: OrderDetailsProps) {
  const { isLoading, isError, error, jobNumber } = props

  return (
    <div className="order-details col-5">
      <div className="card">
        <div className="card-body">
          <div className="card-title">
            <strong>Sags detaljer:</strong>
            {isLoading && (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-2x" />
              </div>
            )}
          </div>
          <div className="card-text">
            <ErrorMessage className="error-message text-left" isError={isError} error={error} />
            {!isLoading && !isError && jobNumber && (
              <>
                <div className="row mt-2">
                  <div className="col-6">SagsID:</div>
                  <div className="col-6">{jobNumber.job_id}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">Ordre:</div>
                  <div className="col-6">{jobNumber.order_number}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">Jobnavn:</div>
                  <div className="col-6">{jobNumber.title}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">Kunde:</div>
                  <div className="col-6">{jobNumber.customer_title}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">Ordrestyrer:</div>
                  <div className="col-6">{jobNumber.coordinator}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
