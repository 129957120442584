import * as React from 'react'
import { useState } from 'react'
import * as PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import FormFields from '../resources_records/FormFields'

import { resources_records_path } from '../../routes'
import useResourcesRecordFormController from '../../hooks/useResourcesRecordFormController'
import { useCreateResourcesRecordMutation } from '../../hooks/queries/resources_records'

type NewTimeEntryButtonProps = {
  record: any
  availableWorktypes: any[]
  availableWorkcards: any[]
  availablePartners: any[]
  availableWarehouses: any[]
  allPartners: any[]
  availableMachines: any[]
  hideMachinesAndItems?: boolean
  hidePackagings?: boolean
  buttonClass?: string
}
function NewTimeEntryButton(props: NewTimeEntryButtonProps) {
  const {
    record,
    availableWorktypes,
    availableWorkcards,
    availableWarehouses,
    availablePartners,
    allPartners,
    availableMachines,
    hideMachinesAndItems,
    hidePackagings,
    buttonClass,
  } = props
  const buttonTitle = 'Indtast tid'
  const [successfullyCreated, setSuccessfullyCreated] = useState(false)

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-secondary'}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '720px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      onClose={() => setSuccessfullyCreated(false)}
      modal
    >
      {close => {
        const { formData, setFormData, transform } = useResourcesRecordFormController(record)
        const mutation = useCreateResourcesRecordMutation(() => setSuccessfullyCreated(true))

        const handleSubmit = e => {
          e.preventDefault()
          mutation.mutate({ resources_record: transform(formData) })
        }

        return (
          <div className={`resources-record-modal modal-popup ${mutation.isLoading ? 'loading' : ''}`}>
            <a className="close" onClick={close}>
              &times;
            </a>
            <div className="resources-record-modal-header popup-header">{buttonTitle}</div>
            <form onSubmit={handleSubmit} action={resources_records_path({ format: 'json' })} method="POST">
              <div className="resources-record-modal-content popup-content">
                {successfullyCreated && <div className="alert alert-success"> Record successfully created! </div>}
                {!successfullyCreated && (
                  <FormFields
                    record={record}
                    formData={formData}
                    setFormData={setFormData}
                    availableWorktypes={availableWorktypes}
                    availableWorkcards={availableWorkcards}
                    availableWarehouses={availableWarehouses}
                    availablePartners={availablePartners}
                    availableMachines={availableMachines}
                    allPartners={allPartners}
                    hideMachinesAndItems={hideMachinesAndItems}
                    hidePackagings={hidePackagings}
                  />
                )}
              </div>
              {mutation.isError && (
                <div className="resources-record-modal-error">
                  {mutation.error.message}. Make sure you have all fields properly filled and try again.
                </div>
              )}
              <div className="resources-record-modal-actions popup-actions">
                <button type="button" className="btn btn-outline-secondary" onClick={close}>
                  Luk
                </button>
                {!successfullyCreated && (
                  <button type="submit" className="btn btn-primary" disabled={mutation.isLoading}>
                    {mutation.isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Save'}
                  </button>
                )}
              </div>
            </form>
          </div>
        )
      }}
    </Popup>
  )
}

NewTimeEntryButton.propTypes = {
  record: PropTypes.object.isRequired,
  availableWorktypes: PropTypes.array.isRequired,
  availableWorkcards: PropTypes.array.isRequired,
  availablePartners: PropTypes.array.isRequired,
  availableMachines: PropTypes.array.isRequired,
  allPartners: PropTypes.array.isRequired,
  hideMachinesAndItems: PropTypes.bool,
  hidePackagings: PropTypes.bool,
  buttonClass: PropTypes.string,
}

NewTimeEntryButton.defaultProps = {}

export default NewTimeEntryButton
