function findTaskName(taskId, tasks) {
  const task = tasks.find(t => t[1] === taskId)
  if (task) {
    return task[0]
  }
}

export default function filterToString(filter, tasks) {
  const filterString = Object.keys(filter)
    .map(key => {
      if (filter[key] === null || filter[key].length === 0) {
        return ''
      }
      switch (key) {
        case 'no':
          return `Resource: ${filter[key].join(', ')}`
        case 'job_task_no':
          return `Afdeling: ${filter[key].map(taskId => findTaskName(taskId, tasks)).join(', ')}`
        case 'job_id':
          return `Ordre ID: ${filter[key]}`
        case 'place_of_production':
          return `ØST/VEST: ${filter[key]}`
        case 'start_date':
          return `Dato fra: ${filter[key]}`
        case 'end_date':
          return `Dato til: ${filter[key]}`
        default:
          console.log(`UNKNOWN FILTER KEY: ${key}`, filter[key])
          return ''
      }
    })
    .filter(s => s.length !== 0)
    .join('; ')

  return filterString
}
