import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import SortableSelect, { arrayMove, SortableMultiValue } from '../../sortable_select/SortableSelect'
import DictionariesContext from '../../DictionariesContext'

function DepartmentWorktypes({ department }) {
  const dictionaries = useContext(DictionariesContext)
  const { availableWorktypes } = dictionaries
  const worktypeOptions = availableWorktypes.map(w => {
    return { label: w.title, value: w.id }
  })
  const initialWorktypeIds = department.worktypes ? department.worktypes.map(w => w.id) : []
  const [selectedWorktypeIds, setSelectedWorktypeIds] = useState(initialWorktypeIds)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedWorktypeIds, oldIndex, newIndex)
    setSelectedWorktypeIds(newValue)
  }

  return (
    <div className="mt-4">
      <h4>{department.name}</h4>
      <SortableSelect
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        placeholder="Vælg eller skriv..."
        value={selectedWorktypeIds.map(worktypeId => worktypeOptions.find(u => u.value === worktypeId))}
        onChange={opts => setSelectedWorktypeIds(opts === null ? [] : opts.map(o => o.value))}
        options={worktypeOptions}
        components={{
          MultiValue: SortableMultiValue,
        }}
        closeMenuOnSelect={false}
      />
      {selectedWorktypeIds.map(worktypeId => {
        return <input key={worktypeId} type="hidden" name={`department_worktypes[${department.id}][worktype_ids][]`} value={worktypeId} />
      })}
    </div>
  )
}

DepartmentWorktypes.propTypes = {
  department: PropTypes.object.isRequired,
}

export default DepartmentWorktypes
