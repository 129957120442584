import * as React from 'react'

type RelatedInfoProps = {
  data: any
}

export default function RelatedInfo(props: RelatedInfoProps) {
  const { data } = props

  const solutionsInfo = data.reduce((acc: any, item: any) => {
    const solutionId = item['LøsningsID']
    const itemData = { amount: item['Antal'], name: item['Varebeskrivelse'] }
    if (acc[solutionId] === undefined) {
      acc[solutionId] = [itemData]
    } else {
      acc[solutionId].push(itemData)
    }
    return acc
  }, {})

  return (
    <div className="scan-form-related-info">
      <h5>Related items</h5>
      <table className="table">
        <tbody>
          {Object.keys(solutionsInfo).map(solutionId => {
            const solutionData = solutionsInfo[solutionId]
            return (
              <React.Fragment key={solutionId}>
                {solutionData.map((item: any) => {
                  const { amount, name } = item
                  return (
                    <tr key={`${solutionId}-${name}`} className="related-info-item-data">
                      <td>{solutionId}</td>
                      <td>{name}</td>
                      <td>{amount}</td>
                    </tr>
                  )
                })}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
