import { weekly_reports_path } from '../../routes'
import { useState } from 'react'

interface HideApprovedCheckboxProps {
  hideApproved: boolean
  week: number
  year: number
}

export default function HideApprovedCheckbox({ hideApproved, week, year }: HideApprovedCheckboxProps) {
  const [loading, setLoading] = useState(false)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const newHideApproved = e.target.checked
    window.location.href = weekly_reports_path({ hide_approved: newHideApproved, week: week, year: year })
  }
  return (
    <label className="label">
      {loading && <i className="fas fa-spinner fa-spin mr-2"></i>}
      {!loading && <input type="checkbox" name="hide_approved" className="mr-2" checked={hideApproved} value={1} onChange={onChange} />}
      Skjul godkendte rapporter
    </label>
  )
}
