import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactFormGenerator } from 'react-form-builder2'
import { registerCustomItems } from './ApprovalFormInputs.js'

function ApprovalFormGenerator({ formDefinition }) {
  useEffect(() => {
    // Trick to avoid ink offset on Signature elements on retina screens
    const { $ } = window
    const signaturePad = $('.react-form-builder-form').find('canvas')
    if (signaturePad.get(0)) {
      const initialWidth = signaturePad.get(0).width
      signaturePad.get(0).width = 200
      signaturePad.get(0).width = initialWidth
    }
  })
  registerCustomItems()
  return (
    <div className="react-form-builder-2">
      <ReactFormGenerator hide_actions data={formDefinition} />
    </div>
  )
}

ApprovalFormGenerator.propTypes = {
  formDefinition: PropTypes.array.isRequired,
}

export default ApprovalFormGenerator
