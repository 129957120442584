import React, { useContext } from 'react'
import Select from 'react-select'
import DictionariesContext from '../../DictionariesContext'

export default function TaskSelect({ filter, setFilter }) {
  const dictionaries = useContext(DictionariesContext)
  const taskOptions = dictionaries.availableTasks.map(t => {
    return { value: t[1], label: t[0] }
  })
  const jobTaskNo = filter.job_task_no

  return (
    <div className="form-group">
      <label htmlFor="job-plannings-filter-taskno">Afdeling</label>
      <Select
        id="job-plannings-filter-taskno"
        placeholder="Vælg eller skriv..."
        isMulti
        value={jobTaskNo && jobTaskNo.map(n => taskOptions.find(o => o.value === parseInt(n, 10)))}
        onChange={opts => {
          const options = opts === null ? [] : opts.map(o => o.value)
          return setFilter({ ...filter, job_task_no: options })
        }}
        options={taskOptions}
      />
    </div>
  )
}
