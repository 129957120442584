import React, { useState, useContext } from 'react'
import { useStorageState } from 'react-storage-hooks'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import Select from 'react-select'
import DictionariesContext from '../DictionariesContext'

function Filter({ initialFilter, onSubmit }) {
  const [filter, setFilter] = useState(initialFilter)
  const [showAllUsers, setShowAllUsers] = useStorageState(sessionStorage, 'filter-show-all-users', false)

  const { no } = filter
  const departmentIds = filter.department_ids || []

  const dictionaries = useContext(DictionariesContext)
  const { currentUser, availableDepartments } = dictionaries
  const users = showAllUsers ? dictionaries.availableUsers : [currentUser, ...dictionaries.availablePartners]

  const valueForNumberOption = u => (Object.prototype.hasOwnProperty.call(u, 'username') ? u.username : u.no)
  const toNumberOption = u => {
    const value = valueForNumberOption(u)
    const label = [value, u.name].join(' - ')
    return { value, label }
  }

  let numberOptions = users.map(toNumberOption)

  const departmentOptions = availableDepartments.map(t => {
    const label = [t.task_no, t.title].join(' - ')
    return { value: t.id, label }
  })

  // users array might not contain options from saved filter - need to append missed,
  // so they're always visible as selected
  if (no.length > 0) {
    const numberValues = numberOptions.map(o => o.value)
    no.forEach(n => {
      if (!numberValues.includes(n)) {
        let label = ''
        // try to find in availableUsers, then in availableMachines
        const user = find(dictionaries.availableUsers, u => u.username === n)
        if (user) {
          label = [n, user.name].join(' - ')
        } else {
          const machine = find(dictionaries.availableMachines, m => m.no === n)
          if (machine) {
            label = [n, machine.name].join(' - ')
          } else {
            // (unlikely case) not found neither in availableUsers nor in
            // availableMachines - leave only value as a label
            label = n
          }
        }
        numberOptions = [{ value: n, label }, ...numberOptions]
      }
    })
  }

  return (
    <div className="job-plannings-filter">
      <div className="form-group">
        <label htmlFor="job-plannings-filter-number">Bruger</label>
        <div className="form-check job-plannings-filter-show-all">
          <label>
            <input type="checkbox" checked={showAllUsers} onChange={e => setShowAllUsers(e.target.checked)} />
            <span className="record-partners-select-show-all">Vis alle medarbejdere</span>
          </label>
        </div>
        <Select
          id="job-plannings-filter-number"
          isMulti
          value={no.map(n => numberOptions.find(o => o.value === n))}
          onChange={opts => {
            const options = opts === null ? [] : opts.map(o => o.value)
            return setFilter({ ...filter, no: options })
          }}
          options={numberOptions}
        />
      </div>
      <div className="form-group">
        <label htmlFor="job-plannings-filter-taskno">Afdeling</label>
        <Select
          id="job-plannings-filter-taskno"
          isMulti
          value={departmentIds.map(n => departmentOptions.find(o => o.value === parseInt(n, 10)))}
          onChange={opts => {
            const options = opts === null ? [] : opts.map(o => o.value)
            return setFilter({ ...filter, department_ids: options })
          }}
          options={departmentOptions}
        />
      </div>
      <div className="form-group">
        <label>&nbsp;</label>
        <div>
          <button type="button" className="btn btn-outline-primary" onClick={() => onSubmit(filter)}>
            Filter
          </button>
        </div>
      </div>
    </div>
  )
}

Filter.propTypes = {
  initialFilter: PropTypes.shape({
    no: PropTypes.array,
    department_ids: PropTypes.array,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Filter
