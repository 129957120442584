import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import FilesTable from './FilesTable'

function FilesPopupButton({ jobnumber }) {
  let popupTitle = 'Filer'
  if (jobnumber) {
    popupTitle = `${popupTitle} ${jobnumber.job_id}`
  }
  return (
    <Popup
      trigger={
        <button type="button" className="btn btn-outline-secondary">
          Filer
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '720px', minWidth: '300px' }}
      modal
    >
      {close => (
        <div className="files-button-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="files-button-modal-header popup-header">{popupTitle}</div>
          <div className="files-button-modal-content popup-content">
            <FilesTable jobnumber={jobnumber} />
          </div>
          <div className="files-button-modal-actions popup-actions">
            <button type="button" className="btn btn-outline-secondary" onClick={close}>
              Luk
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

FilesPopupButton.propTypes = {
  jobnumber: PropTypes.object.isRequired,
}

export default FilesPopupButton
