import DictionariesContext from '../../DictionariesContext'
import { useContext } from 'react'

export default function ProjectButton({ jobPlanning, buttonClass = null, buttonTitle = 'BrandEasy Project...' }) {
  const { createSMProjectUrl, smProjectUrl } = useContext(DictionariesContext)
  const href = jobPlanning.brandeasy_project_id ?
    smProjectUrl + jobPlanning.brandeasy_project_id :
    createSMProjectUrl + '?job_planning_line_key=' + encodeURIComponent(jobPlanning.key)
  return (
    <a className={buttonClass || 'btn btn-outline-secondary'} href={href} target="_blank" title="BrandEasy project">
      {buttonTitle}
    </a>
  )
}
