import { Travel } from './travel_list'
import { useLeaveTravelMutation } from '../../hooks/queries/travels'

export default function LeaveTravelButton({ travel }: { travel: Travel }) {
  const mutation = useLeaveTravelMutation()
  if (mutation.isError) {
    return null
  }
  return (
    <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => mutation.mutate(travel.id)}>
      {mutation.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
      {!mutation.isLoading && 'Afmeld'}
    </button>
  )
}
