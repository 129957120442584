import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getJobCostingLines,
  createJobCostingLine,
  updateQuantitiesJobCostingLines, refreshJobCostingLines,
} from '../../api/job_costing_lines'

function payloadFromValues(values) {
  const payload = { ...values }
  if (values.item) {
    payload['item_id'] = values.item.value
  }
  if (values.date) {
    payload['date'] = values.date
  }
  return payload
}

export function useJobCostingLinesQuery(jobId, setChangedQuantities) {
  return useQuery(['job_costing_lines', jobId], () => getJobCostingLines(jobId), { onSuccess: () => setChangedQuantities({}) })
}

export function useUpdateJobCostingLineMutation(jobCostingLine, values) {
  const queryClient = useQueryClient()
  const reload = () => queryClient.invalidateQueries('job_costing_lines')

  const payload = payloadFromValues(values)
  payload['job_id'] = jobCostingLine.job_id
  const mutationFunction = () => createJobCostingLine(payload)

  return useMutation(mutationFunction, { onSuccess: reload })
}

export function useUpdateQuantitiesJobCostingLinesMutation(changedQuantities) {
  const queryClient = useQueryClient()
  const reload = () => queryClient.invalidateQueries('job_costing_lines')

  const payload = JSON.stringify(changedQuantities)
  const mutationFunction = () => updateQuantitiesJobCostingLines({ quantities: payload })

  return useMutation(mutationFunction, { onSuccess: reload })
}

export function useRefreshJobCostingLinesMutation(jobnumber: string) {
  const queryClient = useQueryClient()
  const reload = () => queryClient.invalidateQueries('job_costing_lines')

  const mutationFunction = () => refreshJobCostingLines(jobnumber)
  return useMutation(mutationFunction, { onSuccess: reload })
}