import React from 'react'
import OrderNumberPicker from "../../resources_records/OrderNumberPicker";
import {useStorageState} from "react-storage-hooks";

export default function OrderSelect({ filter, setFilter }) {
  const [jobnumber, setJobnumber] = useStorageState(sessionStorage, 'filter-jobnumber', null)
  const onChangeJobnumber = jn => {
    setJobnumber(jn)
    setFilter({ ...filter, job_id: jn && jn.job_id })
  }

  return (
    <div className="job-plannings-filter-order-number form-group">
      <OrderNumberPicker hideCustomerTitle orderNumber={filter.job_id ? jobnumber : null} setOrderNumber={onChangeJobnumber} />
    </div>
  )
}
