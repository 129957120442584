import { useJobCostingLinesQuery } from '../../../hooks/queries/job_costing_lines'
import ErrorMessage from '../list_app/ErrorMessage'
import ConsumptionLineRow from './ConsumptionLineRow'

export default function ConsumptionLinesTable({ jobnumber, changedQuantities, setChangedQuantities }) {
  const { isLoading, isError, data, error } = useJobCostingLinesQuery(jobnumber.job_id, setChangedQuantities)

  if (isError) {
    return <ErrorMessage isError={isError} error={error} />
  }
  return (
    <div className="job-costing-lines-table">
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      {data && (
        <table className="table">
          <thead>
            <tr>
              <th>Bruger</th>
              <th>Dato</th>
              <th>Vare</th>
              <th>Location</th>
              <th>Antal</th>
              <th></th>
              <th>Forkalk. antal</th>
              <th>Efterkalk. antal</th>
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <ConsumptionLineRow
                row={row}
                key={row.key}
                changedQuantities={changedQuantities}
                setChangedQuantities={setChangedQuantities}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
