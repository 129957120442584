import { Travel } from './travel_list'
import EditTravelButton from './EditTravelButton'
import JoinTravelButton from './JoinTravelButton'
import LeaveTravelButton from './LeaveTravelButton'
import GoogleMapsPin from '../shared/GoogleMapsPin'
import useTravelRow from '../../hooks/useTravelRow'

type TravelListRowProps = {
  travel: Travel
}
export default function TravelListCard({ travel }: TravelListRowProps) {
  const { formattedDate, direction, joined, canJoin, availableSeats } = useTravelRow(travel)

  const route = travel.route_option === 'bridge' ? 'Bro' : `Færge, ${travel.ferry_time}`
  return (
    <div className="card mb-2 h-100">
      <div className="card-header d-flex justify-content-between">
        <div>
          {formattedDate}&nbsp;|&nbsp;<b>{travel.pickup_time}</b>
          <span className="ml-1 badge badge-light">{direction}</span>
        </div>
        <div className="align-self-right">{travel.driver.username}</div>
      </div>
      <div className="card-body">
        <h5 className="card-title">
          {availableSeats} af {travel.seat_capacity} ledige
        </h5>
        <p className="card-text">Medrejsende: {travel.passengers.map(passenger => passenger.username).join(', ')}</p>
        <p className="card-text">
          {travel.pickup_location && (
            <span className="mr-1">
              <GoogleMapsPin address={travel.pickup_location} />
            </span>
          )}
          {travel.pickup_location}
        </p>
        <p className="card-text">{route}</p>
      </div>
      <div className="card-footer bg-transparent d-flex justify-content-end">
        <EditTravelButton travel={travel} buttonClass="btn btn-outline-secondary btn-sm" buttonTitle="Rediger" windowTitle="Rediger" />
        {canJoin && <JoinTravelButton travel={travel} />}
        {joined && <LeaveTravelButton travel={travel} />}
      </div>
    </div>
  )
}
