import { groupBy, orderBy } from 'lodash'

export default class JobPlanningLinesCollection {
  constructor(data, isLoading, isError, error, sorting) {
    this._isLoading = isLoading
    this._isError = isError
    this._error = error
    this._groupedCollection = {}
    this._groupJobPlanningLines(data, sorting)
  }

  get groupedCollection() {
    return this._groupedCollection
  }

  get isLoading() {
    return this._isLoading
  }

  get isError() {
    return this._isError
  }

  get error() {
    return this._error
  }

  _groupJobPlanningLines(data, sorting) {
    const groupedJobPlanningLines = groupBy(data, jp => jp.finished_date)

    let sortColumn = line => {
      switch (sorting.column) {
        case 'customer_title':
          return line.jobnumber['customer_title']
        case 'description':
          return line.jobnumber['title']
        default:
          return line[sorting.column]
      }
    }

    Object.keys(groupedJobPlanningLines)
      .sort()
      .forEach(date => {
        this._groupedCollection[date] = orderBy(groupedJobPlanningLines[date], sortColumn, sorting.direction)
      })
  }
}
