import axios from 'axios'
import { approval_forms_answer_path, approval_forms_answers_path } from '../routes'

export function getApprovalFormsAnswers(payload = {}) {
  const options = {
    url: approval_forms_answers_path({format: 'json'}),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function createApprovalFormsAnswer(payload = {}) {
  const options = {
    url: approval_forms_answers_path({format: 'json'}),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function updateApprovalFormsAnswer(payload = {}) {
  const options = {
    url: approval_forms_answer_path(payload.id, {format: 'json'}),
    method: 'patch',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}
