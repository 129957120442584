import React from 'react'
import DebouncedInput from '../shared/DebouncedInput'

export default function Filter({ column, table }) {
  const columnFilterValue = column.getFilterValue() || ''

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue}
        onChange={value => column.setFilterValue(value)}
        placeholder="Filter..."
        className="form-control mt-2"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}
