import * as React from 'react'
import { useState } from 'react'
import * as PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import TimeFields from './TimeFields'
import RemovedFields from './RemovedFields'
import { separateTime, timesHash } from '../shared/utils'
import OrderNumberPicker from './OrderNumberPicker'
import DependentTaskPicker from './DependentTaskPicker'

function AdjustmentsFormFields({ adjustment, availableWorktypes }) {
  const { jobnumber, date } = adjustment
  const times = adjustment.record?.times
  const initialTimes = adjustment.times && adjustment.times.length > 0 ? adjustment.times : [{}]
  const [adjustmentTimes, setAdjustmentTimes] = useState(timesHash(initialTimes))
  const [removedTimes, setRemovedTimes] = useState([])
  const [orderNumber, setOrderNumber] = useState(null)
  const [taskId, setTaskId] = useState(null)

  const cloneFn = (collection, setCollection) => {
    return source => {
      const newKey = uuid()
      const newItem = {}
      setCollection({ ...collection, [newKey]: newItem })
    }
  }

  const cloneTimeFields = cloneFn(adjustmentTimes, setAdjustmentTimes)

  const removeFn = (collection, setCollection, removedCollection, setRemovedCollection) => {
    return key => {
      const newCollection = { ...collection }
      const item = newCollection[key]
      if (item.id) {
        setRemovedCollection([...removedCollection, item.id])
      }
      delete newCollection[key]
      setCollection(newCollection)
    }
  }

  const removeTimeFields = removeFn(adjustmentTimes, setAdjustmentTimes, removedTimes, setRemovedTimes)

  return (
    <>
      <div className="form-group">
        {jobnumber && (
          <>
            <label>Ordrenummer, Sags-ID eller beskrivelse</label>
            <select className="form-control" disabled defaultValue={jobnumber?.full_title}>
              <option>{jobnumber?.full_title}</option>
            </select>
          </>
        )}
        {!jobnumber && (
          <>
            <OrderNumberPicker orderNumber={orderNumber} setOrderNumber={setOrderNumber} />
            <input type="hidden" name="resources_adjustment[jobnumber_id]" value={orderNumber?.id || ''} />
            <DependentTaskPicker
              jobnumber={orderNumber}
              taskId={taskId}
              setTaskId={id => setTaskId(id)}
              name="resources_adjustment[task_id]"
            />
          </>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="resources-record-date">Dato</label>
        <input id="resources-record-date" required disabled type="date" className="form-control col-xs-12" defaultValue={date} />
      </div>
      {times && (
        <div className="form-group">
          <label htmlFor="">Registreret tid</label>
          <table className="table">
            <tbody>
              {times.map(time => {
                const [hours, minutes] = separateTime(time.time)
                return (
                  <tr key={time.id} className="">
                    <td>
                      {hours}:{minutes > 9 ? minutes : `0${minutes}`}
                    </td>
                    <td>
                      {time.worktype.code} - {time.worktype.title}
                    </td>
                    <td>{time.comment}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {Object.keys(adjustmentTimes).map((key, index) => {
        const showRemoveTimeFieldsButton = Object.keys(adjustmentTimes).length > 1
        return (
          <TimeFields
            key={key}
            idKey={key}
            fieldNameFn={field => `resources_adjustment[times_attributes][${index}][${field}]`}
            showSignControl
            timeFields={adjustmentTimes[key]}
            setTimeFields={data => setAdjustmentTimes({ ...adjustmentTimes, [key]: data })}
            cloneTimeFields={cloneTimeFields}
            removeTimeFields={removeTimeFields}
            showRemoveTimeFieldsButton={showRemoveTimeFieldsButton}
            availableWorktypes={availableWorktypes}
          />
        )
      })}
      <RemovedFields collection={removedTimes} namePrefix="resources_adjustment[times_attributes]" />
    </>
  )
}

AdjustmentsFormFields.propTypes = {
  availableWorktypes: PropTypes.array.isRequired,
  adjustment: PropTypes.shape({
    jobnumber_id: PropTypes.object,
    workcard_ids: PropTypes.array,
    partner_ids: PropTypes.array,
    jobnumber: PropTypes.object,
    times: PropTypes.array,
    machines: PropTypes.array,
    is_master: PropTypes.bool,
  }).isRequired,
}

export default AdjustmentsFormFields
