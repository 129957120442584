import axios, { AxiosRequestConfig } from 'axios'
import { pull_api_shipments_path, api_shipments_path } from '../routes'

export function getShipments(jobnumberId) {
  const ajaxParams: AxiosRequestConfig = {
    url: api_shipments_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: { jobnumber_id: jobnumberId },
  }
  return axios(ajaxParams).then(response => response.data)
}

export function pullShipments(jobnumberId) {
  const ajaxParams: AxiosRequestConfig = {
    url: pull_api_shipments_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: { jobnumber_id: jobnumberId },
  }
  return axios(ajaxParams).then(response => response.data)
}