import uuid from 'uuid/v4'

export function timesHash(times) {
  const hash = {}
  times.forEach(t => {
    const key = t.id ? t.id : uuid()
    hash[key] = t
  })
  return hash
}

export function separateTime(timeInMinutes) {
  const minutes = Math.floor(timeInMinutes % 60) || 0
  const hours = Math.floor(timeInMinutes / 60) || 0
  return [hours, Math.abs(minutes)]
}

export function minutesToTime(minutes: number) {
  const [h, m] = separateTime(minutes)
  return `${h}:${m < 10 ? '0' + m : m}`
}
