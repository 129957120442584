import * as React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

type ScanFormFieldsProps = {
  form: any
  availableAreas: string[]
}
export function areaToOption(area: string) {
  return { label: area, value: area }
}

export default function ScanFormFields(props: ScanFormFieldsProps) {
  const { form, availableAreas } = props
  const { register, formState, watch, control, setFocus } = form
  const { errors } = formState
  const areasOptions = availableAreas.map(a => areaToOption(a))

  return (
    <div className="scan-fields col-7">
      <div className="form-group">
        <div className="row">
          <label className="col-4" htmlFor="area">
            Vælg område
          </label>
          <div className="col-8">
            <Controller name="area" control={control} render={({ field }) => <Select {...field} options={areasOptions} />} />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <label className="col-4" htmlFor="order_and_id">
            Ordre + id
          </label>
          <div className="col-8">
            <input
              type="text"
              className={`form-control${errors.order_and_id ? ' is-invalid' : ''}`}
              id="order_and_id"
              placeholder="Scan ordrenummer + id"
              {...register('order_and_id', { required: true })}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <label className="col-4" htmlFor="operator">
            Initialer
          </label>
          <div className="col-8">
            <input
              type="text"
              className={`form-control${errors.operator ? ' is-invalid' : ''}`}
              id="operator"
              placeholder="Scan initialer"
              {...register('operator', { required: true })}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <label className="col-4" htmlFor="machine">
            Maskine
          </label>
          <div className="col-8">
            <input
              type="text"
              className={`form-control${errors.machine ? ' is-invalid' : ''}`}
              id="machine"
              placeholder="Scan maskine id"
              {...register('machine', { required: true })}
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <label className="col-4" htmlFor="order_note">
            Bemærkning
          </label>
          <div className="col-8">
            <input
              type="text"
              className={`form-control${errors.order_note ? ' is-invalid' : ''}`}
              id="order_note"
              placeholder="Note til ordrestyrer (valgfrit)"
              {...register('order_note')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
