import { useContext } from 'react'
import Popup from 'reactjs-popup'
import SendMessageForm from './SendMessageForm'
import DictionariesContext from '../../DictionariesContext'
import useSendMessagesController from '../../../hooks/useSendMessagesController'

export default function SendMessageButton({ jobPlanning, buttonClass, buttonTitle = 'Send besked', onSuccessSend = () => {} }) {
  const { availableUsers } = useContext(DictionariesContext)
  const controller = useSendMessagesController(availableUsers, jobPlanning, onSuccessSend)
  const { reset } = controller.sendMessageMutation

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-secondary'}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '720px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      onClose={reset}
      modal
    >
      {close => (
        <div className="resources-record-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="popup-header">{buttonTitle}</div>
          <SendMessageForm controller={controller} jobPlanning={jobPlanning} closePopup={close}/>
        </div>
      )}
    </Popup>
  )
}