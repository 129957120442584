import * as PropTypes from 'prop-types'
import * as React from 'react'
import { useQuery } from 'react-query'
import SendMessageButton from '../job_plannings/send_message_popup_button/SendMessageButton'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'

import { getCompletePopupJobPlanningLines } from '../../api/job_planning_lines'
import BrandEasyProjectButton from './BrandEasyProjectButton'
import { format } from 'date-fns'

function JobPlanningsTable({ jobnumber }) {
  let prevDate
  const classes = ['odd', 'even']
  let currentClassIdx = 0

  const { isLoading, data, error, isError } = useQuery(['job_planning_lines_files', jobnumber.id], () =>
    getCompletePopupJobPlanningLines({ jobnumber_id: jobnumber.id })
  )

  const rowClass = currentDate => {
    if (prevDate !== currentDate) {
      prevDate = currentDate
      currentClassIdx = Math.abs(currentClassIdx - 1)
    }
    return classes[currentClassIdx]
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Nummer</th>
            <th>Slutdato</th>
            <th>Timer</th>
            <th>Placering</th>
            <th>Bemærkning</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map(row => {
              const minutes = Math.round(parseFloat(row.quantity) * 60)
              const time = `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`
              const status = row.actually_completed ? 'Færdig' : 'Planlagt'
              return (
                <tr key={row.key} className={rowClass(row.finished_date)}>
                  <td>{row.no}</td>
                  <td>{format(Date.parse(row.finished_date), 'dd-MM-yyyy')}</td>
                  <td>{time}</td>
                  <td>{row.placement}</td>
                  <td>{row.comment}</td>
                  <td>{status}</td>
                  <td>
                    <SendMessageButton jobPlanning={row} buttonClass="mb-2 btn btn-outline-secondary text-nowrap" />
                    <BrandEasyProjectButton jobPlanning={row} />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      <ErrorMessage error={error} isError={isError} className="job-plannings-table-error"/>
    </>
  )
}

JobPlanningsTable.propTypes = {
  jobnumber: PropTypes.object.isRequired,
}
export default JobPlanningsTable
