import * as React from 'react'
import { useContext } from 'react'

import DictionariesContext from '../../DictionariesContext'
import ChangeFilterButton from './ChangeFilterButton'
import filterToString from './filterToString'
import useSavedFiltersController from '../../../hooks/useSavedFiltersController'

type FilterProps = {
  filter: any
  setFilter: (filter: any) => void
}

export default function Filter({ filter, setFilter }: FilterProps) {
  const dictionaries = useContext(DictionariesContext)
  const { currentUser, updateCurrentUser, availableTasks } = dictionaries
  const savedFiltersController = useSavedFiltersController({
    filter,
    setFilter,
    currentUser,
    updateCurrentUser,
    savedFiltersKey: 'saved_filters',
    savedFilterStorageKey: 'currentSavedFilter',
  })
  const { setCurrentSavedFilter, savedFilters } = savedFiltersController
  const filterString = filterToString(filter, availableTasks)

  return (
    <div className="mb-4 card p-2">
      <div className="d-flex justify-content-between">
        <div className="job-plannings-filter-filter-string d-flex align-items-center">
          <i className="fa fa-icon fa-filter text-secondary ml-2 mr-1" />
          {filterString}
        </div>
        <div className="job-plannings-filter-actions d-print-none">
          <div className="btn-group">
            <ChangeFilterButton savedFiltersController={savedFiltersController} />
            <button
              type="button"
              className="btn btn-outline-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="caret" />
              <span className="sr-only">Toggle Dropdown</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {savedFilters &&
                savedFilters.length > 0 &&
                savedFilters
                  .sort(f => (f.default ? -1 : 1))
                  .map(savedFilter => {
                    return (
                      <a
                        key={savedFilter.id}
                        className="dropdown-item"
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setCurrentSavedFilter(savedFilter)
                          setFilter(savedFilter.filter)
                        }}
                      >
                        {savedFilter.default ? 'Nulstil til standard filter' : savedFilter.name}
                      </a>
                    )
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
