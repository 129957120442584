import { useEffect, useState } from 'react'
import Uploader from './Uploader'
import RemoveFieldsButton from '../../shared/RemoveFieldsButton'
import { formatBytes } from '../../helpers/file'

export default function AttachedFile({ file, onSuccess, onRemove }) {
  const [progress, setProgress] = useState(0)
  const [uploaded, setUploaded] = useState(false)
  const [error, setError] = useState(null)
  useEffect(() => {
    const uploader = new Uploader(file, {
      onSuccess: blob => {
        setUploaded(true)
        onSuccess({...blob, file: file})
      },
      onProgress: progress => setProgress(progress),
      onError: error => setError(error),
    })
    uploader.upload()
  }, [])
  const uploadedClass = uploaded ? 'direct-upload--complete' : error ? 'direct-upload--error' : 'direct-upload--pending'
  return (
    <div className={`direct-upload ${uploadedClass}`}>
      <div className="direct-upload__progress" style={{ width: `${progress}%` }}></div>
      <span className="direct-upload__filename">
        {file.name} ({formatBytes(file.size)})
      </span>
      {uploaded && <RemoveFieldsButton handleRemove={onRemove} className="direct-upload-remove ml-1" />}
    </div>
  )
}
