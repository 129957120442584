import React, { useState } from 'react'
import _ from 'lodash'

const FileUploader = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props
  const inputName = `${name}[]`
  const removedInputName = `${name}_destroy[]`
  const [removedImages, setRemovedImages] = useState([])
  const [images, setImages] = useState(defaultValue || [])
  return (
    <>
      <input className="mb-2" ref={ref} type="file" capture="camera" multiple name={inputName} disabled={disabled} />
      <input type="hidden" name="file_uploader_key" value={name} />
      {removedImages.map(removed => {
        return <input type="hidden" key={removed} name={removedInputName} value={removed} />
      })}
      {images &&
        images.map(file => {
          return (
            <div className="mb-1" key={file.url}>
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.is_image && <img className="mr-2" width="32" height="32" src={file.url} alt={file.filename} />}
                {!file.is_image && <i className="fa fa-2x fa-icon fa-file-alt mr-2" />}
                {file.filename}
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="#"
                className="remove ml-2 text-danger"
                onClick={e => {
                  e.preventDefault()
                  setRemovedImages([...removedImages, file.id])
                  setImages(_.filter(images, i => i.id !== file.id))
                }}
              >
                <i className="fa fa-icon fa-times" />
              </a>
            </div>
          )
        })}
    </>
  )
})

export default FileUploader
