import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import Moment from 'moment'
import { useMutation } from 'react-query'
import { createResourcesOverviewComment } from '../../api/resources'

function CommentButton({ userData, user, date }) {
  const [initialComment, setInitialComment] = useState(userData.comment)
  const [comment, setComment] = useState(initialComment)
  const mutation = useMutation(
    text =>
      createResourcesOverviewComment({ resources_overview_comment: { user_id: user.id, comment: text, date: date.format('DD-MM-YYYY') } }),
    {
      onSuccess: async () => {
        setInitialComment(comment)
      },
    }
  )
  let trigger = null
  if (mutation.isLoading) {
    trigger = <i className="fa fa-spinner fa-spin" />
  } else {
    const title = initialComment || '+ Add a new comment'
    const colorClass = initialComment ? ' has-comment' : ''
    trigger = <i role="button" title={title} className={`resources-overview-comment-button fa fa-icon fa-comment${colorClass}`} />
  }
  return (
    <Popup trigger={trigger} position="bottom center" closeOnDocumentClick>
      {close => (
        <div className="popup resources-overview-comment-popup">
          <textarea
            autoFocus
            className="input-field form-control"
            value={comment}
            onChange={e => {
              setComment(e.target.value)
            }}
          />
          <div className="actions">
            <button type="button" className="btn btn-outline-secondary" onClick={close}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                close()
                mutation.mutate(comment)
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

CommentButton.propTypes = {
  user: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  date: PropTypes.instanceOf(Moment).isRequired,
}

export default CommentButton
