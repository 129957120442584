import * as React from 'react'
import { areaToOption } from './ScanFormFields'

type RelatedAreasProps = {
  areas: string[]
  availableAreas: string[]
  form: any
}

export default function RelatedAreas(props: RelatedAreasProps) {
  const { areas, form, availableAreas } = props

  const setArea = (area: string) => {
    form.setValue('area', areaToOption(area))
  }

  const isAvailable = (area: string) => availableAreas.includes(area)

  return (
    <div className="scan-form-related-areas">
      {areas &&
        areas.map(area => (
          <button key={area} disabled={!isAvailable(area)} type="button" className="btn btn-light mr-2" onClick={() => setArea(area)}>
            {area}
          </button>
        ))}
    </div>
  )
}
