import React, { useState } from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'
import CompleteButton from './CompleteButton'
import PlacementField from './PlacementField'

function TableView({data, reload, setApiError}) {
  let prevDate
  const classes = ['odd', 'even']
  let currentClassIdx = 0

  const rowClass = currentDate => {
    if (prevDate !== currentDate) {
      prevDate = currentDate
      currentClassIdx = Math.abs(currentClassIdx - 1)
    }
    return classes[currentClassIdx]
  }
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Sagsopgnr.</th>
          <th>Nummer</th>
          <th>Kunde</th>
          <th>Stadiebeskrivelse</th>
          <th>Slutdato</th>
          <th>Timer</th>
          <th>Placering</th>
          <th>Bemærkning</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map(row => {
            const minutes = Math.round(parseFloat(row.quantity) * 60)
            const time = `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`
            return (
              <tr key={row.key} className={rowClass(row.finished_date)}>
                <td>{row.job_task_no}</td>
                <td>{row.no}</td>
                <td>{row.jobnumber && row.jobnumber.customer_title}</td>
                <td>{row.description}</td>
                <td>{moment(row.finished_date).format('DD-MM-YYYY')}</td>
                <td>{time}</td>
                <td>
                  <PlacementField jobPlanningKey={row.key} placement={row.placement} reload={reload} />
                </td>
                <td>{row.comment}</td>
                <td>
                  <CompleteButton  jobPlanning={row} reload={reload} setApiError={setApiError}/>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default TableView
