import { useForm } from 'react-hook-form'
import { UserShape } from '../../api/api_data_types'
import { CurrentUserType } from '../DictionariesContext'
import TravelFormFields, { userToOption } from './TravelFormFields'
import { Travel } from './travel_list'
import { useCreateTravelMutation, useUpdateTravelMutation } from '../../hooks/queries/travels'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import DeleteTravelButton from "./DeleteTravelButton";

function getDefaultValues(travel: Travel | undefined, currentUser: CurrentUserType) {
  let defaults = {
    driver: userToOption(currentUser),
    direction: 'east_to_west',
    route_option: 'bridge',
    seat_capacity: 5,
  }

  if (travel === undefined) return defaults

  const driver = travel.driver
  const passengers = travel.passengers.map(p => userToOption(p))
  return {
    ...travel,
    passengers,
    driver: userToOption(driver),
  }
}

function dataToPayload(data) {
  const { driver, passengers, ...rest } = data
  return {
    travel: {
      ...rest,
      driver_id: driver.value,
      passenger_ids: passengers ? passengers.map(p => p.value) : [],
    },
  }
}

type TravelFormProps = {
  travel?: Travel
  availableUsers: UserShape[]
  currentUser: CurrentUserType
  close: () => void
}
export default function TravelForm({ travel, availableUsers, currentUser, close }: TravelFormProps) {
  const defaultValues = getDefaultValues(travel, currentUser)
  const form = useForm({ defaultValues })
  const { handleSubmit } = form

  const mutation = travel?.id ? useUpdateTravelMutation(travel.id) : useCreateTravelMutation()
  const onSubmit = data => {
    const payload = dataToPayload(data)
    mutation.mutate(payload, { onSuccess: close })
  }

  const canDelete = travel && travel.id && (travel.user.id === currentUser.id || travel.driver.id === currentUser.id)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="popup-content">
        <TravelFormFields form={form} availableUsers={availableUsers} currentUser={currentUser} />
      </div>
      <div className="popup-actions">
        <ErrorMessage isError={mutation.isError} error={mutation.error} />
        <button type="button" className="btn btn-outline-secondary" onClick={close}>
          Luk
        </button>
        {canDelete && (
          <DeleteTravelButton travel={travel} />
        )}
        {!mutation.isError && (
          <button type="submit" className="btn btn-primary" disabled={mutation.isLoading}>
            {mutation.isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Gem'}
          </button>
        )}
      </div>
    </form>
  )
}
