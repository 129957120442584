import * as React from 'react'
import Popup from 'reactjs-popup'
import ResourceSelect from './ResourceSelect'
import TaskSelect from './TaskSelect'
import OrderSelect from './OrderSelect'
import PlaceOfProductionSelect from './PlaceOfProductionSelect'
import DateSelect from './DateSelect'
import SavedFilters from './SavedFilters'
import { SavedFiltersControllerType } from '../../../hooks/useSavedFiltersController'

type ChangeFilterButtonProps = {
  savedFiltersController: SavedFiltersControllerType
}

export default function ChangeFilterButton({ savedFiltersController }: ChangeFilterButtonProps) {
  const { filter, setFilter, formFilter, setFormFilter } = savedFiltersController

  const startDate = formFilter.start_date || ''
  const endDate = formFilter.end_date || ''

  const closeWindow = closeModal => {
    closeModal()
    setFormFilter(filter)
  }

  return (
    <>
      <Popup
        trigger={
          <button type="button" className="btn btn-outline-secondary text-nowrap">
            Skift filter
          </button>
        }
        position="center center"
        contentStyle={{ width: 'auto', maxWidth: '992px' }}
        modal
        closeOnDocumentClick={false}
      >
        {closeModal => (
          <div className="job-planning-filter-modal modal-popup">
            <a className="close" onClick={() => closeWindow(closeModal)}>
              &times;
            </a>
            <div className="job-planning-filter-modal-header popup-header">Skift filter</div>
            <div className="job-planning-filter-modal-content popup-content">
              <ResourceSelect filter={formFilter} setFilter={setFormFilter} />
              <TaskSelect filter={formFilter} setFilter={setFormFilter} />
              <OrderSelect filter={formFilter} setFilter={setFormFilter} />
              <div className="row">
                <PlaceOfProductionSelect filter={formFilter} setFilter={setFormFilter} />
                <DateSelect
                  id="job-plannings-filter-start-date"
                  label="Dato fra"
                  value={startDate}
                  onChange={e => setFormFilter({ ...formFilter, start_date: e.target.value })}
                />
                <DateSelect
                  id="job-plannings-filter-end-date"
                  label="Dato til"
                  value={endDate}
                  onChange={e => setFormFilter({ ...formFilter, end_date: e.target.value })}
                />
              </div>
              <hr />
              <SavedFilters savedFiltersController={savedFiltersController} closeModal={closeModal} />
            </div>
            <div className="job-planning-filter-modal-actions popup-actions">
              <button type="button" className="btn btn-outline-secondary" onClick={() => closeWindow(closeModal)}>
                Luk
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={e => {
                  setFilter(formFilter)
                  closeModal()
                }}
              >
                Apply filter
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  )
}
