import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { useMutation, useQueryClient } from 'react-query'
import ApprovalFormsTable from './ApprovalFormsTable'
import ApprovalFormsAttachFormButton from './ApprovalFormsAttachFormButton'
import { createApprovalFormsAnswer } from '../../api/approval_forms_answers'

function ApprovalFormsButton(props) {
  const { jobnumber, availableApprovalFormTemplates, buttonClass } = props

  const queryClient = useQueryClient()
  const reloadAnswers = () => {
    queryClient.invalidateQueries('approval_forms_table')
  }

  const mutation = useMutation(
    answer => {
      createApprovalFormsAnswer({ approval_forms_answer: answer })
    },
    {
      onSuccess: async () => {
        reloadAnswers()
      },
    }
  )

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-secondary'}>
          Godkendelse...
        </button>
      }
      position="center center"
      contentStyle={{ width: 'auto', maxWidth: '720px' }}
      modal
      nested
    >
      {close => (
        <div className="complete-button-modal modal-popup">
          <a className="close btn" onClick={close}>
            &times;
          </a>
          <div className="complete-button-modal-header popup-header">Godkendelse for {jobnumber.full_title}</div>
          <div className="complete-button-modal-content popup-content">
            <ApprovalFormsTable jobnumber={jobnumber} />
          </div>
          <div className="complete-button-modal-actions popup-actions">
            <ApprovalFormsAttachFormButton
              isLoading={mutation.isLoading}
              availableApprovalFormTemplates={availableApprovalFormTemplates}
              attachForm={mutation.mutate}
              jobnumber={jobnumber}
            />
            <button type="button" className="btn btn-outline-secondary" onClick={close}>
              Luk
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

ApprovalFormsButton.propTypes = {
  jobnumber: PropTypes.object,
  availableApprovalFormTemplates: PropTypes.array,
  buttonClass: PropTypes.string,
}

export default ApprovalFormsButton
