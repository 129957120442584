import { useQuery } from 'react-query'
import { getProductionPlanningOverview } from '../../api/production_planning'
import { useState } from 'react'

export function useProductionPlanningOverviewQuery(filter, showCompletedToday) {
  const [refetchInterval, setRefetchInterval] = useState<number|false>(3000)
  const key = ['production_planning_overview', filter, showCompletedToday]
  const fetcher = () => getProductionPlanningOverview({ filter, show_completed_today: showCompletedToday })

  const query = useQuery(key, fetcher, {
    refetchInterval,
    onSuccess: data => {
      if (data.status !== 'in_progress') {
        setRefetchInterval(false)
      }
    },
    onError: () => {
      setRefetchInterval(false)
    }
  })

  const data = query.data?.status === 'in_progress' ? null : query.data
  const isFetching = query.isFetching || !data

  return { ...query, data, isFetching }
}
