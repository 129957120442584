import React from 'react'
import Actions from './Actions'
import SortingIndicator from '../../shared/SortingIndicator'

export default function TableHead({ sorting, setSorting }) {
  const columns = {
    job_id: 'SagsID',
    order_no: 'Ordernr',
    job_task_no: 'Opgnr.',
    no: 'Nr',
    status_code: 'Status',
    place_of_production: 'ØST/VEST',
    customer_title: 'Kunde',
    address: '',
    description: 'Beskrivelse',
    finished_date: 'Slutdato',
    quantity: 'Timer',
    placement: 'Placering',
    comment: 'Bemærkning',
  }

  const changeSorting = key => {
    let dir = 'asc'
    let col = sorting.column

    if (sorting.column === key) {
      dir = sorting.direction === 'asc' ? 'desc' : 'asc'
    } else {
      col = key
    }
    setSorting({ column: col, direction: dir })
  }

  return (
    <thead className="">
      <tr>
        {Object.keys(columns).map(key => {
          return (
            <th className="job-plannings-table-sticky-header" key={key}>
              <div className="job-plannings-table-sorting-label" onClick={() => changeSorting(key)}>
                {columns[key]}
                <SortingIndicator className="ml-1" active={sorting.column === key} direction={sorting.direction} />
              </div>
            </th>
          )
        })}
        <th className="d-print-none job-plannings-table-sticky-header">Actions</th>
      </tr>
    </thead>
  )
}
