import TravelListRow from './TravelListRow'

type TravelListTableProps = {
  data: any[]
  isLoading: boolean
}
export default function TravelListTable({ data, isLoading }: TravelListTableProps) {

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Dato</th>
          <th scope="col">Pickup tidspunkt</th>
          <th scope="col">Pickup sted</th>
          <th scope="col">Rute</th>
          <th scope="col">Færge</th>
          <th scope="col">Retning</th>
          <th scope="col">Arrangør</th>
          <th scope="col">Pladser</th>
          <th scope="col">Medrejsende</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading && (
          <tr className="text-center">
            <td colSpan={10}>
              <i className="fa fa-spinner fa-spin fa-2x" />
            </td>
          </tr>
        )}
        {data && data.map(travel => <TravelListRow key={travel.id} travel={travel} />)}
      </tbody>
    </table>
  )
}
