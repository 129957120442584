import * as React from 'react'
import { useContext } from 'react'
import Filter from '../filter/Filter'
import DictionariesContext from '../../DictionariesContext'
import JobPlanningsTableContent from './JobPlanningsTableContent'
import useJobPlanningsController from '../../../hooks/useJobPlanningsController'
import ErrorMessage from './ErrorMessage'
import JobPlanningTableHeadActions from './JobPlanningTableHeadActions'
import useSavedFiltersController from '../../../hooks/useSavedFiltersController'

function JobPlanningsTable() {
  const { currentUser, updateCurrentUser } = useContext(DictionariesContext)
  const {
    jobPlannings,
    filter,
    setFilter,
    showCompletedToday,
    setShowCompletedToday,
    currentView,
    setCurrentView,
    sorting,
    setSorting,
  } = useJobPlanningsController(currentUser)


  return (
    <div>
      <Filter filter={filter} setFilter={setFilter} />
      <ErrorMessage isError={jobPlannings.isError} error={jobPlannings.error} />
      <JobPlanningTableHeadActions
        isError={jobPlannings.isError}
        currentUser={currentUser}
        currentView={currentView}
        setCurrentView={setCurrentView}
        showCompletedToday={showCompletedToday}
        setShowCompletedToday={setShowCompletedToday}
      />
      <JobPlanningsTableContent
        jobPlannings={jobPlannings}
        currentUser={currentUser}
        currentView={currentView}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  )
}

export default JobPlanningsTable
