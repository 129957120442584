import React from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

function DatesTableHeaderRows({ range, firstColumnColspan = 1, colSpanMultiplier = 1, isHolidayOrWeekend = _ => false }) {
  const monthNames = [
    'Januar',
    'Februar',
    'Marts',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'December',
  ]
  const weekdays = ['S', 'M', 'T', 'O', 'T', 'F', 'L']

  const weeks = _.groupBy(range, date => [date.year(), date.isoWeek()])
  const months = _.groupBy(range, date => [date.month(), date.year()])

  const cellClasses = date => {
    const cssClasses = []
    if (isHolidayOrWeekend(date)) {
      cssClasses.push('resources-overview-holiday')
    }
    if (date.date() === 16) {
      cssClasses.push('resources-overview-middle')
    }
    if (date.date() === 1) {
      cssClasses.push('resources-overview-beginning')
    }

    return cssClasses
  }

  return (
    <>
      <tr className="table-header-row">
        <th className="table-header-free-space" colSpan={firstColumnColspan}>
          &nbsp;
        </th>
        {_.map(months, (days, monthYear) => {
          const [month, year] = monthYear.split(',')
          return <th colSpan={days.length * colSpanMultiplier} key={monthYear}>{`${monthNames[parseInt(month, 10)]}, ${year}`}</th>
        })}
      </tr>
      <tr className="table-header-row resources-overview-header-week-numbers">
        <th className="table-header-free-space" colSpan={firstColumnColspan}>
          &nbsp;
        </th>
        {_.map(weeks, (days, yearWeekNumber) => {
          const [year, weekNumber] = yearWeekNumber.split(',')
          return <th colSpan={days.length * colSpanMultiplier} key={weekNumber}>{`Uge ${weekNumber}`}</th>
        })}
      </tr>
      <tr className="table-header-row resources-overview-header-dates">
        <th className="table-header-free-space" colSpan={firstColumnColspan}>
          &nbsp;
        </th>
        {range.map(date => {
          const formatted = date.format('DD')
          return (
            <th colSpan={colSpanMultiplier} key={date} className={cellClasses(date).join(' ')}>
              {formatted}
            </th>
          )
        })}
      </tr>
      <tr className="table-header-row">
        <th className="table-header-free-space" colSpan={firstColumnColspan}>
          &nbsp;
        </th>
        {range.map(date => {
          return (
            <th colSpan={colSpanMultiplier} key={date} className={cellClasses(date).join(' ')}>
              {weekdays[date.weekday()]}
            </th>
          )
        })}
      </tr>
    </>
  )
}

DatesTableHeaderRows.propTypes = {
  range: PropTypes.array.isRequired,
  firstColumnColspan: PropTypes.number,
  isHolidayOrWeekend: PropTypes.func,
}

export default DatesTableHeaderRows
