import * as React from 'react'

type FeedbackTypeSelectProps = {
  setFeedbackType: (feedbackType: string) => void
}

export const FEEDBACK_TYPES = {
  general: 'Generel feedback',
  idea: 'Jeg har en idé',
  bug: 'Jeg har fundet en fejl',
}
export const FEEDBACK_ICONS = {
  general: '😁',
  idea: '💡',
  bug: '🐞',
}

export default function FeedbackTypeSelect(props: FeedbackTypeSelectProps) {
  const { setFeedbackType } = props
  return (
    <div className="feedback-content">
      <p>Hvad er din feedback?</p>
      <div className="feedback-content-list">
        {Object.keys(FEEDBACK_TYPES).map(key => (
          <button key={key} id={`feedback-item-${key}`} className="feedback-item" onClick={() => setFeedbackType(key)}>
            <span className="mr-1">{FEEDBACK_ICONS[key]}</span>
            {FEEDBACK_TYPES[key]}
          </button>
        ))}
      </div>
    </div>
  )
}
