import Uploader from './Uploader'
import { useCallback, useEffect, useRef, useState } from 'react'
import AttachedFile from './AttachedFile'
import Logo from 'react-query/types/devtools/Logo'
import { useDropzone } from 'react-dropzone'

interface UploadInputProps {
  onChange: (files: any[]) => void
  setAllFilesUploaded: (allFilesUploaded: boolean) => void
  // setUploadedFiles: (files: any[]) => void
}

export default function DirectUploadInput(props: UploadInputProps) {
  const { onChange, setAllFilesUploaded } = props
  const [attachedFiles, setAttachedFiles] = useState<any[]>([])
  const uploadedFilesRef = useRef<any[]>([])
  const attachedFilesCountRef = useRef<number>(0)

  const onUploadSuccess = (blob: any) => {
    uploadedFilesRef.current.push(blob)
    if (attachedFilesCountRef.current === uploadedFilesRef.current.length) {
      setAllFilesUploaded(true)
    }
    onChange(uploadedFilesRef.current)
  }
  const onDrop = useCallback(
    acceptedFiles => {
      const uniqueFiles = acceptedFiles.filter(file => !attachedFiles.find(f => f.name === file.name))

      attachedFilesCountRef.current += uniqueFiles.length
      if (uniqueFiles.length === 0) {
        return
      }
      setAllFilesUploaded(false)
      setAttachedFiles([...attachedFiles, ...uniqueFiles])
    },
    [attachedFiles]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const removeAttachedFile = (file: File) => {
    uploadedFilesRef.current = uploadedFilesRef.current.filter(f => f.file !== file)
    attachedFilesCountRef.current -= 1
    setAllFilesUploaded(attachedFilesCountRef.current === uploadedFilesRef.current.length)

    const index = attachedFiles.findIndex(f => f === file)
    const newAttachedFiles = [...attachedFiles]
    newAttachedFiles.splice(index, 1)
    setAttachedFiles(newAttachedFiles)

    onChange(uploadedFilesRef.current)
  }

  return (
    <>
      <div className="direct-upload-dropzone p-3 bg-light mx-auto text-center" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <span>Slip filerne her ...</span>
        ) : (
          <span>Træk og slip nogle filer her, eller klik for at vælge filer fra din enhed ...</span>
        )}
      </div>
      <div>
        {attachedFiles.map((file, index) => (
          <AttachedFile key={file.name} file={file} onSuccess={onUploadSuccess} onRemove={() => removeAttachedFile(file)} />
        ))}
      </div>
    </>
  )
}
