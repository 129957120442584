import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactFormGenerator } from 'react-form-builder2'
import { registerCustomItems } from '../admin/approval_form_templates/ApprovalFormInputs'

function ApprovalFormAnswer({ formDefinition, submitUrl, authenticityToken, answerData }) {
  useEffect(() => {
    // Trick to avoid ink offset on Signature elements on retina screens
    const { $ } = window
    const signaturePad = $('.react-form-builder-form').find('canvas')
    const signatureElement = signaturePad.get(0)
    if (signatureElement) {
      const initialWidth = signatureElement.width
      signatureElement.width = 200
      signatureElement.width = initialWidth
    }
  })

  registerCustomItems()

  return (
    <div className="react-form-builder-2">
      <ReactFormGenerator
        data={formDefinition}
        form_action={submitUrl}
        form_method="POST"
        authenticity_token={authenticityToken}
        answer_data={answerData}
        submitButton={
          <button type="submit" className="btn btn-primary ml-auto mt-4 mb-4">
            Submit
          </button>
        }
      />
    </div>
  )
}

ApprovalFormAnswer.propTypes = {
  formDefinition: PropTypes.array.isRequired,
  submitUrl: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  formData: PropTypes.string.isRequired,
}

export default ApprovalFormAnswer
