import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { useStorageState } from 'react-storage-hooks'
import Table from './Table'
import Filter from './Filter'
import DictionariesContext from '../DictionariesContext'
import { getResourcesOverview } from '../../api/resources'

function ResourcesOverview({ startDate, endDate }) {
  const dictionaries = useContext(DictionariesContext)
  const { currentUser } = dictionaries

  const [filter, setFilter] = useStorageState(sessionStorage, 'filter', { no: [currentUser.username], department_ids: [] })
  const [showWeekends, setShowWeekends] = useStorageState(sessionStorage, 'showWeekends', true)
  const [showNormTime, setShowNormTime] = useStorageState(sessionStorage, 'showNormTime', false)
  const { isLoading, isError, data, error } = useQuery(['resources_overview', startDate, endDate, filter], () =>
    getResourcesOverview({ date: startDate, filter })
  )

  return (
    <>
      <Filter
        initialFilter={filter}
        onSubmit={newFilter => {
          setFilter(newFilter)
        }}
      />
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          {!isLoading && !isError && (
            <div className="form-check form-check-valigned">
              <label>
                <input type="checkbox" checked={showWeekends} onChange={e => setShowWeekends(e.target.checked)} />
                Weekend og helligdage
              </label>
              <label>
                <input className="ml-4" type="checkbox" checked={showNormTime} onChange={e => setShowNormTime(e.target.checked)} />
                Show only Norm time
              </label>
            </div>
          )}
        </div>
      </div>
      {!isLoading && !isError && (
        <Table startDate={startDate} endDate={endDate} data={data} showWeekends={showWeekends} showNormTime={showNormTime} />
      )}
      {isError && <div className="job-plannings-table-error">{error.message}</div>}
    </>
  )
}

ResourcesOverview.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
}

export default ResourcesOverview
