import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import DictionariesContext from '../../DictionariesContext'
import RemoveFieldsButton from '../../shared/RemoveFieldsButton'

function DuplicateFormFieldsRow({ jobPlanning, tasks, removeRow, fieldName, showRemoveFieldsButton }) {
  const dictionaries = useContext(DictionariesContext)
  const { availableUsers } = dictionaries
  const { jobnumber } = jobPlanning
  const users = availableUsers.map(u => {
    return { label: `${u.username} - ${u.name}`, value: u.id }
  })
  const [userId, setUserId] = useState(jobPlanning.user_id)
  const [taskId, setTaskId] = useState(jobPlanning.task_id)

  return (
    <tr>
      <td>
        <input type="hidden" name={fieldName('jobnumber_id')} value={jobnumber.id} />
        <select
          name={fieldName('task_id')}
          className="form-control resources-time-task"
          value={taskId}
          onChange={e => setTaskId(e.target.value)}
          required
        >
          {tasks.map(task => {
            return (
              <option key={task.id} value={task.id}>
                {`${task.number} - ${task.description}`}
              </option>
            )
          })}
        </select>
      </td>
      <td>
        <input
          required
          type="date"
          className="form-control col-xs-12"
          defaultValue={jobPlanning.finished_date}
          name={fieldName('finished_date')}
        />
      </td>
      <td>
        <Select
          placeholder="Vælg eller skriv..."
          menuPosition="fixed"
          required
          options={users}
          value={users.find(u => u.value === userId)}
          onChange={option => {
            setUserId(option.value)
          }}
        />
        <input type="hidden" name={`${fieldName('user_ids][')}`} value={userId} />
      </td>
      <td>
        <input
          required
          type="number"
          step="0.25"
          placeholder="0.0"
          max="24"
          min="0"
          className="form-control col-xs-12"
          defaultValue={jobPlanning.quantity}
          name={fieldName('quantity')}
        />
      </td>
      <td>
        <input type="text" className="form-control col-xs-12" defaultValue={jobPlanning.comment} name={fieldName('comment')} />
      </td>
      <td>
        <input
          type="text"
          maxLength="30"
          className="form-control col-xs-12"
          defaultValue={jobPlanning.placement}
          name={fieldName('placement')}
        />
      </td>
      <td className="text-center">{showRemoveFieldsButton && <RemoveFieldsButton handleRemove={removeRow} />}</td>
    </tr>
  )
}

DuplicateFormFieldsRow.propTypes = {
  removeRow: PropTypes.func.isRequired,
  jobPlanning: PropTypes.shape({
    id: PropTypes.string,
    jobnumber_id: PropTypes.string,
    jobnumber: PropTypes.object,
    quantity: PropTypes.string,
    comment: PropTypes.string,
    actually_completed: PropTypes.string,
    description: PropTypes.string,
    finished_date: PropTypes.string,
    placement: PropTypes.string,
  }).isRequired,
}

export default DuplicateFormFieldsRow
