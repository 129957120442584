import * as React from 'react'

type RelatedIdsProps = {
  data: any
  form: any
  scanId: string
}

function RelatedIdItem(props: any) {
  const { id, name, form, mainId, selectedIds } = props
  const { register } = form
  const value = id.toString()

  const disabled = mainId === value
  const checked = disabled || selectedIds.includes(value) // if it's the main id, it's disabled

  const title = `${id} - ${name}`
  return (
    <label className={`scan-form-related-ids-button btn btn-outline-secondary mr-2 mb-2${checked ? ' active' : ' '}`}>
      <input {...register('related_ids')} type="checkbox" value={value} disabled={disabled} /> {title}
    </label>
  )
}

export default function RelatedIds(props: RelatedIdsProps) {
  const { data, form, scanId } = props

  const solutions = data.reduce((acc: any, item: any) => {
    acc[item["LøsningsID"]] = item["Løsningsnavn"]
    return acc
  }, {})

  const relatedIds = form.watch('related_ids')
  const selectAll = relatedIds.length >= Object.keys(solutions).length - (scanId ? 1 : 0)
  const toggleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // all except the main id
      // const newRelatedIds = solutions.map((item: any) => item["LøsningsID"].toString()).filter((id: string) => id !== scanId)
      const newRelatedIds = Object.keys(solutions).filter((id: string) => id !== scanId)
      form.setValue('related_ids', newRelatedIds)
    } else {
      form.setValue('related_ids', [])
    }
  }

  return (
    <div className="scan-form-related-ids">
      {solutions && Object.keys(solutions).length >= 1 && (
        <>
          <h6 className="mb-3">Relaterede id'er:</h6>
          <div className="btn-group-toggle d-flex flex-wrap">
            {Object.entries(solutions).map(([solutionId, solutionName]) => {
              return <RelatedIdItem key={solutionId} id={solutionId} name={solutionName} form={form} mainId={scanId} selectedIds={relatedIds} />
            })}
          </div>
          <div>
            <label className="select-all mr-2">
              <input type="checkbox" onChange={toggleSelectAll} checked={selectAll} /> Vælg alle
            </label>
          </div>
        </>
      )}
    </div>
  )
}
