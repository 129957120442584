import React, { useState, useContext } from 'react'
import TableRow from './TableRow'
import { dateSummary } from './helpers'
import TableHead from "./TableHead";

const DEFAULT_COLSPAN = 14

function TableView({ isLoading, jobPlanningLines, sorting, setSorting }) {
  return (
    <div className="position-relative job-plannings-table-wrapper">
      <table className="table table-print-sm table-striped job_plannings job-plannings-table">
        <TableHead sorting={sorting} setSorting={setSorting} />
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={DEFAULT_COLSPAN}>
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin fa-2x" />
                </div>
              </td>
            </tr>
          )}
          {!isLoading &&
            Object.keys(jobPlanningLines).map(date => {
              return <JobPlanningsGroupRows key={date} date={date} jobPlannings={jobPlanningLines[date]} />
            })}
        </tbody>
      </table>
    </div>
  )
}

function JobPlanningsGroupRows({ date, jobPlannings }) {
  return (
    <>
      <tr className="job-planning-date-row">
        <td className="job-planning-date-col" colSpan={DEFAULT_COLSPAN}>
          {dateSummary(date, jobPlannings)}
        </td>
      </tr>
      {jobPlannings.map(jobPlanning => {
        return <TableRow key={jobPlanning.key} jobPlanning={jobPlanning} className="" />
      })}
    </>
  )
}

export default TableView
