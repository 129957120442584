import axios, { AxiosRequestConfig } from 'axios'
import { api_travel_path, api_travels_path, join_api_travel_path, leave_api_travel_path } from '../routes'
import { FilterShape } from '../components/travel_list_app/travel_list'
import { format } from 'date-fns'

export function getTravels(filter: FilterShape) {
  const { startDate, endDate } = filter
  let params = {}
  if (startDate) {
    params = { ...params, start_date: startDate }
  }
  if (endDate) {
    params = { ...params, end_date: endDate }
  }
  if (filter.direction === 'east_to_west' || filter.direction === 'west_to_east') {
    params = { ...params, direction: filter.direction }
  }
  const options: AxiosRequestConfig = {
    url: api_travels_path({ format: 'json', ...params }),
    method: 'get',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}

export function createTravel(payload) {
  const options: AxiosRequestConfig = {
    url: api_travels_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function updateTravel(id, payload) {
  const options: AxiosRequestConfig = {
    url: api_travel_path(id, { format: 'json' }),
    method: 'put',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function joinTravel(id) {
  const options: AxiosRequestConfig = {
    url: join_api_travel_path(id, { format: 'json' }),
    method: 'post',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}

export function leaveTravel(id) {
  const options: AxiosRequestConfig = {
    url: leave_api_travel_path(id, { format: 'json' }),
    method: 'post',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}

export function destroyTravel(id) {
  const options: AxiosRequestConfig = {
    url: api_travel_path(id, { format: 'json' }),
    method: 'delete',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}
