import axios, { AxiosRequestConfig } from 'axios'
import {
  overview_api_production_planning_index_path
} from '../routes'

export function getProductionPlanningOverview(payload = {}) {
  const options: AxiosRequestConfig = {
    url: overview_api_production_planning_index_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}