import React from 'react'
import {getApiErrorMessage} from "../../helpers/error"

export default function ErrorMessage({ isError, error, className }) {
  if (!isError) {
    return null
  }

  return <div className={className || "error-message"}>{getApiErrorMessage(error)}</div>
}
