import axios, { AxiosRequestConfig } from 'axios'
import { api_scan_records_path, related_api_scan_records_path } from '../routes'

export function createScanRecord(payload) {
  const options: AxiosRequestConfig = {
    url: api_scan_records_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function getScanInfo(orderNumber: string, area: string) {
  const options: AxiosRequestConfig = {
    url: related_api_scan_records_path({ order_number: orderNumber, area: area }),
    method: 'get',
    responseType: 'json',
  }
  return axios(options)
}
