import * as React from 'react'

type TableHeadProps = {
  hideItems: boolean
  showCompletedToday: boolean
}

export default function TableHead(props: TableHeadProps) {
  const { hideItems, showCompletedToday } = props
  const columns = {
    job_id: 'SagsID',
    order_number: 'Ordrenr.',
    customer_title: 'Kunde',
    job_title: 'Jobnavn',
    solution_id: 'Løsn. ID',
    solution_name: 'Løsn. navn',
    machine_time: <span className="effection-icon effection-icon-printer"></span>,
    staffing: <span className="effection-icon effection-icon-user"></span>,
    spak: 'SPAK',
    initials: 'Initialer',
    machine: 'Maskine',
    process_areas: 'Procesområder',
    item_1: 'Vare 1',
    item_2: 'Vare 2',
  }
  if (hideItems) {
    delete columns.item_1
    delete columns.item_2
  }
  if (!showCompletedToday) {
    delete columns.initials
    delete columns.machine
  }

  return (
    <thead className="job-plannings-table-sticky-header">
      <tr>
        {Object.keys(columns).map(key => {
          return (
            <th key={key} className="text-nowrap">
              {columns[key]}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
