import React from 'react'
import PropTypes from 'prop-types'

function WorkingDayStatusIcon({ status }) {
  const statusesMap = {
    0: 'icon-office',
    1: 'fa fa-home',
    2: 'fa fa-road',
    3: 'fa fa-globe',
  }
  const iconClass = statusesMap[status]

  return <span className={`weeklist-working-day-status ${iconClass}`} />
}

WorkingDayStatusIcon.propTypes = {
  status: PropTypes.number.isRequired,
}

export default WorkingDayStatusIcon
