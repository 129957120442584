import { FilterShape } from './travel_list'

type TravelListFilterProps = {
  filter: FilterShape
  setFilter: (filter: FilterShape) => void
}
export default function TravelListFilter({ filter, setFilter }: TravelListFilterProps) {
  return (
    <div className="d-flex flex-wrap">
      <div className="form-group d-flex mr-3">
        <label className="text-nowrap pt-2 mr-2">Dato fra: </label>
        <input
          type="date"
          className="form-control"
          value={filter.startDate || ''}
          onChange={e => setFilter({ ...filter, startDate: e.target.value })}
        />
      </div>
      <div className="form-group d-flex mr-3">
        <label className="text-nowrap pt-2 mr-2">Dato til: </label>
        <input
          type="date"
          className="form-control"
          value={filter.endDate || ''}
          onChange={e => setFilter({ ...filter, endDate: e.target.value })}
        />
      </div>
      <div className="form-group d-flex">
        <label className="text-nowrap pt-2 mr-2">Retning: </label>
        <select className="form-control" value={filter.direction || 'all'} onChange={e => setFilter({ ...filter, direction: e.target.value as any})}>
          <option value="all">Alle</option>
          <option value="east_to_west">{'ØST->VEST'}</option>
          <option value="west_to_east">{'VEST->ØST'}</option>
        </select>
      </div>
    </div>
  )
}
