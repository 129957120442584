import React, { useState } from 'react'
import Select from 'react-select'
import _ from 'lodash'

function FormFieldUsers({ isNewRecord, currentUser, availableUsers, availableDepartments, defaultUserId, fieldName, fieldId }) {
  const [userIds, setUserIds] = useState([defaultUserId])
  const users = availableUsers.map(u => {
    return { label: `${u.username} - ${u.name}`, value: u.id }
  })
  const myDepartments = _.filter(availableDepartments, department => {
    if (department.length === 0 || department.managers.length === 0) return false
    const managerIds = department.managers.map(m => m.id)
    return managerIds.indexOf(currentUser.id) !== -1
  })
  const addAllUsersFromDepartment = e => {
    e.preventDefault()
    const myDepartmentsUsers = _.flatten(myDepartments.map(d => d.users))
    const ids = myDepartmentsUsers.map(u => u.id)
    setUserIds(ids)
  }
  const addDepartmentUsers = department => {
    const ids = [...new Set(department.users.map(u => u.id).concat(userIds))]
    setUserIds(ids)
  }
  const removeDepartmentUsers = department => {
    const departmentUserIds = department.users.map(u => u.id)
    const ids = userIds.filter(id => !departmentUserIds.includes(id))
    setUserIds(ids)
  }
  return (
    <div className="row">
      <div className="col-sm-12 form-group">
        <label htmlFor={fieldId('user_ids')}>Resource</label>
        {currentUser.is_department_manager && isNewRecord && (
          <div className="text-right mb-2">
            <span>Add/remove users from departments:</span>
            {myDepartments.map(d => {
              const active = _.every(d.users, u => userIds.includes(u.id))
              return (
                <a
                  key={d.id}
                  href="#"
                  className={`ml-2 badge ${active ? 'badge-info' : 'badge-light'}`}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    active ? removeDepartmentUsers(d) : addDepartmentUsers(d)
                  }}
                >
                  {d.title}
                </a>
              )
            })}
          </div>
        )}
        <Select
          id={fieldId('user_ids')}
          isMulti={isNewRecord && currentUser.is_department_manager}
          placeholder="Vælg eller skriv..."
          required
          closeMenuOnSelect={!(isNewRecord && currentUser.is_department_manager)}
          options={users}
          value={userIds.map(userId => users.find(u => u.value === userId))}
          onChange={opts => {
            if (Array.isArray(opts)) {
              const ids = opts === null ? [] : opts.map(o => o.value)
              setUserIds(ids)
            } else {
              setUserIds(opts ? [opts.value] : [])
            }
          }}
        />
        {userIds.map(id => (
          <input type="hidden" name={`${fieldName('user_ids')}[]`} value={id} key={id} />
        ))}
      </div>
    </div>
  )
}

export default FormFieldUsers
