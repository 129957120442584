import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getCompletePopupJobPlanningLines } from '../../../api/job_planning_lines'

function DuplicateFormExistingJobPlannings({ jobPlanning }) {
  const { isLoading, data, error } = useQuery(['duplicate_form_existed_job_plannings', jobPlanning.jobnumber.id], () =>
    getCompletePopupJobPlanningLines({ jobnumber_id: jobPlanning.jobnumber.id, show_completed_in_past: false })
  )
  return (
    <>
      {isLoading && (
        <tr>
          <td colSpan="7" className="text-center">
            <i className="fa fa-spinner fa-spin fa-2x" />
          </td>
        </tr>
      )}
      {error && (
        <tr>
          <td colSpan="7" className="error text-center">
            {error}
          </td>
        </tr>
      )}
      {data &&
        data.map(row => {
          return (
            <tr key={row.key}>
              <td className="align-middle">{row.job_task_no}</td>
              <td className="align-middle">{row.finished_date}</td>
              <td className="align-middle">{row.no}</td>
              <td className="align-middle">{row.quantity}</td>
              <td className="align-middle">{row.comment}</td>
              <td className="align-middle">{row.placement}</td>
              <td />
            </tr>
          )
        })}
    </>
  )
}

DuplicateFormExistingJobPlannings.propTypes = {
  jobPlanning: PropTypes.shape({
    id: PropTypes.string,
    jobnumber: PropTypes.shape({
      id: PropTypes.number,
    }),
    quantity: PropTypes.string,
    comment: PropTypes.string,
    actually_completed: PropTypes.string,
    description: PropTypes.string,
    finished_date: PropTypes.string,
    placement: PropTypes.string,
  }).isRequired,
}

export default DuplicateFormExistingJobPlannings
