import * as React from 'react'
import { useState } from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../../helpers/quieryClient'

import DictionariesContext from '../../DictionariesContext'
import JobPlanningsTable from './JobPlanningsTable'

type ListAppProps = {
  availableAreas: string[]
  availableWorktypes: any[]
  availableWorkcards: any[]
  availablePartners: any[]
  availableWarehouses: any[]
  availableMachines: any[]
  availableUsers: any[]
  availableTasks: any[]
  availableApprovalFormTemplates: any[]
  availableDepartments: any[]
  allPartners: any[]
  createSMProjectUrl: string
  smProjectUrl: string
  currentUser: any
}

export default function ListApp(props: ListAppProps) {
  const {
    availableAreas,
    availableWorktypes,
    availableWorkcards,
    availablePartners,
    availableWarehouses,
    availableMachines,
    availableUsers,
    availableTasks,
    availableApprovalFormTemplates,
    availableDepartments,
    createSMProjectUrl,
    smProjectUrl,
    allPartners,
    currentUser,
  } = props

  const queryClient = buildQueryClient()

  const [dictionaries, setDictionaries] = useState({
    availableAreas,
    availableWorktypes,
    availableWorkcards,
    availablePartners,
    availableWarehouses,
    availableMachines,
    availableUsers,
    availableTasks,
    availableApprovalFormTemplates,
    availableDepartments,
    allPartners,
    createSMProjectUrl,
    smProjectUrl,
    currentUser,
    updateCurrentUser: user => {
      setDictionaries({ ...dictionaries, currentUser: user })
    },
  })

  return (
    <DictionariesContext.Provider value={dictionaries}>
      <QueryClientProvider client={queryClient}>
        <JobPlanningsTable />
      </QueryClientProvider>
    </DictionariesContext.Provider>
  )
}
