import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'

function DepartmentLinks({ department }) {
  const [links, setLinks] = useState(department.links || [])
  const [removedIds, setRemovedIds] = useState([])

  const inputId = name => `edit-current-user-department-links-${department.id}-${name}`
  const inputName = (name, index) => `department_links[${department.id}][department_links_attributes][${index}][${name}]`

  const handleAddLink = () => {
    const newLink = {
      key: uuid(),
      label: '',
      url: '',
    }
    setLinks([...links, newLink])
  }
  const removeLink = link => {
    if (link.id) {
      setRemovedIds([...removedIds, link.id])
    }
    setLinks(links.filter(l => link !== l))
  }

  return (
    <div className="mt-4">
      <h4>{department.name}</h4>
      {links.map((link, index) => {
        return (
          <div className="card mb-2" key={link.id || link.key}>
            <div className="card-body">
              {link.id && <input type="hidden" name={inputName('id', index)} value={link.id} />}
              <i role="button" className="edit-current-user-department-links-remove fa fa-icon fa-times" onClick={() => removeLink(link)} />
              <div className="form-group">
                <label htmlFor={inputId('label')}>Label</label>
                <input
                  className="form-control"
                  id={inputId('label')}
                  name={inputName('label', index)}
                  placeholder="Go to Google"
                  type="text"
                  defaultValue={link.label}
                />
              </div>
              <div className="form-group">
                <label htmlFor={inputId('href')}>URL</label>
                <input
                  className="form-control"
                  id={inputId('href')}
                  name={inputName('href', index)}
                  placeholder="https://google.com"
                  type="url"
                  defaultValue={link.href}
                />
              </div>
            </div>
          </div>
        )
      })}
      {removedIds.map((id, index) => (
        <React.Fragment key={id}>
          <input type="hidden" name={inputName('_destroy', index)} value="1" />
          <input type="hidden" name={inputName('id', index)} value={id} />
        </React.Fragment>
      ))}
      <button className="btn btn-outline-secondary" type="button" onClick={handleAddLink}>
        Add link
      </button>
    </div>
  )
}

DepartmentLinks.propTypes = {
  department: PropTypes.object.isRequired,
}

export default DepartmentLinks
