import React from 'react'
import PropTypes from 'prop-types'

function RemoveFieldsButton({ handleRemove, className }) {
  const classes = `${className} remove-fields-button  fa fa-icon fa-close`
  return <i role="button" className={classes} onClick={handleRemove} />
}

RemoveFieldsButton.propTypes = {
  handleRemove: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default RemoveFieldsButton
