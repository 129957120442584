import { useMutation } from 'react-query'
import { SavedFilterType } from '../components/DictionariesContext'
import { useEffect, useState } from 'react'
import {createReminder} from "../api/reminders";

export default function useReminderFormController(department, templates) {
  const [currentTemplate, setCurrentTemplate] = useState(templates[0])
  const defaultRecipients = department.managers.map(user => user.id)

  const [formData, setFormData] = useState({
    subject: currentTemplate?.subject,
    body: currentTemplate?.body,
    recipients: defaultRecipients,
  })
  useEffect(() => {
    setFormData({ ...formData, subject: currentTemplate?.subject, body: currentTemplate?.body })
  }, [currentTemplate])

  const mutation = useMutation({ mutationFn: (payload: any) => createReminder(payload) })

  return { mutation, formData, setFormData, currentTemplate, setCurrentTemplate }
}
