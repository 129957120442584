import React from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'
import CompleteButton from './CompleteButton'
import PlacementField from './PlacementField'

function CardView({ data, reload }) {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-sm-3">
      {data &&
        data.map(row => {
          const minutes = Math.round(parseFloat(row.quantity) * 60)
          const time = `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`
          return (
            <div key={row.key} className="col mb-4">
              <div className="card h-100">
                <div className="card-header d-flex justify-content-between">
                  <div>
                    {moment(row.finished_date).format('DD-MM-YYYY')}&nbsp;|&nbsp;<b>{time}</b>
                  </div>
                  <div className="align-self-right">{row.no}</div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    {row.job_task_no}, {row.jobnumber && row.jobnumber.customer_title}
                  </h5>
                  <p className="card-text">{row.description}</p>
                  {row.comment && <p className="card-text">Bemærkning: {row.comment}</p>}
                </div>
                <div className="card-footer bg-transparent">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      Placering:&nbsp;
                      <PlacementField jobPlanningKey={row.key} placement={row.placement} reload={reload} />
                    </div>
                    <div className="">
                      <CompleteButton jobPlanning={row} reload={reload} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default CardView
