import { week_list_path } from '../../routes'
import DepartmentsPicker from './DepartmentsPicker'
import DatePickerButton from '../shared/DatePickerButton'
import { format, parseISO } from 'date-fns'
import { da } from 'date-fns/locale'

interface FilterAndExportRowProps {
  startDate: string
  endDate: string
  availableDepartments: any[]
  selectedDepartmentIds: number[]
}

export default function FilterAndExportRow(props: FilterAndExportRowProps) {
  const { startDate, endDate, availableDepartments, selectedDepartmentIds } = props
  const exportUrl = week_list_path({
    start_date: startDate,
    end_date: endDate,
    department_ids: selectedDepartmentIds,
    format: 'xlsx',
  })
  const start = parseISO(startDate)
  const end = parseISO(endDate)
  return (
    <div className="row mb-3">
      <div className="col-4">
        <DepartmentsPicker
          startDate={startDate}
          endDate={endDate}
          availableDepartments={availableDepartments}
          selectedDepartmentIds={selectedDepartmentIds}
        />
      </div>
      <div className="col-3">
        <DatePickerButton
          buttonLabel={format(start, 'PPP', { locale: da })}
          buttonClass="btn btn-outline-secondary col-12"
          date={start}
          setDate={date => {
            if (date !== start) {
              const urlOptions = {
                end_date: format(end, 'yyyy-MM-dd'),
                start_date: format(date, 'yyyy-MM-dd'),
                department_ids: selectedDepartmentIds,
              }
              window.location.href = week_list_path(urlOptions)
            }
          }}
        />
      </div>
      <div className="col-3">
        <DatePickerButton
          buttonLabel={format(end, 'PPP', { locale: da })}
          buttonClass="btn btn-outline-secondary col-12"
          date={end}
          setDate={date => {
            if (date !== end) {
              const urlOptions = {
                end_date: format(date, 'yyyy-MM-dd'),
                start_date: format(start, 'yyyy-MM-dd'),
                department_ids: selectedDepartmentIds,
              }
              window.location.href = week_list_path(urlOptions)
            }
          }}
        />
      </div>
      <div className="col-2">
        <a className="btn btn-outline-secondary col-12" href={exportUrl}>
          <i className="fa fa-download mr-1" />
          Download excel
        </a>
      </div>
    </div>
  )
}
