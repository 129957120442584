import { DirectUpload } from '@rails/activestorage'

export default class Uploader {
  private options: {
    onError?: (error: any) => void
    onProgress?: (progress: any) => void
    onSuccess?: (success: any) => void
  }
  private readonly url: string
  private directUpload: any
  private readonly file: File

  constructor(file, options = {}) {
    this.options = options
    this.file = file
    this.url = document.querySelector('meta[name=direct-upload-url]')?.getAttribute('content')
    this.directUpload = new DirectUpload(file, this.url, this)
  }

  upload() {
    this.directUpload.create((error, blob) => {
      if (error) {
        // Handle the error
        this.options.onError(error)
      } else {
        this.options.onSuccess(blob)
        // Use the with blob.signed_id as a file reference in next request
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
    const progress = (event.loaded / event.total) * 100
    this.options.onProgress(progress)
  }
}
