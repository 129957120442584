import * as React from 'react'
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'
import { ResourcesTimeShape } from '../../api/api_data_types'
import { useEffect } from 'react'
import CorrectItemButton from '../../components/items_overview_app/CorrectItemButton'

function fuzzyFilter(row, columnId, value, addMeta) {
  const itemRank = rankItem(row.getValue(columnId), value)
  addMeta({ itemRank }) // Store the itemRank info
  return itemRank.passed
}

export default function useItemsOverviewTable(items) {
  const [highlightItems, setHighlightItems] = React.useState(false)

  const cellFunction = info => {
    const value = info.getValue()
    if (value === null || value === undefined) {
      return null
    }

    if (highlightItems && info.column.id === 'no') {
      return <CorrectItemButton item={info.row.original} />
      // return <span className="text-danger">{value}</span>
    }

    if (!isNaN(value)) {
      return value.toLocaleString('da-DK')
    }

    return value
  }

  const [data, setData] = React.useState(() => [...items])
  useEffect(() => {
    if (!highlightItems) {
      setData(items)
      return
    }

    const filteredItems = items.filter(item => {
      const sumQuantities = item.inventory_fjernlager + item.inventory_malov + item.inventory_skejby + item.inventory_lyfa
      const quantity = item.inventory
      // compare using 4 decimals
      return Math.abs(sumQuantities - quantity) > 0.0001
    })
    setData(filteredItems)
  }, [highlightItems])

  const columns: ColumnDef<ResourcesTimeShape>[] = React.useMemo(
    () => [
      { header: 'Nr', accessorKey: 'no', cell: cellFunction, filterFn: 'includesString' },
      { header: 'Beskrivelse', accessorKey: 'description', cell: cellFunction, filterFn: 'includesString' },
      { header: 'Beskrivelse 2', accessorKey: 'description_2', cell: cellFunction, filterFn: 'includesString' },
      { header: 'Antal på ordre', accessorKey: 'quantity_printvis_orders', cell: cellFunction },
      { header: 'Placering (Vest)', accessorKey: 'shelf_no_dgj', cell: cellFunction, filterFn: 'includesString' },
      { header: 'Placering (Øst)', accessorKey: 'shelf_no_east', cell: cellFunction, filterFn: 'includesString' },
      { header: 'Basisenhed', accessorKey: 'base_unit_of_measure', cell: cellFunction, filterFn: 'includesString' },
      {
        header: 'Antal i køb',
        accessorKey: 'qty_on_purch_order',
        cell: cellFunction,
        filterFn: 'includesString',
      },
      { header: 'Lager', accessorKey: 'inventory', cell: cellFunction, filterFn: 'includesString' },
      {
        header: 'Måløv',
        accessorKey: 'inventory_malov',
        cell: cellFunction,
        filterFn: 'includesString',
      },
      {
        header: 'Skejby',
        accessorKey: 'inventory_skejby',
        cell: cellFunction,
        filterFn: 'includesString',
      },
      {
        header: 'Lyfa',
        accessorKey: 'inventory_lyfa',
        cell: cellFunction,
        filterFn: 'includesString',
      },
      {
        header: 'Fjernlager',
        accessorKey: 'inventory_fjernlager',
        cell: cellFunction,
        filterFn: 'includesString',
      },
      { header: 'Kæde', accessorKey: 'chain_name', cell: cellFunction, filterFn: 'includesString' },
    ],
    [items, highlightItems]
  )

  const [columnFilters, setColumnFilters] = React.useState([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    initialState: {
      sorting: [{ id: 'no', desc: false }],
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return { table, globalFilter, setGlobalFilter, highlightItems, setHighlightItems }
}
