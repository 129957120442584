import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import uuid from 'uuid/v4'
import WarehouseItemFields from './WarehouseItemFields'
import RemovedFields from '../resources_records/RemovedFields'
import { timesHash } from '../shared/utils'

function FormFields({ record, availableWarehouses, submitting, onSubmit }) {
  const [warehouseId, setWarehouseId] = useState((record.warehouse && record.warehouse.id) || '')
  const [date, setDate] = useState(record.date || moment().format('YYYY-MM-DD'))
  const initialItems = record.warehouse_items && record.warehouse_items.length > 0 ? record.warehouse_items : [{}]
  const [items, setItems] = useState(timesHash(initialItems))
  const [removedItems, setRemovedItems] = useState([])

  useEffect(() => {
    if (submitting) {
      const attributesReducer = (acc, id, index) => {
        acc[10000 + index] = { id, destroy: 1 }
      }

      const allItems = { ...items, ...removedItems.reduce(attributesReducer, {}) }

      onSubmit({
        resources_warehouse: {
          warehouse_id: warehouseId,
          date,
          items_attributes: { ...Object.values(allItems) },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting])

  const cloneFn = (collection, setCollection) => {
    return source => {
      const newKey = uuid()
      const newItem = {}
      setCollection({ ...collection, [newKey]: newItem })
    }
  }

  const cloneItemFields = cloneFn(items, setItems)

  const removeFn = (collection, setCollection, removedCollection, setRemovedCollection) => {
    return key => {
      const newCollection = { ...collection }
      const item = newCollection[key]
      if (item.id) {
        setRemovedCollection([...removedCollection, item.id])
      }
      delete newCollection[key]
      setCollection(newCollection)
    }
  }

  const removeItemFields = removeFn(items, setItems, removedItems, setRemovedItems)

  return (
    <>
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="resources-warehouse-warehouse">Lager</label>
          <select
            id="resources-warehouse-warehouse"
            required
            className="form-control col-xs-12"
            name="resources_warehouse[warehouse_id]"
            value={warehouseId}
            onChange={e => setWarehouseId(e.target.value)}
          >
            {availableWarehouses.map(wh => {
              return (
                <option key={wh[1]} value={wh[1]}>
                  {wh[0]}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="resources-record-date">Dato</label>
          <input
            id="resources-record-date"
            required
            type="date"
            className="form-control col-xs-12"
            value={date}
            onChange={e => setDate(e.target.value)}
            name="resources_warehouse[date]"
          />
        </div>
      </div>

      {Object.keys(items).map((key, index) => {
        const showRemoveItemFieldsButton = Object.keys(items).length > 1
        return (
          <WarehouseItemFields
            key={key}
            idKey={key}
            index={index}
            itemFields={items[key]}
            setItemFields={data => setItems({ ...items, [key]: data })}
            cloneItemFields={cloneItemFields}
            removeItemFields={removeItemFields}
            showRemoveItemFieldsButton={showRemoveItemFieldsButton}
          />
        )
      })}
      <RemovedFields collection={removedItems} namePrefix="resources_warehouse[warehouse_items_attributes]" />
    </>
  )
}

FormFields.propTypes = {
  availableWarehouses: PropTypes.array.isRequired,
  record: PropTypes.shape({
    warehouse_id: PropTypes.object,
    warehouse: PropTypes.object,
    warehouse_items: PropTypes.array,
  }).isRequired,
}

export default FormFields
