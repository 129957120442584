import * as React from 'react'
import { ItemShape } from '../../api/api_data_types'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import { useMoveItemMutation } from '../../hooks/queries/items'

type CorrectItemFormProps = {
  item: ItemShape
  close: () => void
}
export default function CorrectItemForm(props: CorrectItemFormProps) {
  const { item, close } = props
  const { inventory, inventory_malov, inventory_skejby, inventory_lyfa, inventory_fjernlager } = item
  const defaultAmount = inventory - (inventory_malov + inventory_skejby + inventory_lyfa + inventory_fjernlager)
  const form = useForm({ defaultValues: { amount: defaultAmount, destination: '', item_id: item.id } })
  const mutation = useMoveItemMutation(item.id, () => {})
  const amount = form.watch('amount') || 0

  const onSubmit = data => {
    mutation.mutate(data)
  }

  return (
    <div className="mt-3">
      <ErrorMessage className="d-inline error-message" error={mutation.error} isError={mutation.isError} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <input type={'hidden'} {...form.register('item_id')} name="item_id" value={item.id} />
        <div className="row">
          <div className="col-sm-5 form-group">
            <label htmlFor="move-item-amount">Antal</label>
            <input
              id="move-item-amount"
              {...form.register('amount')}
              required
              type="number"
              className="form-control col-xs-12"
              name="amount"
            />
          </div>
          <div className="col-sm-1 form-group pt-2">
            <label className="d-block" >&nbsp;</label>
            <i className="fa fa-arrow-right"  />
          </div>

          <div className="col-sm-6 form-group">
            <label htmlFor="move-item-destination">Lokation</label>
            <select
              id="move-item-destination"
              {...form.register('destination')}
              required
              className="form-control col-xs-12"
              name="destination"
            >
              <option value="">Vælg lokation</option>
              <option value="MÅLØV">Lager Måløv</option>
              <option value="SKEJBY">Lager Skejby</option>
              <option value="LYFA">Lager Lyfa</option>
              <option value="FJERNLAGER">Lager Fjernlager</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5 form-group">
            <input
              id="move-item-amount-negative"
              type="number"
              disabled
              value={-amount}
              className="form-control col-xs-12"
              name="amount"
            />
          </div>
          <div className="col-sm-1 form-group pt-2">
            <i className="fa fa-arrow-right"  />
          </div>
          <div className="col-sm-6 form-group">
            <input
              id="move-item-destination-negative"
              disabled
              type='text'
              className="form-control col-xs-12"
              value="Blank"
              name="destination"
            />
          </div>
        </div>
        {mutation.isSuccess && (
          <div className="alert alert-success" role="alert">
            Varen er flyttet
          </div>
        )}
        <div className="actions">
          <button type="button" className="btn btn-outline-secondary" onClick={close}>
            {mutation.isSuccess ? 'Luk' : 'Annuller'}
          </button>
          {!mutation.isSuccess && (
            <button type="submit" className="btn btn-primary" disabled={mutation.isLoading || mutation.isError}>
              {mutation.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              {!mutation.isLoading && 'Flyt'}
            </button>
          )}
        </div>
      </form>
    </div>
  )
}
