import React from 'react'
import PropTypes from 'prop-types'

function RemovedFields({ collection, namePrefix }) {
  return collection.map((id, index) => {
    return (
      <React.Fragment key={namePrefix + id}>
        <input type="hidden" name={`${namePrefix}[${10000 + index}][id]`} value={id} />
        <input type="hidden" name={`${namePrefix}[${10000 + index}][_destroy]`} value />
      </React.Fragment>
    )
  })
}

RemovedFields.propTypes = {
  collection: PropTypes.array.isRequired,
  namePrefix: PropTypes.string.isRequired,
}

export default RemovedFields
