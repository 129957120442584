import React from 'react'

export default function Tab({ title, name, activeTab, setActiveTab }) {
  const tabClass = name === activeTab ? 'active' : ''
  return (
    <li className="nav-item">
      <a
        className={`nav-link ${tabClass}`}
        href="#"
        onClick={e => {
          e.preventDefault()
          setActiveTab(name)
        }}
      >
        {title}
      </a>
    </li>
  )
}
