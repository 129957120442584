import * as moment from 'moment/moment'
import { JobPlanningLineShape } from '../api/api_data_types'
import GoogleMapsPin from '../components/shared/GoogleMapsPin'

export default function useJobPlanningLineRow(jobPlanning: JobPlanningLineShape) {
  const minutes = Math.round(parseFloat(jobPlanning.quantity) * 60)
  const time = `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`

  const formattedDate = moment(jobPlanning.finished_date).isSame('0001-01-01')
    ? 'Ingen dato'
    : moment(jobPlanning.finished_date).format('DD-MM-YYYY')

  let addressLink = null
  if (jobPlanning.ship_to_city && jobPlanning.ship_to_address) {
    addressLink = <GoogleMapsPin address={[jobPlanning.ship_to_address, jobPlanning.ship_to_city]} />
  }

  let description = jobPlanning.jobnumber && jobPlanning.jobnumber.title
  if (jobPlanning.job_task_no.toString() === '2001') {
    description = description ? `${jobPlanning.description} | ${description}` : jobPlanning.description
  }

  const persons = [
    ['Ordrebehandler', jobPlanning.project_creator],
    ['Sælger', jobPlanning.salesperson],
    ['Projektleder', jobPlanning.project_manager],
  ]
    .filter(p => !!p[1])
    .map(p => `${p[0]}: ${p[1]}`)
    .join(', ')

  return { time, formattedDate, addressLink, description, persons }
}
