import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

function RegisteredTimeIndicator({ date, userData }) {
  if (date.isSameOrAfter(moment(), 'day')) {
    return null
  }
  const norm = parseInt(userData.norm, 10)
  const registered = parseInt(userData.registered, 10) || 0
  const absence = parseInt(userData.absence, 10) || 0
  let classColor = 'red'
  if (!Number.isNaN(norm)) {
    classColor = norm - registered - absence > 0 ? 'red' : 'green'
  }
  return <span className={`resources-overview-registered-time-indicator ${classColor}`} />
}

RegisteredTimeIndicator.propTypes = {
  date: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
}

export default RegisteredTimeIndicator
