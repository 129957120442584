import { useContext } from 'react'
import TravelDictionariesContext from '../components/travel_list_app/TravelDictionariesContext'
import { format } from 'date-fns'
import { Travel } from '../components/travel_list_app/travel_list'

export default function useTravelRow(travel: Travel) {
  const { currentUser } = useContext(TravelDictionariesContext)
  const formattedDate = format(new Date(travel.date), 'dd-MM-yyyy')
  const direction = travel.direction === 'east_to_west' ? 'ØST->VEST' : 'VEST->ØST'

  const joined = travel.passengers.some(passenger => passenger.id === currentUser.id)
  const canJoin =
    travel.user.id !== currentUser.id && travel.driver.id !== currentUser.id && !joined && travel.seat_capacity > travel.passengers.length

  const availableSeats = travel.seat_capacity - 1 - travel.passengers.length

  return {
    formattedDate,
    direction,
    joined,
    canJoin,
    availableSeats,
  }
}
