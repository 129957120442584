import * as React from 'react'
import { useContext } from 'react'
import ProductionPlanningDictionariesContext from './ProductionPlanningDictionariesContext'
import { SavedFiltersControllerType } from '../../hooks/useSavedFiltersController'
import SavedFilters from '../job_plannings/filter/SavedFilters'

export type ProductionPlanningFilterShape = {
  area: string
  production_place: string
  status_code: string
  hide_items?: boolean
  start_date?: string
  end_date?: string
}

type FilterFormProps = {
  savedFiltersController: SavedFiltersControllerType
  closeModal: () => void
}

export default function FilterForm(props: FilterFormProps) {
  const { savedFiltersController, closeModal } = props

  const { availableAreas } = useContext(ProductionPlanningDictionariesContext)

  const { setFilter, formFilter, setFormFilter, currentSavedFilter } = savedFiltersController

  const startDate = formFilter.start_date || ''
  const endDate = formFilter.end_date || ''
  const productionPlace = formFilter.production_place || 'east'
  const statusCode = formFilter.status_code || 'ALL'
  const hideItems = formFilter.hide_items || false

  return (
    <>
      <div className="production-planning-filter-modal-content popup-content">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="production-planning-filter-area">Area</label>
            <select
              id="production-planning-filter-area"
              className="form-control"
              value={formFilter.area}
              onChange={e => setFormFilter({ ...formFilter, area: e.target.value })}
            >
              {availableAreas.map(area => (
                <option key={area} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="production-planning-filter-production-place">Producent sted</label>
            <select
              id="production-planning-filter-production-place"
              className="form-control"
              value={productionPlace}
              onChange={e => setFormFilter({ ...formFilter, production_place: e.target.value })}
            >
              <option value="east">Øst</option>
              <option value="west">Vest</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="production-planning-filter-status-code">Statuscode</label>
            <select
              id="production-planning-filter-status-code"
              className="form-control"
              value={statusCode}
              onChange={e => setFormFilter({ ...formFilter, status_code: e.target.value })}
            >
              <option value="ALL">ALL</option>
              <option value="PROD-ORDRE">PROD-ORDRE</option>
              <option value="PRODBAKKEN">PRODBAKKEN</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="production-plannings-filter-start-date">Dato fra</label>
            <input
              id="production-plannings-filter-start-date"
              type="date"
              className="form-control"
              value={startDate}
              onChange={e => setFormFilter({ ...formFilter, start_date: e.target.value })}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="production-plannings-filter-end-date">Dato til</label>
            <input
              id="production-plannings-filter-end-date"
              type="date"
              className="form-control"
              value={endDate}
              onChange={e => setFormFilter({ ...formFilter, end_date: e.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="production-plannings-filter-hide-items">
              <input
                id="production-plannings-filter-hide-items"
                type="checkbox"
                checked={hideItems}
                onChange={e => setFormFilter({ ...formFilter, hide_items: e.target.checked })}
              />
              <span className='ml-2'>Skjul Varer</span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <hr />
            <SavedFilters savedFiltersController={savedFiltersController} closeModal={closeModal} />
          </div>
        </div>
      </div>
      <div className="production-planning-filter-modal-actions popup-actions">
        <button type="button" className="btn btn-outline-secondary" onClick={closeModal}>
          Luk
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={e => {
            setFilter(formFilter)
            closeModal()
          }}
        >
          Apply filter
        </button>
      </div>
    </>
  )
}
