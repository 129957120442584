import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import ConsumptionLinesTable from './consumption_lines_table/ConsumptionLinesTable'
import EditConsumptionLineButton from './consumption_lines_table/EditConsumptionLineButton'
import SaveAllConsumptionLinesButton from './consumption_lines_table/SaveAllConsumptionLinesButton'
import ErrorMessage from './list_app/ErrorMessage'
import useConsumptionTableController from '../../hooks/useConsumptionTableController'
import checkFeatureAvailability from '../helpers/features'
import RefreshButton from './consumption_lines_table/RefreshButton'

function ConsumptionLinesPopupButton(props) {
  const { jobnumber, buttonClass, currentUser } = props
  const buttonTitle = 'Forbrugskladde'
  const title = jobnumber ? buttonTitle + ` (${jobnumber.full_title})` : buttonTitle

  const showConsumptionAction = checkFeatureAvailability('consumption', { currentUser }) && jobnumber.job_id !== null
  const { saveAllError, setSaveAllError, changedQuantities, setChangedQuantities } = useConsumptionTableController()

  if (!showConsumptionAction) {
    return null
  }

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-secondary'}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '992px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      nested
      modal
    >
      {close => (
        <div className={`resources-record-modal modal-popup`}>
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="resources-record-modal-header popup-header">
            {title}
            <RefreshButton className="ml-3" jobnumber={jobnumber} />
          </div>
          <div className="resources-record-modal-content popup-content">
            <ErrorMessage error={saveAllError} isError={saveAllError !== null} />
            <ConsumptionLinesTable
              jobnumber={jobnumber}
              changedQuantities={changedQuantities}
              setChangedQuantities={setChangedQuantities}
            />
          </div>
          <div className="resources-record-modal-actions popup-actions">
            <EditConsumptionLineButton
              jobCostingLine={{ job_id: jobnumber.job_id, user_no: currentUser.username }}
              buttonClass="btn btn-outline-primary"
              buttonTitle="Tilføj"
              windowTitlePrefix="Tilføj"
            />
            <SaveAllConsumptionLinesButton changedQuantities={changedQuantities} setError={setSaveAllError} />
            <button type="button" className="btn btn-outline-secondary" onClick={close}>
              Luk
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

ConsumptionLinesPopupButton.propTypes = {
  jobnumber: PropTypes.object.isRequired,
  buttonClass: PropTypes.string,
}

ConsumptionLinesPopupButton.defaultProps = {}

export default ConsumptionLinesPopupButton
