import Select from 'react-select'
import { Controller, UseFormReturn } from 'react-hook-form'
import { UserShape } from '../../api/api_data_types'
import { CurrentUserType } from '../DictionariesContext'

interface TravelFormFieldsProps {
  form: UseFormReturn<any>
  availableUsers: UserShape[]
  currentUser: CurrentUserType
}

export function userToOption(user: UserShape) {
  return { label: `${user.username} - ${user.name}`, value: user.id }
}

export default function TravelFormFields({ form, availableUsers, currentUser }: TravelFormFieldsProps) {
  const { register, formState, watch, control } = form
  const { errors } = formState
  const routeOption = watch('route_option')
  const users = availableUsers.map(u => userToOption(u))

  return (
    <>
      <div className="form-group">
        <label htmlFor="driver">Arrangør</label>
        <Controller name="driver" control={control} render={({ field }) => <Select isClearable {...field} options={users} />} />
      </div>
      <div className="form-group">
        <label htmlFor="passengers">Passagerer</label>
        <Controller name="passengers" control={control} render={({ field }) => <Select isMulti {...field} options={users} />} />
      </div>
      <div className="form-group">
        <label htmlFor="travel_date">Dato</label>
        <input
          type="date"
          className={`form-control${errors.date ? ' is-invalid' : ''}`}
          id="travel_date"
          {...register('date', { required: true })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="pickup_time">Pickup tidspunkt</label>
        <input
          type="time"
          className={`form-control${errors.pickup_time ? ' is-invalid' : ''}`}
          id="pickup_time"
          {...register('pickup_time', { required: true })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="pickup_location">Pickup sted</label>
        <input
          type="text"
          className={`form-control${errors.pickup_location ? ' is-invalid' : ''}`}
          id="pickup_location"
          {...register('pickup_location')}
        />
      </div>
      <div className="form-group">
        <label htmlFor="route_option">Rute</label>
        <select
          className={`form-control${errors.route_option ? ' is-invalid' : ''}`}
          id="route_option"
          {...register('route_option', { required: true })}
        >
          <option value="bridge">Bro</option>
          <option value="ferry">Færge</option>
        </select>
      </div>
      {routeOption === 'ferry' && (
        <div className="form-group">
          <label htmlFor="ferry_time">Færge</label>
          <input
            type="time"
            className={`form-control${errors.ferry_time ? ' is-invalid' : ''}`}
            id="ferry_time"
            {...register('ferry_time')}
          />
        </div>
      )}
      <div className="form-group">
        <label htmlFor="direction">Retning</label>
        <select
          className={`form-control${errors.direction ? ' is-invalid' : ''}`}
          id="direction"
          {...register('direction', { required: true })}
        >
          <option value="east_to_west">{'ØST->VEST'}</option>
          <option value="west_to_east">{'VEST->ØST'}</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="seat_capacity">Pladser (inkl. chauffør)</label>
        <input
          type="number"
          className={`form-control${errors.seat_capacity ? ' is-invalid' : ''}`}
          id="seat_capacity"
          min={1}
          step={1}
          {...register('seat_capacity', { required: true })}
        />
      </div>
    </>
  )
}
