import React from 'react'

export default function RecipientCheckbox({ value, title, formFields, setFormFields }) {
  if (value === undefined || value === null) {
    return null
  }

  const handleCheckboxChange = e => {
    const recipients = e.target.checked ? [...formFields.recipients, value] : formFields.recipients.filter(a => a !== value)
    setFormFields({ ...formFields, recipients })
  }

  return (
    <label className="pl-0 form-check form-check-valigned message-popup-recipient">
      <input
        type="checkbox"
        name="message[recipients][]"
        value={value}
        checked={formFields.recipients.includes(value)}
        onChange={handleCheckboxChange}
      />
      {title} ({value})
    </label>
  )
}
