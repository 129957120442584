export default function GoogleMapsPin({ address }: { address: string | string[] }) {
  let addressString = ''
  if (typeof address === 'string') {
    addressString = address
  } else {
    addressString = address.filter(a => a.length > 0).join(', ')
  }
  if (addressString.length === 0) {
    return null
  }

  const mapsQuery = encodeURIComponent(addressString)
  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${mapsQuery}`
  return (
    <a href={addressUrl} target="_blank" rel="noreferrer" title={addressString}>
      <i className="fa fa-icon fa-map-marker-alt" />
    </a>
  )
}
