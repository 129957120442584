import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getTravels, createTravel, updateTravel, joinTravel, leaveTravel, destroyTravel } from '../../api/travels'
import { FilterShape } from '../../components/travel_list_app/travel_list'

export function useTravelsQuery(filter: FilterShape) {
  return useQuery(['travels', filter], () => getTravels(filter))
}

function reloadTravelsFn(queryClient) {
  return () => queryClient.invalidateQueries('travels')
}

export function useCreateTravelMutation() {
  return useMutation(createTravel, { onSuccess: reloadTravelsFn(useQueryClient()) })
}

export function useUpdateTravelMutation(travelId) {
  return useMutation(payload => updateTravel(travelId, payload), { onSuccess: reloadTravelsFn(useQueryClient()) })
}

export function useJoinTravelMutation() {
  return useMutation((id: number) => joinTravel(id), { onSuccess: reloadTravelsFn(useQueryClient()) })
}

export function useLeaveTravelMutation() {
  return useMutation((id: number) => leaveTravel(id), { onSuccess: reloadTravelsFn(useQueryClient()) })
}

export function useDestroyTravelMutation() {
  return useMutation((id: number) => destroyTravel(id), { onSuccess: reloadTravelsFn(useQueryClient()) })
}
