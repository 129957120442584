import React from 'react'
import PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from 'react-query'
import DictionariesContext from '../DictionariesContext'
import ResourcesOverview from './ResourcesOverview'

function ResourcesOverviewApp({ startDate, endDate, availableDepartments, availablePartners, availableUsers, currentUser }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  const dictionaries = {
    availableDepartments,
    availablePartners,
    availableUsers,
    currentUser,
  }

  return (
    <DictionariesContext.Provider value={dictionaries}>
      <QueryClientProvider client={queryClient}>
        <ResourcesOverview startDate={startDate} endDate={endDate} />
      </QueryClientProvider>
    </DictionariesContext.Provider>
  )
}

ResourcesOverviewApp.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export default ResourcesOverviewApp
