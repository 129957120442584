import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import TableView from './TableView'
import CardView from './CardView'
import { useCompletePopupJobPlanningLinesQuery } from '../../../hooks/queries/job_planning_lines'
import ErrorMessage from '../list_app/ErrorMessage'

function JobPlanningsTable(props) {
  const { currentView, jobnumberId } = props
  const [showCompletedInPast, setShowCompletedInPast] = useState(false)
  const [apiError, setApiError] = useState()
  const { isLoading, data, error, reload } = useCompletePopupJobPlanningLinesQuery(jobnumberId, showCompletedInPast)

  useEffect(() => {
    if (error) {
      setApiError(error)
    }
  }, [error])

  if (apiError) {
    return <ErrorMessage isError={true} error={apiError} />
  }

  return (
    <>
      <div className="form-check form-check-valigned job-planning-complete-show-completed">
        <label>
          <input type="checkbox" checked={showCompletedInPast} onChange={e => setShowCompletedInPast(e.target.checked)} />
          Vis afsluttede stadier
        </label>
      </div>
      {currentView === 'table' && <TableView data={data} reload={reload} setApiError={setApiError}/>}
      {currentView === 'cards' && <CardView data={data} reload={reload} setApiError={setApiError}/>}
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      {error && <div className="job-plannings-table-error">{error.message}</div>}
    </>
  )
}

JobPlanningsTable.propTypes = {
  jobnumberId: PropTypes.number.isRequired,
}
export default JobPlanningsTable
