import { createContext } from 'react'
import { CurrentUserType } from '../DictionariesContext'
import { UserShape } from '../../api/api_data_types'

export type TravelDictionariesContextType = {
  currentUser: CurrentUserType
  availableUsers: UserShape[]
}

const TravelDictionariesContext = createContext<TravelDictionariesContextType>(null)

export default TravelDictionariesContext
