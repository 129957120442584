import * as React from 'react'
import Filter from './Filter'
import Table from './Table'
import { useProductionPlanningOverviewQuery } from '../../hooks/queries/production_planning'
import { useStorageState } from 'react-storage-hooks'
import { useEffect } from 'react'

type OverviewProps = {
  area: string
}

export default function Overview(props: OverviewProps) {
  const { area } = props

  const defaultFilter = {
    area: area,
    production_place: 'east',
    status_code: 'ALL',
    hide_items: false,
  }

  const [filter, setFilter] = useStorageState(sessionStorage, 'production-planning-filter', defaultFilter)
  const [showCompletedToday, setShowCompletedToday] = useStorageState(sessionStorage, 'production-planning-show-completed-today', false)
  const hideItems = filter?.hide_items || false
  const populatedFilter = filter?.area ? filter : defaultFilter
  const { isFetching, isError, data, error } = useProductionPlanningOverviewQuery(populatedFilter, showCompletedToday)
  const productionPlanningLines = data || {}
  useEffect(() => {
    if (!filter?.area) {
      setFilter(defaultFilter)
    }
  }, [])

  return (
    <div>
      <Filter
        filter={populatedFilter}
        setFilter={setFilter}
        showCompletedToday={showCompletedToday}
        setShowCompletedToday={setShowCompletedToday}
      />
      <Table
        isError={isError}
        error={error}
        isLoading={isFetching}
        hideItems={hideItems}
        productionPlanningLines={productionPlanningLines}
        area={filter.area}
        showCompletedToday={showCompletedToday}
      />
    </div>
  )
}
