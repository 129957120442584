import Actions from './Actions'
import useJobPlanningLineRow from '../../../hooks/useJobPlanningLineRow'
import { JobPlanningLineShape } from '../../../api/api_data_types'

type CardProps = {
  jobPlanning: JobPlanningLineShape
  className: string
}

function Card({ jobPlanning, className }: CardProps) {
  const { time, formattedDate, addressLink, description, persons } = useJobPlanningLineRow(jobPlanning)

  return (
    <div className={`card ${className}`}>
      <div className="card-header d-flex justify-content-between" title={persons}>
        <div>
          {formattedDate}&nbsp;|&nbsp;<b>{time}</b>
          <span className="ml-1 badge badge-light">{jobPlanning.status_code}</span>
        </div>
        <div className="align-self-right">{jobPlanning.no}</div>
      </div>
      <div className="card-body">
        <h5 className="card-title">
          {jobPlanning.job_id} - {jobPlanning.order_no}
          <br />
          {jobPlanning.jobnumber && jobPlanning.jobnumber.customer_title}
        </h5>
        <p className="card-text">
          {jobPlanning.job_task_no}, {description}
          {jobPlanning.place_of_production && (
            <>
              <br />
              {jobPlanning.place_of_production}
            </>
          )}
        </p>
        {jobPlanning.placement && <p className="card-text">Placering: {jobPlanning.placement}</p>}
        {jobPlanning.comment && <p className="card-text">Bemærkning: {jobPlanning.comment}</p>}
      </div>
      <div className="card-footer bg-transparent">
        <div className="row">
          <div className="col pt-2">{addressLink && <span className="">{addressLink}</span>}</div>
          <div className="col">
            {jobPlanning.jobnumber && (
              <Actions
                jobnumberId={jobPlanning.jobnumber.id}
                jobnumber={jobPlanning.jobnumber}
                jobPlanning={jobPlanning}
                currentView="cards"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
