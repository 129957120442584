import initRoutes from './routes'
import initAlert from './alert'
import initList from './lists'

const { $ } = window as any

$(document).ready(() => {
  initRoutes()
  initAlert()
  initList()
})
