import React from 'react'
import PropTypes from 'prop-types'
import SelectJobnumbers from './SelectJobnumbers'
import DepartmentLinks from './DepartmentLinks'
import DepartmentWorktypes from './DepartmentWorktypes'
import DictionariesContext from '../../DictionariesContext'

function EditCurrentUser(props) {
  const { userJobnumbers, departments, availableWorktypes } = props
  const dictionaries = { availableWorktypes }

  return (
    <DictionariesContext.Provider value={dictionaries}>
      <div>
        <SelectJobnumbers
          defaultJobnumbers={userJobnumbers}
          inputName="user_jobnumber_ids[]"
          label="Bruger specifikke Sags-ID numre:"
          selectId="edit-user-jobnumber-select"
        />

        {departments &&
          departments.map(department => {
            const elementId = `edit-department-jobnumber-select-${department.id}`
            const { jobnumbers } = department
            return (
              <div className="mt-4" key={department.id}>
                <SelectJobnumbers
                  defaultJobnumbers={jobnumbers}
                  inputName={`department_jobnumber_ids[${department.id}][]`}
                  label={`Sagsnumre til afdeling "${department.name}":`}
                  selectId={elementId}
                />
              </div>
            )
          })}

        {departments && <h2 className="mt-4">Links for departments</h2>}
        {departments && departments.map(department => <DepartmentLinks department={department} key={department.id} />)}
        {departments && <h2 className="mt-4">Worktypes for departments</h2>}
        {departments && departments.map(department => <DepartmentWorktypes department={department} key={department.id} />)}
      </div>
    </DictionariesContext.Provider>
  )
}
EditCurrentUser.propTypes = {
  userJobnumbers: PropTypes.array.isRequired,
  departments: PropTypes.array,
  availableWorktypes: PropTypes.array,
}

export default EditCurrentUser
