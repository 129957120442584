import React, { useEffect } from 'react'
import { useCompleteJobPlanningMutation } from './queries/job_planning_lines'

export default function useCompleteJobPlanningController(jobPlanning, currentUser, availableMachines, reload, setApiError) {
  const { key } = jobPlanning
  const machineNumbers = availableMachines.map(m => m.no)
  const mutation = useCompleteJobPlanningMutation(key, reload)
  const { isLoading, isError, error } = mutation
  const isCompleted = jobPlanning.actually_completed && jobPlanning.actually_completed.length > 0

  useEffect(() => {
    if (isError) {
      setApiError(error)
    }
  }, [isError])

  let isAllowed = false
  if (!isCompleted) {
    // Could be completed
    if (
      currentUser.complete_stage_permission === 'all' ||
      (currentUser.complete_stage_permission === 'own' && jobPlanning.no === currentUser.username) ||
      (currentUser.complete_stage_permission === 'machine' && jobPlanning.no === currentUser.username) ||
      (currentUser.complete_stage_permission === 'machine' && machineNumbers && machineNumbers.includes(jobPlanning.no)) ||
      (currentUser.complete_stage_permission === 'managed_departments' &&
        currentUser.is_department_manager &&
        (jobPlanning.no === currentUser.username ||
          (currentUser.managed_usernames && currentUser.managed_usernames.includes(jobPlanning.no))))
    ) {
      isAllowed = true
    }
    // JobPlanning is not completed but the user can't complete it
    // isAllowed remains false
  } else {
    if (jobPlanning.actually_completed === currentUser.username) {
      // Could be un-completed
      isAllowed = true
    }
    // JobPlanning has been completed by the other user - can't un-complete
    // isAllowed remains false
  }

  const performRequest = () => mutation.mutate(isCompleted ? 'undo' : 'complete')

  return { performRequest, isLoading, isAllowed, isCompleted }
}
