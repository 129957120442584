import React from 'react'
import PropTypes from 'prop-types'
import { minutesToTime } from '../helpers/time'
import RegisteredTimeIndicator from './RegisteredTimeIndicator'
import CommentButton from './CommentButton'

function UserRow({ range, userData, user, isHolidayOrWeekend, showNormTime }) {
  const cellClasses = date => {
    const cssClasses = []
    if (isHolidayOrWeekend(date)) {
      cssClasses.push('resources-overview-holiday')
    }
    if (date.date() === 16) {
      cssClasses.push('resources-overview-middle')
    }
    if (date.date() === 1) {
      cssClasses.push('resources-overview-beginning')
    }

    return cssClasses
  }
  return (
    <tr className="resources-overview-user-row">
      <td className="resources-overview-username">
        <a href={`/?pretend_to_be=${user.id}`} target="_blank">{user.name}</a>
      </td>
      {range.map(date => {
        const key = date.format('YYYY-MM-DD')
        const availableTime = showNormTime ? userData[key].norm : userData[key].available_time
        const dotTitle =
          `Normal: ${minutesToTime(userData[key].norm)}` +
          `\r\nRegistreret: ${minutesToTime(userData[key].registered)}` +
          `\r\nPlanlagt fravær: ${minutesToTime(userData[key].absence)}`
        const timeTitle =
          `Normal: ${minutesToTime(userData[key].norm)}` +
          `\r\nPlanlagt: ${minutesToTime(userData[key].planned)}` +
          `\r\nPlanlagt fravær: ${minutesToTime(userData[key].absence)}` +
          `\r\nRegistreret: ${minutesToTime(userData[key].registered)}`

        const innerCellClasses = cellClasses(date)
          .filter(c => c === 'resources-overview-holiday') // do not need -middle and -beginning classes
          .join(' ')

        return (
          <React.Fragment key={key}>
            <td className={cellClasses(date).join(' ')}>{userData[key].absence_code}</td>
            <td className={innerCellClasses} title={dotTitle}>
              {!isHolidayOrWeekend && <RegisteredTimeIndicator date={date} userData={userData[key]} />}
            </td>
            <td className={innerCellClasses} title={timeTitle}>
              {minutesToTime(availableTime)}
              <CommentButton userData={userData[key]} user={user} date={date} />
            </td>
          </React.Fragment>
        )
      })}
    </tr>
  )
}

UserRow.propTypes = {
  range: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  isHolidayOrWeekend: PropTypes.func.isRequired,
}

export default UserRow
