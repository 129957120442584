import * as React from 'react'
import { useState } from 'react'
import SortableSelect, { arrayMove, SortableMultiValue } from '../sortable_select/SortableSelect'

function ReactSortableSelectInput(props) {
  const { className, value, name, options } = props

  const [selectedIds, setSelectedIds] = useState(value.map(v => v.value))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedIds, oldIndex, newIndex)
    setSelectedIds(newValue)
  }

  return (
    <div className={`${className} react-sortable-select`}>
      <SortableSelect
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        placeholder="Vælg eller skriv..."
        value={selectedIds.map(id => options.find(u => u.value === id))}
        onChange={opts => setSelectedIds(opts === null ? [] : opts.map(o => o.value))}
        options={options}
        components={{
          MultiValue: SortableMultiValue,
        }}
        closeMenuOnSelect={false}
      />
      {selectedIds.map(id => {
        return <input key={id} type="hidden" name={`${name}[]`} value={id} />
      })}
      {selectedIds.length === 0 && <input type="hidden" name={`${name}[]`} />}
    </div>
  )
}

export default ReactSortableSelectInput
