import React, { useState, useContext } from 'react'
import Card from './Card'
import { dateSummary } from './helpers'

function CardsView({ isLoading, jobPlanningLines }) {
  return (
    <div className="job-plannings-card-view">
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      {!isLoading &&
        Object.keys(jobPlanningLines).map(date => {
          return <JobPlanningsGroupDecks key={date} date={date} jobPlannings={jobPlanningLines[date]} />
        })}
    </div>
  )
}

function JobPlanningsGroupDecks({ date, jobPlannings }) {
  return (
    <>
      <div className="job-planning-date-col bg-light border-top mb-2 mt-4 p-2 pl-3">{dateSummary(date, jobPlannings)}</div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
        {jobPlannings.map(jobPlanning => {
          return (
            <div key={jobPlanning.key} className="col mb-4">
              <Card jobPlanning={jobPlanning} className="h-100" />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CardsView
