import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { loadJobnumberOptions } from '../../api/jobnumbers'

function JobnumberPicker({ jobnumber, setJobnumber, onJobnumberChange, hideCustomerTitle }) {
  const handleChange = function(e) {
    const jn = e ? e.jobnumber : null
    setJobnumber(jn)
    if (onJobnumberChange) {
      onJobnumberChange(jn)
    }
  }
  const customerTitle = (jobnumber && jobnumber.customer_title) || ''
  const defaultValue = jobnumber ? { value: jobnumber.id, label: jobnumber.full_title, jobnumber } : ''
  const groupClassName = hideCustomerTitle ? 'col-sm-12 form-group' : 'col-sm-6 form-group'

  return (
    <div className="row">
      <div className={groupClassName}>
        <label>Ordrenummer, Sags-ID eller beskrivelse</label>
        <AsyncSelect
          id="record-jobnumber-select"
          cacheOptions
          placeholder="Vælg eller skriv..."
          loadOptions={loadJobnumberOptions}
          defaultOptions
          isClearable
          defaultValue={defaultValue}
          onChange={e => handleChange(e)}
          required
        />
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, position: 'absolute' }}
          defaultValue={defaultValue}
          required
        />
      </div>
      {!hideCustomerTitle && (
        <div className={groupClassName}>
          <label>Kunde</label>
          <input className="form-control record-jobnumber-company-name" disabled value={customerTitle} />
        </div>
      )}
    </div>
  )
}

JobnumberPicker.propTypes = {
  jobnumber: PropTypes.shape({
    title: PropTypes.string,
    full_title: PropTypes.string,
    customer_title: PropTypes.string,
  }),
  setJobnumber: PropTypes.func.isRequired,
  hideCustomerTitle: PropTypes.bool,
  onJobnumberChange: PropTypes.func,
}

JobnumberPicker.defaultProps = {
  hideCustomerTitle: false,
}

export default JobnumberPicker
