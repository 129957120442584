import NewJobPlanningButton from './NewJobPlanningButton'
import ViewSwitcher from '../../shared/ViewSwitcher'
import React from 'react'

export default function JobPlanningTableHeadActions(props) {
  const { isError, currentUser, currentView, setCurrentView, showCompletedToday, setShowCompletedToday } = props
  if (isError) {
    return null
  }

  return (
    <div className="row text-right">
      <div className="col-12 job-plannings-top-actions d-print-none">
        <div className="job-plannings-top-actions-right">
          <NewJobPlanningButton label="Tilføj stadie" jobPlanning={{ user_id: currentUser.id }} />
        </div>
        <div className="job-plannings-top-actions-left">
          <ViewSwitcher currentView={currentView} setCurrentView={setCurrentView} />
          <label className="mb-0 ml-2">
            <input type="checkbox" checked={showCompletedToday} onChange={e => setShowCompletedToday(e.target.checked)} />
            Vis sager afsluttet d.d.
          </label>
        </div>
      </div>
    </div>
  )
}
