import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'
import { updateApprovalFormsAnswer } from '../../api/approval_forms_answers'

function ApprovalFormsRow({ approvalFormAnswer }) {
  const [renaming, setRenaming] = useState(false)
  const [name, setName] = useState(approvalFormAnswer.name)

  const queryClient = useQueryClient()
  const reloadAnswers = () => {
    queryClient.invalidateQueries('approval_forms_table')
  }
  const mutation = useMutation(
    answer => {
      updateApprovalFormsAnswer({ id: answer.id, approval_forms_answer: answer })
    },
    {
      onSuccess: async () => {
        reloadAnswers()
        setRenaming(false)
      },
    }
  )

  return (
    <tr key={approvalFormAnswer.id}>
      {!renaming && (
        <>
          <td className="align-middle">
            <a
              className="text-nowrap"
              href={`/approval_forms/answers/${approvalFormAnswer.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-icon fa-external-link-alt mr-2" />
              {approvalFormAnswer.name}
            </a>
          </td>
          <td className="text-right">
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              onClick={() => {
                setRenaming(true)
              }}
            >
              Rename
            </button>
          </td>
        </>
      )}
      {renaming && (
        <td colSpan="2">
          {mutation.isLoading && <i className="fa fa-spinner fa-spin" />}
          {!mutation.isLoading && (
            <div className="d-flex">
              <input
                type="text"
                className="form-control flex-grow-1"
                name="approval_forms[name]"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-outline-primary ml-1"
                onClick={() => {
                  mutation.mutate({ id: approvalFormAnswer.id, name })
                }}
              >
                Save
              </button>
              <button type="button" className="btn btn-outline-secondary ml-1" onClick={() => setRenaming(false)}>
                Luk
              </button>
            </div>
          )}
        </td>
      )}
    </tr>
  )
}

ApprovalFormsRow.propTypes = {
  approvalFormAnswer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    template: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ApprovalFormsRow
