import * as React from 'react'

type SavedFiltersProps = {
  savedFiltersController: any
  closeModal: () => void
}
export default function SavedFilters({ savedFiltersController, closeModal }: SavedFiltersProps) {
  const {
    isError,
    errorMessage,
    saveAndApply,
    deleteFilter,
    changeCurrentSavedFilter,
    addNewSavedFilter,
    savedFilters,
    currentSavedFilter,
    setCurrentSavedFilter,
  } = savedFiltersController

  const currentSavedFilterId = currentSavedFilter ? currentSavedFilter.id : ''
  return (
    <div className="row">
      <div className="d-flex col-12 justify-content-lg-start justify-content-between">
        <div className="">
          <label htmlFor="job-plannings-filter-saved-filters-name">Gemte filtre</label>
        </div>
        <div className="ml-2">
          <select className="form-control form-control-sm" value={currentSavedFilterId} onChange={changeCurrentSavedFilter}>
            <option value="">{'- Vælg gemt filter -'}</option>
            {savedFilters
              .sort(f => (f.default ? -1 : 1))
              .map(savedFilter => {
                return (
                  <option key={savedFilter.id} value={savedFilter.id}>
                    {savedFilter.name}
                  </option>
                )
              })}
          </select>
        </div>
        <div className="ml-2">
          <button className="btn btn-outline-primary btn-sm text-nowrap" onClick={addNewSavedFilter}>
            + Nyt filter
          </button>
        </div>
      </div>
      {currentSavedFilter && (
        <div className="col-12 mt-4">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex mb-3">
              <input
                id="job-plannings-filter-saved-filters-name"
                className="form-control w-auto flex-grow-1"
                type="text"
                value={currentSavedFilter.name}
                onChange={e => setCurrentSavedFilter({ ...currentSavedFilter, name: e.target.value })}
              />
              <label className="ml-2 mt-2 mr-4">
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={e => {
                    setCurrentSavedFilter({ ...currentSavedFilter, default: e.target.checked })
                  }}
                  checked={currentSavedFilter.default}
                />
                standard
              </label>
            </div>
            <div className="d-flex justify-content-end mb-3 flex-grow-1">
              <button type="button" className="btn btn-outline-danger ml-2" onClick={deleteFilter}>
                Slet
              </button>
              <button type="button" className="btn btn-outline-primary ml-2" onClick={() => saveAndApply(closeModal)}>
                Gem og anvend
              </button>
            </div>
          </div>
        </div>
      )}
      {isError && <div className="col text-center error-message">{errorMessage}</div>}
    </div>
  )
}
