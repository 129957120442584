import * as React from 'react'
import ChangeFilterButton from './ChangeFilterButton'
import { useContext } from 'react'
import ProductionPlanningDictionariesContext from './ProductionPlanningDictionariesContext'
import useSavedFiltersController from '../../hooks/useSavedFiltersController'

type FilterProps = {
  filter: any //ProductionPlanningFilterShape
  setFilter: (filter: any) => void
  showCompletedToday: boolean
  setShowCompletedToday: (showCompletedToday: boolean) => void
}

export default function Filter(props: FilterProps) {
  const { filter, setFilter, showCompletedToday, setShowCompletedToday } = props
  const { area, start_date, end_date, production_place, status_code } = filter
  const { currentUser, updateCurrentUser } = useContext(ProductionPlanningDictionariesContext)
  const savedFilters = currentUser.saved_production_filters

  const savedFiltersController = useSavedFiltersController({
    filter,
    setFilter,
    currentUser,
    updateCurrentUser,
    savedFiltersKey: 'saved_production_filters',
    savedFilterStorageKey: 'currentSavedProductionFilter',
  })

  const { setFormFilter, setCurrentSavedFilter } = savedFiltersController

  let filterString = `Afdeling: ${area}`
  if (production_place) {
    filterString += `; Produktionssted: ${production_place === 'east' ? 'Øst' : 'Vest'}`
  }
  if (status_code) {
    filterString += `; Status: ${status_code}`
  }
  if (start_date) {
    filterString += `; Dato fra: ${start_date}`
  }
  if (end_date) {
    filterString += `; Dato til: ${end_date}`
  }
  return (
    <div className="mb-3">
      <div className="card p-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="mr-5">
              <span className="font-size-1-5">&nbsp;</span>
              {filterString}
            </div>
          </div>
          <div className="d-print-none">
            <div className="btn-group">
              <ChangeFilterButton savedFiltersController={savedFiltersController} />
              <button
                type="button"
                className="btn btn-outline-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="caret" />
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {savedFilters &&
                  savedFilters.length > 0 &&
                  savedFilters
                    .sort(f => (f.default ? -1 : 1))
                    .map(savedFilter => {
                      return (
                        <a
                          key={savedFilter.id}
                          className="dropdown-item"
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            setCurrentSavedFilter(savedFilter)
                            setFilter(savedFilter.filter)
                          }}
                        >
                          {savedFilter.default ? 'Nulstil til standard filter' : savedFilter.name}
                        </a>
                      )
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <label className="ml-2">
          <input className="mr-2" type="checkbox" checked={showCompletedToday} onChange={e => setShowCompletedToday(e.target.checked)} />
          Vis afsluttede sager d.d.
        </label>
      </div>
    </div>
  )
}
