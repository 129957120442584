import * as React from 'react'
import ScanForm from './ScanForm'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'

type ScanAppProps = {
  availableAreas: string[]
}

export default function ScanApp(props: ScanAppProps) {
  const queryClient = buildQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <div className="pt-3">
        <ScanForm {...props} />
      </div>
    </QueryClientProvider>
  )
}
