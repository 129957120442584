import { QueryClientProvider } from 'react-query'
import { DepartmentShape, EmailTemplateShape } from '../../../api/api_data_types'
import buildQueryClient from '../../helpers/quieryClient'
import SendReminderButton from './SendReminderButton'

interface SendReminderAppProps {
  department: DepartmentShape
  week: number
  year: number
  templates: EmailTemplateShape[]
}

export default function SendReminderApp(props: SendReminderAppProps) {
  const queryClient = buildQueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <SendReminderButton {...props} />
    </QueryClientProvider>
  )
}
