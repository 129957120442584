import React from 'react'
import PropTypes from 'prop-types'

function MinutesPicker({ minutes, setMinutes, name }) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">M</span>
      </div>
      <select
        name={name}
        className="form-control resources-time-minutes"
        value={minutes}
        onChange={e => setMinutes(parseInt(e.target.value, 10))}
      >
        {[0, 5, 15, 30, 45].map(val => {
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </select>
    </div>
  )
}

MinutesPicker.propTypes = {
  minutes: PropTypes.number.isRequired,
  setMinutes: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}

export default MinutesPicker
