import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { useMutation } from 'react-query'
import { useStorageState } from 'react-storage-hooks'
import DictionariesContext from '../DictionariesContext'
import { changeJobnumberStatus } from '../../api/jobnumbers'

function EditStatusButton({ jobId, statusCode, setStatusCode }) {
  const dictionaries = useContext(DictionariesContext)
  const { currentUser } = dictionaries
  const availableStatuses = currentUser.combined_available_status_codes
  const [selectedId, setSelectedId] = useState(availableStatuses[0] && availableStatuses[0].id)

  const mutation = useMutation(statusId => changeJobnumberStatus({ job_id: jobId, status_id: statusId }))
  let errorMessage = ''
  if (mutation.isError) {
    const errorResponse = mutation.error.response
    if (errorResponse && errorResponse.data) {
      errorMessage = errorResponse.data.error
    } else {
      errorMessage = mutation.error.message
    }
  }
  let trigger = null
  if (mutation.isLoading) {
    trigger = <i className="fa fa-spinner fa-spin" />
  } else {
    trigger = (
      <a className="job-plannings-edit-status-link badge badge-secondary" type="button" href="#">
        {statusCode}
      </a>
    )
  }
  if (availableStatuses.length === 0) {
    return <span className="job-plannings-edit-status-link badge badge-secondary">{statusCode}</span>
  }
  const saveStatusHandler = closeWindow => {
    mutation.mutate(selectedId, {
      onSuccess: async response => {
        const newStatus = response.data.data.status
        setStatusCode(newStatus)
        closeWindow()
      },
    })
  }
  return (
    <div className="d-inline job-plannings-edit-status-button">
      <Popup
        trigger={trigger}
        position="bottom center"
        contentStyle={{ width: 'auto', minWidth: '300px', maxWidth: '100%' }}
        closeOnDocumentClick
        modal
        nested
      >
        {close => (
          <div className="modal-popup popup job-plannings-edit-status-popup">
            <div className="popup-header">Change order status</div>
            <div className="popup-content">
              {mutation.isLoading && <i className="fa fa-spinner fa-spin" />}
              {!mutation.isLoading && mutation.isError && <span className="job-plannings-table-error">{errorMessage}</span>}
              {!mutation.isLoading && !mutation.isError && (
                <select
                  className="input-field form-control job-plannings-edit-status-select"
                  name="status_code[code]"
                  value={selectedId}
                  onChange={e => setSelectedId(e.target.value)}
                >
                  {availableStatuses.map(s => {
                    return (
                      <option key={s.id} value={s.id}>
                        {s.code}
                      </option>
                    )
                  })}
                </select>
              )}
            </div>
            <div className="actions mt-3 d-flex justify-content-between">
              <button type="button" className="btn btn-outline-secondary" onClick={close}>
                Cancel
              </button>
              {!mutation.isError && !mutation.isLoading && (
                <button type="button" className="btn btn-primary" onClick={() => saveStatusHandler(close)}>
                  Save
                </button>
              )}
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
}

EditStatusButton.propTypes = {
  jobId: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  setStatusCode: PropTypes.func.isRequired,
}

export default EditStatusButton
