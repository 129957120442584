import * as React from 'react'
import { usePullShipmentsMutation } from '../../hooks/queries/shipments'

type PullShipmentsButtonProps = {
  jobnumberId: number
  setShipments: (shipments: any) => void
}

export default function PullShipmentsButton(props: PullShipmentsButtonProps) {
  const { jobnumberId, setShipments } = props

  const pullShipmentsMutation = usePullShipmentsMutation()
  const loadShipments = () => {
    pullShipmentsMutation.mutate(jobnumberId, {
      onSuccess: data => {
        setShipments(data)
      },
    })
  }

  return (
    <button className="btn btn-outline-primary btn-sm" type="button" disabled={!jobnumberId} onClick={loadShipments}>
      <i className="fa fa-download mr-2" /> Hent
    </button>
  )
}
