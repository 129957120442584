import React from 'react'

export default function DateSelect({ value, onChange, id, label }) {
  return (
    <div className="form-group col col-lg-4">
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type="date"
        className="form-control"
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
