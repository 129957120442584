import React from 'react'

export default function PlaceOfProductionSelect({ filter, setFilter }) {
  const placeOfProduction = filter.place_of_production || ''

  return (
    <div className="job-plannings-filter-place-of-production form-group col-12 col-lg-4">
      <label htmlFor="job-plannings-filter-place-of-production">ØST/VEST</label>
      <select
        id="job-plannings-filter-place-of-production"
        className="form-control"
        value={placeOfProduction}
        onChange={e => setFilter({ ...filter, place_of_production: e.target.value })}
      >
        <option></option>
        <option value="ØST">ØST</option>
        <option value="VEST">VEST</option>
      </select>
    </div>
  )
}
