import * as PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { loadOrderNumberOptions } from '../../api/jobnumbers'

function OrderOption(props) {
  const { innerRef, innerProps, isDisabled, isFocused, data } = props
  const { jobnumber } = data
  if (isDisabled) {
    return null
  }

  return (
    <tr ref={innerRef} {...innerProps} className={isFocused ? 'table-active option' : 'option'}>
      <td>{jobnumber.job_id}</td>
      <td>{jobnumber.quote_number}</td>
      <td>{jobnumber.order_number}</td>
      <td>{jobnumber.title}</td>
      <td>{jobnumber.customer_title}</td>
    </tr>
  )
}

function OrderMenuWrapper(props) {
  return (
    <components.MenuList {...props}>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Tilbudsnr.</th>
            <th>Ordrenr.</th>
            <th>Jobnavn</th>
            <th>Kundenavn</th>
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </components.MenuList>
  )
}

function OrderLoadingMessage(props) {
  return (
    <tr {...props.innerProps}>
      <td colSpan={5}>{props.children}</td>
    </tr>
  )
}

function OrderNoOptionMessage(props) {
  return (
    <tr {...props.innerProps}>
      <td colSpan={5}>{props.children}</td>
    </tr>
  )
}

function InputWithCopyButton({ value }) {
  return(
    <div className="input-group mr-3">
      <input type="text" className="ml-2 form-control form-control-sm" readOnly value={value} />
      <div className="input-group-append">
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={(e) => navigator.clipboard.writeText(value)}
        >
          <i className="far fa-copy" />
        </button>
      </div>
    </div>
  )
}

function OrderNumberPicker({ orderNumber, setOrderNumber, hideCustomerTitle }) {
  const handleChange = function(e) {
    const jn = e ? e.jobnumber : null
    setOrderNumber(jn)
  }
  const customerTitle = (orderNumber && orderNumber.customer_title) || ''
  const defaultValue = orderNumber ? { value: orderNumber.id, label: orderNumber.order_number_title, orderNumber } : ''
  const groupClassName = 'col-sm-12 form-group'
  return (
    <div className="row">
      <div className={groupClassName}>
        <label>Ordrenummer, Sags-ID eller beskrivelse</label>
        <AsyncSelect
          components={{
            Option: OrderOption,
            MenuList: OrderMenuWrapper,
            LoadingMessage: OrderLoadingMessage,
            NoOptionsMessage: OrderNoOptionMessage,
          }}
          id="record-jobnumber-select"
          cacheOptions
          placeholder="Vælg eller skriv..."
          loadOptions={loadOrderNumberOptions}
          defaultOptions
          isClearable
          defaultValue={defaultValue}
          onChange={e => handleChange(e)}
          styles={{ menu: base => ({ ...base, zIndex: 1100 }) }} // more than sticky headers have
          required
        />
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, position: 'absolute' }}
          defaultValue={defaultValue ? defaultValue.value : ''}
          required
        />
      </div>
      {!hideCustomerTitle && orderNumber && (
        <div className="col-sm-12 form-group form-inline">
          <label>ID: </label>
          <InputWithCopyButton value={orderNumber.job_id} />
          <span>Ordrenummer: </span>
          <InputWithCopyButton value={orderNumber.order_number} />
        </div>
      )}
      {!hideCustomerTitle && (
        <div className={groupClassName}>
          <label>Kunde</label>
          <input className="form-control record-jobnumber-company-name" disabled value={customerTitle} />
        </div>
      )}
    </div>
  )
}

OrderNumberPicker.propTypes = {
  orderNumber: PropTypes.shape({
    title: PropTypes.string,
    full_title: PropTypes.string,
    customer_title: PropTypes.string,
  }),
  setOrderNumber: PropTypes.func.isRequired,
  hideCustomerTitle: PropTypes.bool,
}

OrderNumberPicker.defaultProps = {
  hideCustomerTitle: false,
  orderNumber: null,
}

export default OrderNumberPicker
