import { useMutation } from 'react-query'
import uuid from 'uuid/v4'
import { SavedFilterType } from '../components/DictionariesContext'
import { saveUserFilters } from '../api/users'
import { useEffect, useState } from 'react'
import {useStorageState} from "react-storage-hooks";

// filter - a filter used to query the data
// currentSavedFilter - a filter that is currently selected, it has an id, name, and filter fields that are equal to the filter
// formFilter - a filter that is currently being edited

type UseJobPlanningsSavedFiltersControllerProps = {
  filter,
  setFilter,
  currentUser: any
  savedFiltersKey: string
  savedFilterStorageKey: string
  updateCurrentUser: (currentUser: any) => void
}
export type SavedFiltersControllerType = {
  isError: boolean
  errorMessage: string
  savedFilters: SavedFilterType[]
  saveAndApply: (onSuccessCallback: () => void) => void
  deleteFilter: () => void
  changeCurrentSavedFilter: (e: any) => void
  addNewSavedFilter: () => void
  filter: any
  setFilter: (filter: any) => void
  currentSavedFilter: SavedFilterType
  setCurrentSavedFilter: (filter: SavedFilterType) => void
  formFilter: any
  setFormFilter: (filter: any) => void
}
export default function useSavedFiltersController(props: UseJobPlanningsSavedFiltersControllerProps): SavedFiltersControllerType {
  const { currentUser, savedFiltersKey, updateCurrentUser, savedFilterStorageKey, filter, setFilter } = props

  const [currentSavedFilter, setCurrentSavedFilter] = useStorageState<SavedFilterType>(sessionStorage, savedFilterStorageKey)
  const [formFilter, setFormFilter] = useState(currentSavedFilter?.filter || filter)
  useEffect(() => {
    setFormFilter(currentSavedFilter?.filter || filter)
  }, [currentSavedFilter])

  const savedFilters = currentUser[savedFiltersKey] || []
  const mutation = useMutation({ mutationFn: (f: SavedFilterType[]) => saveUserFilters({ [savedFiltersKey]: JSON.stringify(f) }) })

  const saveAndApply = (onSuccessCallback: () => void) => {
    let newFilters = [...savedFilters]
    let newFilter = { ...currentSavedFilter, filter: formFilter }

    if (currentSavedFilter && currentSavedFilter.id) {
      if (newFilters.find(f => f.id === currentSavedFilter.id)) {
        newFilters = newFilters.map(f => (f.id === currentSavedFilter.id ? newFilter : f))
      } else {
        newFilters.push(newFilter)
      }

      if (currentSavedFilter.default) {
        newFilters = newFilters.map(f => (f.id === currentSavedFilter.id ? f : { ...f, default: false }))
      }
    }
    mutation.mutate(newFilters, {
      onSuccess: async (data: SavedFilterType[]) => {
        updateCurrentUser({ ...currentUser, [savedFiltersKey]: data })
        setCurrentSavedFilter(newFilter)
        setFilter(formFilter)
        onSuccessCallback()
      },
    })
  }

  const deleteFilter = () => {
    const newFilters = savedFilters.filter(f => f.id !== currentSavedFilter.id)
    mutation.mutate(newFilters, {
      onSuccess: async (data: SavedFilterType[]) => {
        updateCurrentUser({ ...currentUser, [savedFiltersKey]: data })
        setCurrentSavedFilter(null)
        setFilter(formFilter)
      },
    })
  }

  const changeCurrentSavedFilter = e => {
    const value = e.target.value
    if (value === '') {
      setCurrentSavedFilter(null)
      return
    }
    const newCurrentSavedFilter = savedFilters.find(f => f.id === value)
    setCurrentSavedFilter(newCurrentSavedFilter)
    setFilter(newCurrentSavedFilter.filter)
  }

  const addNewSavedFilter = () => {
    let newFilter = {
      id: uuid(),
      name: 'Nyt filter',
      filter: formFilter,
      default: false,
    }
    setCurrentSavedFilter(newFilter)
  }

  return {
    isError: mutation.isError,
    errorMessage: mutation.isError && mutation.error instanceof Error ? mutation.error.message : '',
    savedFilters,
    saveAndApply,
    deleteFilter,
    changeCurrentSavedFilter,
    addNewSavedFilter,
    currentSavedFilter,
    setCurrentSavedFilter,
    formFilter,
    setFormFilter,
    filter,
    setFilter
  }
}
