import axios from 'axios'
import { save_filters_users_path } from "../routes"

export function saveUserFilters(payload = {}) {
  const options = {
    url: save_filters_users_path({format: 'json'}),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export default { saveUserFilters }
