import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

function WorkcardsPicker({ workcardIds, setWorkcardIds, name, availableWorkcards }) {
  const options = availableWorkcards.map(card => {
    return { value: card[1], label: card[0] }
  })
  const selected = options.filter(option => workcardIds.includes(option.value))
  return (
    <div className="row">
      <div className="col-sm-12 form-group">
        <label>Arbejdskort og parkering</label>
        <Select
          id="record-workcard-select"
          placeholder="Vælg eller skriv..."
          isMulti
          name={name}
          value={selected}
          onChange={opts => setWorkcardIds(opts.map(o => o.value))}
          options={options}
        />
      </div>
    </div>
  )
}

WorkcardsPicker.propTypes = {
  workcardIds: PropTypes.array.isRequired,
  setWorkcardIds: PropTypes.func.isRequired,
  availableWorkcards: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
}

export default WorkcardsPicker
