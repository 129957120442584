import axios, {AxiosRequestConfig} from 'axios'
import {
  api_job_plannings_path,
  complete_popup_job_plannings_path,
  complete_api_job_planning_path,
  undo_complete_api_job_planning_path, fetch_by_job_number_api_job_plannings_path,
} from '../routes'

export function getJobPlanningLines(payload = {}) {
  const options: AxiosRequestConfig = {
    url: api_job_plannings_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function getJobPlanningLinesByJobNumber(jobNumber) {
  const options: AxiosRequestConfig = {
    url: fetch_by_job_number_api_job_plannings_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: { job_number: jobNumber },
  }
  return axios(options).then(response => response.data)
}

export function getCompletePopupJobPlanningLines(payload = {}) {
  const options: AxiosRequestConfig = {
    url: complete_popup_job_plannings_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function completeJobPlanningLine(key, direction) {
  const urlFn = direction === 'complete' ? complete_api_job_planning_path : undo_complete_api_job_planning_path

  const options: AxiosRequestConfig = {
    url: urlFn(key, { format: 'json' }),
    method: 'put',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}
