import { useState } from 'react'
import * as PropTypes from 'prop-types'
import OrderNumberPicker from '../resources_records/OrderNumberPicker'
import DependentTaskPicker from '../resources_records/DependentTaskPicker'
import RangePickerButton from '../shared/RangePickerButton'
import { format } from 'date-fns'
import { da } from 'date-fns/locale'
import { DateRange } from 'react-day-picker'

function FormFields({ rangeEntry, availableWorktypes, submitting, onSubmit }) {
  const record = rangeEntry.resources_record
  const worktypeId = null
  const comment = ''
  const [jobnumber, setJobnumber] = useState(record.jobnumber)
  const [taskId, setTaskId] = useState(record.task_id)
  const [range, setRange] = useState<DateRange>({ from: record.start_date, to: record.end_date })
  const jobnumberId = (jobnumber && jobnumber.id) || 0

  const namePrefix = 'range_entry[resources_records_attributes]'
  const addNamePrefix = name => `${namePrefix}[${name}]`

  const from = range ? range.from : undefined
  const to = range ? range.to : undefined

  const formattedFrom = from ? format(from, 'eeee, PPP', { locale: da }) : '...'
  const formattedTo = to ? format(to, 'eeee, PPP', { locale: da }) : '...'
  const rangePickerLabel = to === undefined && from === undefined ? 'Vælg periode' : `${formattedFrom} - ${formattedTo}`
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="date">Periode</label>
            <RangePickerButton buttonLabel={rangePickerLabel} buttonClass="btn btn-outline-secondary w-100" range={range} setRange={setRange} />
            <input
              tabIndex={-1}
              autoComplete="off"
              name='range_entry[start_date]'
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              defaultValue={from ? format(from, 'yyyy-MM-dd') : ''}
              required
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              name='range_entry[end_date]'
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              defaultValue={to ? format(to, 'yyyy-MM-dd') : ''}
              required
            />
            {/*<input type="hidden" required name={addNamePrefix('start_date')} value={from ? format(from, 'yyyy-MM-dd') : ''} />*/}
            {/*<input type="hidden" required name={addNamePrefix('end_date')} value={to ? format(to, 'yyyy-MM-dd') : ''} />*/}
          </div>
        </div>
      </div>

      <OrderNumberPicker orderNumber={jobnumber} setOrderNumber={setJobnumber} />
      <input type="hidden" name={addNamePrefix('jobnumber_id')} value={jobnumberId} />
      <div className="row">
        <div className="col-sm-12">
          <DependentTaskPicker jobnumber={jobnumber} name={addNamePrefix('task_id')} taskId={taskId} setTaskId={setTaskId} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor="worktype_id">Arbejdstype</label>
          <select
            id="worktype_id"
            name={addNamePrefix('times_attributes][0][worktype_id')}
            className="form-control resources-time-worktype"
            defaultValue={worktypeId}
            required
          >
            <option />
            {availableWorktypes.map(worktype => {
              const [title, id] = worktype
              return (
                <option key={id} value={id}>
                  {title}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-sm-12 form-group">
          <label htmlFor="comment">Beskrivelse</label>
          <input
            id="comment"
            name={addNamePrefix('times_attributes][0][comment')}
            className="form-control resources-time-comment"
            defaultValue={comment}
          />
        </div>
      </div>
    </>
  )
}

FormFields.propTypes = {
  availableWorktypes: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  record: PropTypes.shape({
    jobnumber_id: PropTypes.object,
    workcard_ids: PropTypes.array,
    partner_ids: PropTypes.array,
    jobnumber: PropTypes.object,
    times: PropTypes.array,
    machines: PropTypes.array,
    is_master: PropTypes.bool,
  }).isRequired,
}

export default FormFields
