import React, { useContext } from 'react'
import Select from 'react-select'
import { useStorageState } from 'react-storage-hooks'
import { find } from 'lodash'
import DictionariesContext from '../../DictionariesContext'

function ResourceSelect({ filter, setFilter }) {
  const [showAllUsers, setShowAllUsers] = useStorageState(sessionStorage, 'filter-show-all-users', false)
  const [includeMachines, setIncludeMachines] = useStorageState(sessionStorage, 'filter-include-machines', false)
  const { no } = filter
  const dictionaries = useContext(DictionariesContext)
  const { currentUser, availableUsers, availablePartners, availableMachines } = dictionaries
  let users = showAllUsers ? availableUsers : [currentUser, ...availablePartners]

  if (includeMachines) {
    users = [...users, ...availableMachines]
  }

  const valueForNumberOption = u => (Object.prototype.hasOwnProperty.call(u, 'username') ? u.username : u.no)
  const toNumberOption = u => {
    const value = valueForNumberOption(u)
    const label = [value, u.name].join(' - ')
    return { value, label }
  }

  let numberOptions = users.map(toNumberOption)
  // users array may not contain options from saved filter - need to append missed,
  // so they're always visible as selected
  if (no.length > 0) {
    const numberValues = numberOptions.map(o => o.value)
    no.forEach(n => {
      if (!numberValues.includes(n)) {
        let label = ''
        // try to find in availableUsers, then in availableMachines
        const user = find(availableUsers, u => u.username === n)
        if (user) {
          label = [n, user.name].join(' - ')
        } else {
          const machine = find(availableMachines, m => m.no === n)
          if (machine) {
            label = [n, machine.name].join(' - ')
          } else {
            // (unlikely case) not found neither in availableUsers nor in
            // availableMachines - leave only value as a label
            label = n
          }
        }
        numberOptions = [{ value: n, label }, ...numberOptions]
      }
    })
  }

  return (
    <div className="form-group">
      <label htmlFor="job-plannings-filter-number">Resource</label>
      <div className="form-check job-plannings-filter-show-all">
        <label>
          <input type="checkbox" checked={showAllUsers} onChange={e => setShowAllUsers(e.target.checked)} />
          <span className="record-partners-select-show-all">Vis alle medarbejdere</span>
        </label>
      </div>
      <div className="form-check job-plannings-filter-include-machines">
        <label>
          <input type="checkbox" checked={includeMachines} onChange={e => setIncludeMachines(e.target.checked)} />
          <span className="record-partners-select-show-all">Vis Maskiner</span>
        </label>
      </div>
      <Select
        id="job-plannings-filter-number"
        placeholder="Vælg eller skriv..."
        isMulti
        className="job-plannings-filter-number"
        value={no.map(n => numberOptions.find(o => o.value === n))}
        onChange={opts => {
          const options = opts === null ? [] : opts.map(o => o.value)
          return setFilter({ ...filter, no: options })
        }}
        options={numberOptions}
      />
    </div>
  )
}

export default ResourceSelect
