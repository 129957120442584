import { useEffect, useState } from 'react'
import { useCreateMessageMutation } from './queries/messages'

function subjectFromJobPlanning(jobPlanning) {
  if (!jobPlanning.jobnumber) {
    return `${jobPlanning.job_no} - ${jobPlanning.no}`
  }
  return `${jobPlanning.jobnumber.job_id} - ${jobPlanning.jobnumber.title} - ${jobPlanning.jobnumber.customer_title}`
}

function formFieldsToFormData(formFields) {
  const formData = new FormData()
  Object.keys(formFields).forEach(key => {
    if (key === 'attachments') {
      formFields.attachments.forEach((att, i) => {
        formData.append(`message[${key}][]`, att)
      })
    } else if (key === 'recipients') {
      formFields.recipients.forEach((recipient, i) => {
        formData.append(`message[${key}][]`, recipient)
      })
    } else {
      formData.append(`message[${key}]`, formFields[key])
    }
  })

  return formData
}

export default function useSendMessagesController(availableUsers, jobPlanning, onSuccessSend) {
  const users = availableUsers.map(u => {
    return { label: u.name, value: u.username }
  })

  const [formFields, setFormFields] = useState({
    subject: subjectFromJobPlanning(jobPlanning),
    jobnumber_id: jobPlanning.jobnumber.id,
    recipients: [],
    production_material_approved: false,
    attachments: [],
    body: '',
  })

  useEffect(() => {
    setFormFields({ ...formFields, subject: subjectFromJobPlanning(jobPlanning), jobnumber_id: jobPlanning.jobnumber.id })
  }, [jobPlanning])

  const sendMessageMutation = useCreateMessageMutation(formFieldsToFormData(formFields), onSuccessSend)

  return {
    formFields,
    setFormFields,
    sendMessageMutation,
    users,
  }
}
