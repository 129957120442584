import * as React from 'react'
import Popup from 'reactjs-popup'
import FeedbackModalContent from './FeedbackModalContent'

type FeedbackButtonProps = {}

function FeedbackButton({}: FeedbackButtonProps) {
  return (
    <>
      <Popup
        trigger={
          <button type="button" className="button feedback-button btn">
            Feedback
          </button>
        }
        position="right bottom"
        contentStyle={{ position: 'absolute', bottom: '3rem', right: '2rem', width: '100%', maxWidth: '320px', padding: 0, borderRadius: '0.7rem' }}
        modal
        closeOnDocumentClick={true}
      >
        {closeModal => (
          <div className="modal-popup">
            <a className="close feedback-close" onClick={closeModal}>
              &times;
            </a>

            <FeedbackModalContent closeModal={closeModal} />
          </div>
        )}
      </Popup>
    </>
  )
}

export default FeedbackButton
