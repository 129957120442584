import axios, { AxiosRequestConfig } from 'axios'
import {
  api_job_costing_line_path,
  api_job_costing_lines_path,
  update_quantities_api_job_costing_lines_path,
  refresh_api_job_costing_lines_path,
} from '../routes'

export function getJobCostingLines(jobId) {
  const options: AxiosRequestConfig = {
    url: api_job_costing_lines_path({ format: 'json', job_id: jobId }),
    method: 'get',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}

export function updateQuantitiesJobCostingLines(payload) {
  const options: AxiosRequestConfig = {
    url: update_quantities_api_job_costing_lines_path({ format: 'json' }),
    method: 'put',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function createJobCostingLine(payload) {
  const options: AxiosRequestConfig = {
    url: api_job_costing_lines_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function refreshJobCostingLines(jobId) {
  const options: AxiosRequestConfig = {
    url: refresh_api_job_costing_lines_path({ format: 'json', job_id: jobId }),
    method: 'post',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}
