import {useQuery, useQueryClient} from 'react-query'
import { getJobnumberStatus } from '../api/jobnumbers'

export default function useFetchOrderStatus(jobnumber) {
  const query = useQuery({
    queryKey: ['order-number-status', jobnumber ? jobnumber.job_id : ''],
    queryFn: () => {
      return getJobnumberStatus({ job_id: jobnumber.job_id })
    },
    enabled: !!(jobnumber && jobnumber.job_id),
  })
  const { data } = query

  const queryClient = useQueryClient()
  const statusCode = (data && data.data && data.data.data) ? data.data.data.status : ''
  const setStatusCode = newStatus => {
    queryClient.setQueryData(['order-number-status', jobnumber.job_id], statusResponse => {
      statusResponse.data.data.status = newStatus
      return statusResponse
    })
  }

  return {...query, statusCode, setStatusCode}
}
