import React from 'react'
import useConsumptionTableController from '../../hooks/useConsumptionTableController'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import ConsumptionLinesTable from '../job_plannings/consumption_lines_table/ConsumptionLinesTable'
import EditConsumptionLineButton from '../job_plannings/consumption_lines_table/EditConsumptionLineButton'
import SaveAllConsumptionLinesButton from '../job_plannings/consumption_lines_table/SaveAllConsumptionLinesButton'

export default function ConsumptionsTable({ jobnumber, currentUser }) {
  const { saveAllError, setSaveAllError, changedQuantities, setChangedQuantities } = useConsumptionTableController()
  return (
    <div>
      <div className="consumption-table-content">
        <ErrorMessage error={saveAllError} isError={saveAllError !== null} />
        <ConsumptionLinesTable jobnumber={jobnumber} changedQuantities={changedQuantities} setChangedQuantities={setChangedQuantities} />
      </div>
      <div className="consumption-table-actions d-flex justify-content-end">
        <EditConsumptionLineButton
          jobCostingLine={{ job_id: jobnumber.job_id, user_no: currentUser.username }}
          buttonClass="btn btn-outline-primary mr-2"
          buttonTitle="Tilføj"
          windowTitlePrefix="Tilføj"
        />
        <SaveAllConsumptionLinesButton changedQuantities={changedQuantities} setError={setSaveAllError} />
      </div>
    </div>
  )
}
