import React, { useEffect } from 'react'
import { useUpdateQuantitiesJobCostingLinesMutation } from '../../../hooks/queries/job_costing_lines'

export default function SaveAllConsumptionLinesButton({
  buttonTitle = 'Gem alle',
  buttonClass = 'btn btn-primary',
  changedQuantities,
  setError,
}) {
  const mutation = useUpdateQuantitiesJobCostingLinesMutation(changedQuantities)
  const { mutate, isLoading, isError, error } = mutation
  useEffect(() => {
    if (isError) {
      setError(error)
    }
  }, [error, isError])
  return (
    <button type="button" className={buttonClass} onClick={() => mutate()} disabled={isLoading}>
      {isLoading ? <i className="fa fa-spinner fa-spin" /> : buttonTitle}
    </button>
  )
}
