import React from 'react'

export default function DebouncedInput({ value: initialValue, onChange, debounce = 200, ...props }) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />
}
