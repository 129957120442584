import * as React from 'react'
import { useContext } from 'react'
import { format } from 'date-fns'
import { LightgalleryProvider } from 'react-lightgallery'

import { some } from 'lodash'
import { useQuery, useQueryClient } from 'react-query'
import { getMessages, downloadAttachments } from '../../api/messages'
import DownloadButton from './DownloadButton'
import MessageAttachment from './MessageAttachment'
import DictionariesContext from '../DictionariesContext'
import SendMessageButton from '../job_plannings/send_message_popup_button/SendMessageButton'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import {getJobPlanningLinesByJobNumber} from "../../api/job_planning_lines";
import ProjectButton from "../job_plannings/project_button/ProjectButton";

type MessagesTableProps = {
  jobnumber: {
    id: number
    jobnumber: string
  }
}
export default function MessagesTable({ jobnumber }: MessagesTableProps) {
  const { isLoading, data, error, isError } = useQuery(['messages', jobnumber.id], () => getMessages({ jobnumber_id: jobnumber.id }))
  const { isLoading: isJobPlaningLinesLoading, data: jobPlanningLineData } = useQuery(['job-planning-line-fetch-by-job-number', jobnumber.jobnumber], () =>
    getJobPlanningLinesByJobNumber( jobnumber.jobnumber )
  )
  const queryClient = useQueryClient()
  const jobPlanning = jobPlanningLineData && jobPlanningLineData.data ? jobPlanningLineData.data[0] : null
  const projectID = jobPlanning && jobPlanning.brandeasy_project_id
  const reloadMessages = () => {
    queryClient.invalidateQueries('messages')
  }
  const attachmentsPresented =
    data &&
    some(data, row => {
      return row.attachments.length > 0
    })
  const { currentUser } = useContext(DictionariesContext)

  return (
    <LightgalleryProvider>
      <div className="messages-tab">
        <div className="d-flex justify-content-end mb-2">
          {attachmentsPresented && (
            <div className="messages-download-attachments clearfix">
              <DownloadButton jobnumber={jobnumber} downloadAttachments={downloadAttachments} />
            </div>
          )}
          {jobnumber && (
            <div className="ml-2 messages-new-message">
              {isJobPlaningLinesLoading && (
                <button className="btn btn-outline-primary w-100" disabled>
                  <i className="fa fa-spinner fa-spin" />
                </button>
              )}
              {!isJobPlaningLinesLoading && projectID && (
                <ProjectButton jobPlanning={jobPlanning} buttonClass="btn btn-outline-secondary" buttonTitle="BrandEasy" />
              )}
              {!isJobPlaningLinesLoading && !projectID && (
                <SendMessageButton jobPlanning={{ jobnumber }} buttonClass="btn btn-outline-primary" onSuccessSend={reloadMessages} />
              )}
            </div>
          )}
        </div>
        {jobnumber && !isLoading && data.length === 0 && <div className="text-center">Ingen beskeder på denne ordre</div>}
        {data &&
          data.map(row => {
            return (
              <div className="card mb-3" key={row.id}>
                <div className="card-body">
                  <div className="card-text">{row.body}</div>
                  <div className="message-footer">
                    {row.user ? row.user.username + ', ' : ''}
                    {format(Date.parse(row.created_at), 'dd-MM-yyyy, HH:mm:ss')}
                  </div>
                </div>
                {row.attachments.length > 0 && (
                  <ul className="list-group list-group-flush">
                    {row.attachments.map(attachment => (
                      <MessageAttachment
                        key={attachment.id}
                        attachment={attachment}
                        messageId={row.id}
                        allowDelete={currentUser.id === row.user.id}
                        reloadMessages={reloadMessages}
                      />
                    ))}
                  </ul>
                )}
              </div>
            )
          })}
        {isLoading && (
          <div className="text-center">
            <i className="fa fa-spinner fa-spin fa-2x" />
          </div>
        )}
        <ErrorMessage error={error} isError={isError} className="job-plannings-table-error" />
      </div>
    </LightgalleryProvider>
  )
}
