import React from 'react'
import PropTypes from 'prop-types'
import useAxios from 'axios-hooks'
import moment from 'moment-timezone'
import DownloadButton from '../files/DownloadButton'
import { formatBytes } from '../helpers/file'
import { downloadAttachments } from '../../api/files'
import { files_jobnumber_path } from "../../routes"

function FilesTable({ jobnumber }) {
  const [{ data, loading, error }, reload] = useAxios(
    {
      url: files_jobnumber_path(jobnumber.id, {format: 'json'}),
    },
    { useCache: false }
  )

  if (error) {
    return <div className="text-center">{error.message}</div>
  }

  if (loading) {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin" />
      </div>
    )
  }
  if (!data.length) {
    return <div className="text-center">{`There are no files for ID ${jobnumber.job_id}`}</div>
  }
  return (
    <>
      <div className="messages-download-attachments clearfix">
        <DownloadButton jobnumber={jobnumber} downloadAttachments={downloadAttachments} />
      </div>
      <div className="">
        {data &&
          data.map(file => {
            return (
              <div className="border-bottom" key={file.url}>
                <div className="mt-2">
                  <a href={file.download_url}>
                    <i role="button" className="fa fa-icon fa-download">
                      &nbsp;
                    </i>
                  </a>
                  &nbsp;
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.filename}
                  </a>
                </div>
                <div className="mb-2 pl-4">
                  <small>
                    {formatBytes(file.size)},{' '}
                    {moment(file.last_modified)
                      .tz('Europe/Copenhagen')
                      .format('DD-MM-YYYY, HH:mm:ss')}
                  </small>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

FilesTable.propTypes = {
  jobnumber: PropTypes.shape({
    id: PropTypes.number,
    jobnumber: PropTypes.string,
  }),
}

export default FilesTable
