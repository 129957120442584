import * as React from 'react'
import Popup from 'reactjs-popup'
import FilterForm  from './FilterForm'
import { SavedFiltersControllerType } from '../../hooks/useSavedFiltersController'

type ChangeFilterButtonProps = {
  savedFiltersController: SavedFiltersControllerType
}

function ChangeFilterButton({ savedFiltersController}: ChangeFilterButtonProps) {
  return (
    <>
      <Popup
        trigger={
          <button type="button" className="btn btn-outline-secondary text-nowrap">
            Skift filter
          </button>
        }
        position="center center"
        contentStyle={{width: 'auto', maxWidth: '992px'}}
        modal
        closeOnDocumentClick={false}
      >
        {closeModal => (
          <div className="production-planning-filter-modal modal-popup">
            <a className="close" onClick={closeModal}>
              &times;
            </a>
            <div className="production-planning-filter-modal-header popup-header">Skift filter</div>
            <FilterForm savedFiltersController={savedFiltersController} closeModal={closeModal}/>
          </div>
        )}
      </Popup>
    </>
  )
}

export default ChangeFilterButton
