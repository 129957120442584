import * as React from 'react'
import { useState } from 'react'
import OrderNumberPicker from './OrderNumberPicker'
import * as PropTypes from 'prop-types'
import WorkcardsPicker from './WorkcardsPicker'
import PartnersPicker from './PartnersPicker'
import TimeFields from './TimeFields'
import MachineFields from './MachineFields'
import ItemFields from './ItemFields'
import DependentTaskPicker from './DependentTaskPicker'
import NestedFieldset from './NestedFieldset'
import useNestedAttributes from '../../hooks/useNestedAttributes'
import { loadPackagingsItemOptions } from '../../api/items'
import ShipmentFields from './ShipmentFields'
import PullShipmentsButton from './PullShipmentsButton'

type FormFieldsProps = {
  record: any
  formData: any
  setFormData: (formData: any) => void
  availableWorktypes: any[]
  availableWorkcards: any[]
  availablePartners: any[]
  allPartners: any[]
  availableMachines: any[]
  availableWarehouses: any[]
  hideMachinesAndItems?: boolean
  hidePackagings?: boolean
}

function FormFields(props: FormFieldsProps) {
  const {
    record,
    formData,
    setFormData,
    availableWorktypes,
    availableWorkcards,
    availablePartners,
    allPartners,
    availableMachines,
    availableWarehouses,
    hideMachinesAndItems,
    hidePackagings,
  } = props
  const [jobnumber, setJobnumber] = useState(record.jobnumber)
  const jobnumberId = (jobnumber && jobnumber.id) || 0

  const [times, noVisibleTimes, addTime, removeTime, setTime] = useNestedAttributes(formData, setFormData, 'times_attributes')
  const [machines, noVisibleMachines, addMachine, removeMachine, setMachine] = useNestedAttributes(
    formData,
    setFormData,
    'machines_attributes'
  )
  const [items, noVisibleItems, addItem, removeItem, setItem] = useNestedAttributes(formData, setFormData, 'items_attributes')
  const [packagings, noVisiblePackagings, addPackaging, removePackaging, setPackaging] = useNestedAttributes(
    formData,
    setFormData,
    'packagings_attributes'
  )
  const [shipments, noVisibleShipments, _addShipment, _removeShipment, setShipment] = useNestedAttributes(
    formData,
    setFormData,
    'shipments_attributes'
  )

  const showMachinesAndItems = !hideMachinesAndItems && record.is_master

  return (
    <>
      <OrderNumberPicker
        orderNumber={jobnumber}
        setOrderNumber={jn => {
          setJobnumber(jn)
          if (jn && jn.id && jn.id > 0) {
            setFormData({ ...formData, jobnumber_id: jn.id })
          }
        }}
      />
      <input type="hidden" name="resources_record[jobnumber_id]" value={jobnumberId} />
      <div className="row">
        <div className="col-sm-6">
          <DependentTaskPicker
            jobnumber={jobnumber}
            taskId={formData.task_id}
            setTaskId={id => setFormData({ ...formData, task_id: id })}
            name="resources_record[task_id]"
          />
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="resources-record-date">Dato</label>
          <input
            id="resources-record-date"
            required
            type="date"
            className="form-control col-xs-12"
            value={formData.date}
            onChange={e => setFormData({ ...formData, date: e.target.value })}
            name="resources_record[date]"
          />
        </div>
      </div>

      <NestedFieldset className="mb-4" title="Timer" addButtonTitle="+ Tilføj" onAddButtonClick={addTime}>
        {noVisibleTimes && <div className="text-muted text-center">Ingen timer</div>}
        {Object.keys(times).map((key, index) => {
          const time = times[key]
          const isLastItem = Object.keys(times).length == index + 1
          return (
            <TimeFields
              key={key}
              className={isLastItem ? '' : 'mb-2'}
              idKey={key}
              fieldNameFn={field => `resources_record[times_attributes][${index}][${field}]`}
              timeFields={time}
              setTimeFields={data => setTime(key, data)}
              availableWorktypes={availableWorktypes}
              handleRemove={() => removeTime(key)}
              showSignControl={false}
            />
          )
        })}
      </NestedFieldset>
      {showMachinesAndItems && (
        <>
          <NestedFieldset className="mb-4" title="Maskiner" addButtonTitle="+ Tilføj" onAddButtonClick={addMachine}>
            {noVisibleMachines && <div className="text-muted text-center">Ingen maskiner</div>}
            {Object.keys(machines).map((key, index) => {
              const machine = machines[key]
              const isLastItem = Object.keys(machines).length == index + 1
              return (
                <MachineFields
                  key={key}
                  className={isLastItem ? '' : 'mb-2'}
                  idKey={key}
                  index={index}
                  machineFields={machine}
                  fieldNameFn={field => `resources_record[machines_attributes][${index}][${field}]`}
                  setMachineFields={data => setMachine(key, data)}
                  availableMachines={availableMachines}
                  handleRemove={() => removeMachine(key)}
                />
              )
            })}
          </NestedFieldset>
          <NestedFieldset className="mb-4" title="Materialer" addButtonTitle="+ Tilføj" onAddButtonClick={addItem}>
            {noVisibleItems && <div className="text-muted text-center">Ingen materialer</div>}
            {Object.keys(items).map((key, index) => {
              const item = items[key]
              return (
                <ItemFields
                  key={key}
                  idKey={key}
                  itemFields={item}
                  availableWarehouses={availableWarehouses}
                  fieldNameFn={field => `resources_record[items_attributes][${index}][${field}]`}
                  setItemFields={data => setItem(key, data)}
                  handleRemove={() => removeItem(key)}
                />
              )
            })}
          </NestedFieldset>
        </>
      )}
      {!hidePackagings && (
        <>
          <NestedFieldset className="mb-4" title="Emballage" addButtonTitle="+ Tilføj" onAddButtonClick={addPackaging}>
            {noVisiblePackagings && <div className="text-muted text-center">Ingen forsendelser</div>}
            {Object.keys(packagings).map((key, index) => {
              const packaging = packagings[key]
              return (
                <ItemFields
                  key={key}
                  idKey={key}
                  itemFields={packaging}
                  loadItemOptions={loadPackagingsItemOptions}
                  fieldNameFn={field => `resources_record[packagings_attributes][${index}][${field}]`}
                  setItemFields={data => setPackaging(key, data)}
                  handleRemove={() => removePackaging(key)}
                />
              )
            })}
          </NestedFieldset>
          <NestedFieldset
            className="mb-4"
            title="Forsendelser"
            headerButtons={
              <PullShipmentsButton
                jobnumberId={jobnumberId}
                setShipments={data =>
                  setFormData({
                    ...formData,
                    shipments_attributes: data,
                  })
                }
              />
            }
          >
            {noVisibleShipments && <div className="text-muted text-center">Ingen forsendelser</div>}
            <ShipmentFields
              shipmentFields={shipments}
              fieldNameFn={(index, field) => `resources_record[shipments_attributes][${index}][${field}]`}
              setShipmentFields={shipments => setFormData({ ...formData, shipments_attributes: shipments })}
              jobnumberId={jobnumberId}
            />
          </NestedFieldset>
        </>
      )}
      {record.is_master && (
        <WorkcardsPicker
          workcardIds={formData.workcard_ids}
          setWorkcardIds={ids => setFormData({ ...formData, workcard_ids: ids })}
          availableWorkcards={availableWorkcards}
          name="resources_record[workcard_ids][]"
        />
      )}
      {record.is_master && (
        <PartnersPicker
          partnerIds={formData.partner_ids}
          setPartnerIds={ids => setFormData({ ...formData, partner_ids: ids })}
          availablePartners={availablePartners}
          allPartners={allPartners}
          name="resources_record[partner_ids][]"
        />
      )}
    </>
  )
}

FormFields.propTypes = {
  availableWorktypes: PropTypes.array.isRequired,
  availableWorkcards: PropTypes.array.isRequired,
  availablePartners: PropTypes.array.isRequired,
  availableMachines: PropTypes.array.isRequired,
  allPartners: PropTypes.array.isRequired,
  hideMachinesAndItems: PropTypes.bool,
  record: PropTypes.shape({
    jobnumber_id: PropTypes.object,
    workcard_ids: PropTypes.array,
    partner_ids: PropTypes.array,
    jobnumber: PropTypes.object,
    times: PropTypes.array,
    machines: PropTypes.array,
    is_master: PropTypes.bool,
  }).isRequired,
}

export default FormFields
