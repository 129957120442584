import axios, {AxiosRequestConfig} from 'axios'
import { jobnumbers_path, change_status_jobnumbers_path, get_status_jobnumbers_path } from '../routes'

export function loadJobnumberOptions(filter, callback) {
  const ajaxParams: AxiosRequestConfig = {
    url: jobnumbers_path(),
    method: 'get',
    responseType: 'json',
    params: { q: filter },
  }
  axios(ajaxParams).then(response => {
    const { data } = response
    if (data) {
      const options = data.map(jn => {
        return { label: jn.full_title, value: jn.id, jobnumber: jn }
      })
      callback(options)
    } else {
      callback([])
    }
  })
}

export function loadOrderNumberOptions(filter, callback) {
  const ajaxParams: AxiosRequestConfig = {
    url: jobnumbers_path(),
    method: 'get',
    responseType: 'json',
    params: { q: filter },
  }
  axios(ajaxParams).then(response => {
    const { data } = response
    if (data) {
      const options = data.map(jn => {
        return { label: jn.job_id_title, value: jn.id, jobnumber: jn }
      })
      callback(options)
    } else {
      callback([])
    }
  })
}

export function changeJobnumberStatus(payload = {}) {
  const options: AxiosRequestConfig = {
    url: change_status_jobnumbers_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options)
}

export function getJobnumberStatus(payload = {}) {
  const options: AxiosRequestConfig = {
    url: get_status_jobnumbers_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options)
}