import * as React from 'react'
import { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { useTasksQuery } from '../../hooks/queries/tasks'

function DependentTaskPicker({ jobnumber, taskId, setTaskId, name, disabled = false, htmlId = 'resources-task' }) {
  const { isError, data, isFetched } = useTasksQuery(jobnumber)

  useEffect(() => {
    if (!taskId && data && data.length > 0) {
      setTaskId(data[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className="row">
      <div className="col-sm-12 form-group">
        <label htmlFor={htmlId}>Procesområde / Afdeling</label>
        <select
          id={htmlId}
          name={name}
          className="form-control resources-time-task"
          value={taskId || ''}
          onChange={e => setTaskId(e.target.value)}
          required
          disabled={disabled}
        >
          {isFetched &&
            !isError &&
            data.map(task => {
              return (
                <option key={task.id} value={task.id}>
                  {`${task.number} - ${task.description}`}
                </option>
              )
            })}
        </select>
        {/* Submit value even if select is disabled */}
        {disabled && <input type="hidden" name={name} value={taskId} />}
      </div>
    </div>
  )
}

DependentTaskPicker.propTypes = {
  taskId: PropTypes.number,
  setTaskId: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}
DependentTaskPicker.defaultProps = {
  taskId: undefined,
}

export default DependentTaskPicker
