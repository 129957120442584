import React from 'react'
import PropTypes from 'prop-types'

function RemovedField({ field, fieldNameFn }) {
  const { id } = field
  return (
    <React.Fragment>
      <input type="hidden" name={fieldNameFn('id')}  value={id} />
      <input type="hidden" name={fieldNameFn('_destroy')} value />
    </React.Fragment>
  )
}

RemovedField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldNameFn: PropTypes.func.isRequired,
}

export default RemovedField
