import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

function ApprovalFormsAttachFormButton(props) {
  const { isLoading, jobnumber, availableApprovalFormTemplates, attachForm } = props
  const { $ } = window
  useEffect(() => {
    $('#dropdownMenuButton').dropdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="dropdown d-inline-block">
      <button
        className="btn btn-outline-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={isLoading}
      >
        {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Attach form'}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {availableApprovalFormTemplates.map(template => {
          return (
            <a
              className="dropdown-item"
              key={template.id}
              href="#"
              onClick={e => {
                e.preventDefault()
                attachForm({ jobnumber_id: jobnumber.id, template_id: template.id })
              }}
            >
              {template.name}
            </a>
          )
        })}
      </div>
    </div>
  )
}

ApprovalFormsAttachFormButton.propTypes = {
  jobnumber: PropTypes.object.isRequired,
  availableApprovalFormTemplates: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  attachForm: PropTypes.func.isRequired,
}

export default ApprovalFormsAttachFormButton
