import * as React from 'react'
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ResourcesReportTimeShape } from '../../api/api_data_types'
import { minutesToTime } from '../../components/helpers/time'

export default function useResourcesReportTable(resourcesTimes: ResourcesReportTimeShape[]) {
  const columns: ColumnDef<ResourcesReportTimeShape>[] = React.useMemo(
    () => [
      { header: 'Dato', accessorKey: 'date', filterFn: 'includesString', cell: info => info.getValue() || null },
      {
        header: 'Kategori',
        accessorKey: 'worktype.category',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Arbejdstype',
        accessorKey: 'worktype.code',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Beskrivelse',
        accessorKey: 'comment',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Tid',
        accessorFn: o => {
          return o.time ? minutesToTime(o.time) : null
        },
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
    ],
    resourcesTimes
  )

  const [data, setData] = React.useState(() => [...resourcesTimes])
  const [columnFilters, setColumnFilters] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    initialState: {
      sorting: [{ id: 'date', desc: false }],
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return { table }
}
