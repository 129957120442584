import { useState } from 'react'
import { format } from 'date-fns'
import { useUpdateJobCostingLineMutation } from './queries/job_costing_lines'

export default function useConsumptionLineFormController(jobCostingLine) {
  const itemToOption = item => {
    return { item: item, label: item.title, value: item.id }
  }
  const defaultItem: {value?: string} = jobCostingLine.item ? itemToOption(jobCostingLine.item) : {}
  const defaultQuantity =
    jobCostingLine.quantity && jobCostingLine.quantity > 0 ? jobCostingLine.quantity : jobCostingLine.estimated_quantity
  const initialValues = {
    date: jobCostingLine.date || format(new Date(), 'yyyy-MM-dd'),
    item: defaultItem,
    location_code: jobCostingLine.location_code || '3', // 3 = MÅLØV
    text: '',
    quantity: defaultQuantity || 0,
  }
  const [values, setValues] = useState(initialValues)
  const mutation = useUpdateJobCostingLineMutation(jobCostingLine, values)
  const submit = callback => {
    mutation.mutate(
      null,
      {
        onSuccess: () => {
          callback()
        },
      }
    )
  }
  const isItemPickerDisabled = defaultItem.value !== undefined

  let isSubmitDisabled = false

  if (
    values.item.value === undefined ||
    values.date === undefined ||
    values.quantity === undefined ||
    values.location_code === '' ||
    values.location_code === undefined ||
    parseFloat(values.quantity) === 0
  ) {
    isSubmitDisabled = true
  }

  const { isLoading, isError, error } = mutation
  return { values, setValues, submit, isItemPickerDisabled, isError, isLoading, error, isSubmitDisabled }
}
