import * as React from 'react'
import Popup from 'reactjs-popup'
import { ItemShape } from '../../api/api_data_types'
import CorrectItemForm from './CorrectItemForm'

type CorrectItemButtonProps = {
  item: ItemShape
}
export default function CorrectItemButton(props: CorrectItemButtonProps) {
  const { item } = props
  let trigger: JSX.Element
  const title = item.no
  trigger = (
    <span role="button" className="text-danger text-nowrap">
      <i className={`fa fa-icon fa-edit`} title={title} />
      {title}
    </span>
  )
  return (
    <Popup
      trigger={trigger}
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '620px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      closeOnDocumentClick
      modal
    >
      {close => (
        <div className="modal-popup resources-record-modal resources-overview-comment-popup p-2">
          <a className="close" onClick={close} role="button">
            &times;
          </a>
          <div className="popup-header">
            Flyt {item.no} - {item.description}
          </div>
          <table className="mt-3 table table-bordered">
            <thead>
            <tr>
              <th>Lager</th>
              <th>Lager Måløv</th>
              <th>Lager Skejby</th>
              <th>Lager Lyfa</th>
              <th>Lager Fjernlager</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              {/*format to use commas instead of dots*/}
              {/*<td>{item.inventory}</td>*/}
              <td>{item.inventory.toLocaleString('da-DK')}</td>
              <td>{item.inventory_malov.toLocaleString('da-DK')}</td>
              <td>{item.inventory_skejby.toLocaleString('da-DK')}</td>
              <td>{item.inventory_lyfa.toLocaleString('da-DK')}</td>
              <td>{item.inventory_fjernlager.toLocaleString('da-DK')}</td>
            </tr>
            </tbody>
          </table>
          <CorrectItemForm item={item} close={close} />
        </div>
      )}
    </Popup>
  )
}
