import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getJobPlanningLines, completeJobPlanningLine, getCompletePopupJobPlanningLines } from '../../api/job_planning_lines'

export function useJobPlanningLinesQuery(filter, showCompletedToday) {
  return useQuery(['job_planning_lines', filter, showCompletedToday], () =>
    getJobPlanningLines({ filter, show_completed_today: showCompletedToday })
  )
}

export function useCompletePopupJobPlanningLinesQuery(jobnumberId, showCompletedInPast) {
  const { isLoading, data, error } = useQuery(['job_planning_lines_popup', showCompletedInPast], () =>
    getCompletePopupJobPlanningLines({ jobnumber_id: jobnumberId, show_completed_in_past: showCompletedInPast })
  )

  const queryClient = useQueryClient()
  const reload = () => queryClient.invalidateQueries('job_planning_lines_popup')

  return { isLoading, data, error, reload }
}

export function useCompleteJobPlanningMutation(jobPlanningKey, reload) {
  const key = encodeURIComponent(jobPlanningKey)
  const options = { onSuccess: reload }
  return useMutation(direction => completeJobPlanningLine(key, direction), options)
}
