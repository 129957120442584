interface ShowWeekendsSwitcherProps {
  showWeekends: boolean
  setShowWeekends: (showWeekends: boolean) => void
}
export default function ShowWeekendsSwitcher({showWeekends, setShowWeekends}: ShowWeekendsSwitcherProps) {
  return(
    <div className="row">
      <div className="d-flex">
        <div className="form-check form-check-valigned">
          <label>
            <input type="checkbox" checked={showWeekends} onChange={e => setShowWeekends(e.target.checked)} />
            Weekend og helligdage
          </label>
        </div>
        <div className="ml-4 pt-1 small">F - Fraværende (Kan både være ferie, barsel, sygdom eller lign.)</div>
      </div>
    </div>

  )
}