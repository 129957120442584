export default function ConsumptionLineCopyQuantityButton(props) {
  const { changedQuantities, setChangedQuantities, row } = props
  return (
    <i
      className="fa fa-icon fa-arrow-left cursor-pointer"
      onClick={() =>
        setChangedQuantities({
          ...changedQuantities,
          [row.key]: { quantity: row.estimated_quantity, job_id: row.job_id, item_id: row.item.id, line_no: row.line_no },
        })
      }
    />
  )
}
