import React from 'react'

export default function SortingIndicator({ active, direction, className }) {
  if (!active) {
    return null
  }

  const directionClass = direction === 'asc' ? 'up' : 'down'
  return <span className={`fa fa-icon fa-sort-${directionClass} ${className}`} />
}
