import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import AdjustmentsFormFields from "./AdjustmentsFormFields";

export default function AdjustmentsFormFieldsApp(props: any) {
  const queryClient = buildQueryClient()
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AdjustmentsFormFields {...props} />
      </QueryClientProvider>
    </>
  )
}
