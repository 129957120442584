import TravelListFilter from './TravelListFilter'
import EditTravelButton from './EditTravelButton'
import TravelListTable from './TravelListTable'
import { useState } from 'react'
import { FilterShape } from './travel_list'
import { format } from 'date-fns'
import ViewSwitcher, { ViewType } from '../shared/ViewSwitcher'
import { useStorageState } from 'react-storage-hooks'
import { useTravelsQuery } from '../../hooks/queries/travels'
import TravelListCards from './TravelListCards'

export default function TravelListTableWithFilter() {
  const [filter, setFilter] = useState<FilterShape>({ startDate: format(new Date(), 'yyyy-MM-dd'), endDate: '' })
  const [currentView, setCurrentView] = useStorageState<ViewType>(sessionStorage, 'currentView', 'table')
  const { isLoading, isError, error, data } = useTravelsQuery(filter)

  return (
    <>
      <div className="mb-3">
        <TravelListFilter filter={filter} setFilter={setFilter} />
        <div className="d-flex justify-content-between mt-3">
          <ViewSwitcher currentView={currentView} setCurrentView={setCurrentView} />
          <EditTravelButton />
        </div>
      </div>
      {currentView === 'table' && <TravelListTable data={data} isLoading={isLoading} />}
      {currentView === 'cards' && <TravelListCards data={data} isLoading={isLoading} />}
    </>
  )
}
