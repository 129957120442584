import React from 'react'
import PropTypes from 'prop-types'

import { useMutation } from 'react-query'

function DownloadButton({ jobnumber, downloadAttachments }) {
  const mutation = useMutation(jobnumberId => downloadAttachments({ jobnumber_id: jobnumberId }), {
    onSuccess: async d => {
      alert(d.message)
    },
  })

  return (
    <button
      type="button"
      disabled={mutation.isLoading}
      className="btn btn-outline-secondary messages-download-attachments-button"
      onClick={() => mutation.mutate(jobnumber.id)}
    >
      {mutation.isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Download alt'}
    </button>
  )
}

DownloadButton.propTypes = {
  jobnumber: PropTypes.object.isRequired,
  downloadAttachments: PropTypes.func.isRequired,
}
export default DownloadButton
