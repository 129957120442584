import Actions from './Actions'
import useJobPlanningLineRow from '../../../hooks/useJobPlanningLineRow'

function TableRow({ jobPlanning, className }) {
  const { time, formattedDate, addressLink, description, persons } = useJobPlanningLineRow(jobPlanning)

  return (
    <tr key={jobPlanning.key} className={className} title={persons}>
      <td className="text-nowrap">{jobPlanning.job_id}</td>
      <td>{jobPlanning.order_no}</td>
      <td>{jobPlanning.job_task_no}</td>
      <td>{jobPlanning.no}</td>
      <td>
        <span className="badge badge-light">{jobPlanning.status_code}</span>
      </td>
      <td>{jobPlanning.place_of_production}</td>
      <td>{jobPlanning.jobnumber && jobPlanning.jobnumber.customer_title}</td>
      <td>{addressLink} </td>
      <td>{description}</td>
      <td className="text-nowrap">{formattedDate}</td>
      <td>{time}</td>
      <td>{jobPlanning.placement}</td>
      <td>{jobPlanning.comment}</td>
      <td className="d-print-none">
        {jobPlanning.jobnumber && (
          <Actions jobnumberId={jobPlanning.jobnumber.id} jobnumber={jobPlanning.jobnumber} jobPlanning={jobPlanning} currentView="table" />
        )}
      </td>
    </tr>
  )
}

export default TableRow
